import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";

export function setModoTela(modo, registro = {}) {
  return {
    type: "MEMORANDO_MODO_TELA",
    payload: {
      modo: modo,
      registro: registro,
    },
  };
}
export function initForm(registro = {}) {
  return [initialize("memorandoForm", registro)];
}

export function setFiltro(filtro = {}) {
  return {
    type: "DATA_MEMORANDO_FILTRO",
    payload: {
      filtro: filtro,
    },
  };
}

export function getLista() {
  return (dispatch, getState) => {
    axios
      .get(
        `${consts.API_URL}/memorando?data_inicial_inclusao=${
          DateFormat.formatarDataTelaParaSql(
            getState().memorando.filtro.data_inicial_inclusao
          ) === "Invalid DateTime"
            ? null
            : DateFormat.formatarDataTelaParaSql(
                getState().memorando.filtro.data_inicial_inclusao
              )
        }&data_final_inclusao=${
          DateFormat.formatarDataTelaParaSql(
            getState().memorando.filtro.data_final_inclusao
          ) === "Invalid DateTime"
            ? null
            : DateFormat.formatarDataTelaParaSql(
                getState().memorando.filtro.data_final_inclusao
              )
        }${
          getState().memorando.filtro.tipo &&
          getState().memorando.filtro.tipo !== "null"
            ? "&tipo=" + getState().memorando.filtro.tipo
            : ""
        }`
      )
      .then((resp) => {
        dispatch({
          type: "MEMORANDO_LISTADO",
          payload: resp,
        });
      });
  };
}

export function salvar(registro, gerarPDF) {
  return (dispatch, getState) => {
    // console.log(registro);
    if (!registro.id) {
      const id_usuario_origem = getState().auth.usuario.id;
      registro.id_usuario_origem = id_usuario_origem;

      axios
        .post(`${consts.API_URL}/memorando`, {
          ...registro,
        })
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch({
            type: "MEMORANDO_INCLUIDO",
            payload: resp,
          });
          dispatch(getLista());
        })
        .catch((e) => {
          // console.log(e);
          setErroAPI(e);
        });
    } else {
      axios
        .put(`${consts.API_URL}/memorando`, {
          ...registro,
        })
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch(getLista());
          dispatch(setModoTela("lista"));
        })
        .catch((e) => {
          setErroAPI(e);
        });
    }
  };
}

export function excluir(registro) {
  return (dispatch) => {
    axios
      .delete(`${consts.API_URL}/memorando?id=${registro.id}`)
      .then((resp) => {
        setSuccess("Operação Realizada com sucesso.");
        dispatch(getLista());
        dispatch(setModoTela("lista"));
      })
      .catch((e) => {
        setErroAPI(e);
      });
  };
}

export function getListaTipoMemorando() {
  const request = axios.get(`${consts.API_URL}/tipoMemorando`);
  return {
    type: "TIPO_MEMORANDO_LISTADO",
    payload: request,
  };
}

export function getListaLocal() {
  const request = axios.get(`${consts.API_URL}/local`);
  return {
    type: "LOCAL_LISTADO",
    payload: request,
  };
}

export function getListaUsuario() {
  const request = axios.get(`${consts.API_URL}/usuario`);
  return {
    type: "USUARIO_LISTADO",
    payload: request,
  };
}
