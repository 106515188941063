import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import { setError } from "../common/toast/toast";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import Select from "../common/form/select";
import imagemAdd from "../assets/images/icone_add_imagem.png";

import { setModoTela, initForm } from "./usuarioActions";
import { setModoTela as setModoTelaMemorando } from "../memorando/memorandoActions";
import { getLista as getSetores } from "../setor/setorActions";
class UsuarioForm extends Component {
  state = {};

  componentDidMount() {
    this.setState(this.props.formularioValues);
    const fileSelectorImagem = document.createElement("input");
    fileSelectorImagem.setAttribute("type", "file");
    fileSelectorImagem.addEventListener("change", (data) => {
      let reader = new FileReader();
      let file = data.target.files[0];
      if (file) {
        if (!file.name.match(/.png|.jpg|.jpeg/gi)) {
          setError("Selecione apenas imagens (.PNG, .JPG e .JPEG)");
          return;
        }

        reader.onloadend = () => {
          this.props.initForm({
            ...this.props.formularioValues,
            imagem_perfil: reader.result,
          });
        };
        reader.readAsDataURL(file);
      }
    });

    const perfilPadrao = this.props.listaPerfil.find(
      (item) => item.nome === "PADRÃO"
    );

    const setores = {};

    this.props.setores.map((setor) => {
      if (this.props.formularioValues.setores)
        setores[setor.nome.toLowerCase()] =
          this.props.formularioValues.setores.some(
            (item) => item.id_setor === setor.id
          );
    });
    this.props.initForm({
      ...this.props.formularioValues,
      id_perfil_usuario: this.props.formularioValues.id
        ? this.props.formularioValues.id_perfil_usuario
        : perfilPadrao.id,
      ...setores,
    });
    this.fileSelectorImagem = fileSelectorImagem;
  }
  componentDidUpdate() {
    if (
      JSON.stringify(this.state) === JSON.stringify(this.props.formularioValues)
    ) {
      const fileSelectorImagem = document.createElement("input");
      fileSelectorImagem.setAttribute("type", "file");
      fileSelectorImagem.addEventListener("change", (data) => {
        let reader = new FileReader();
        let file = data.target.files[0];
        if (file) {
          if (!file.name.match(/.png|.jpg|.jpeg/gi)) {
            setError("Selecione apenas imagens (.PNG, .JPG e .JPEG)");
            return;
          }

          reader.onloadend = () => {
            this.props.initForm({
              ...this.props.formularioValues,
              imagem_perfil: reader.result,
            });
          };
          reader.readAsDataURL(file);
        }
      });

      const perfilPadrao = this.props.listaPerfil.find(
        (item) => item.nome === "PADRÃO"
      );

      const setores = {};

      this.props.setores.map((setor) => {
        if (this.props.formularioValues.setores)
          setores[setor.nome.toLowerCase()] =
            this.props.formularioValues.setores.some(
              (item) => item.id_setor === setor.id
            );
      });
      this.props.initForm({
        ...this.props.formularioValues,
        id_perfil_usuario: this.props.formularioValues.id
          ? this.props.formularioValues.id_perfil_usuario
          : perfilPadrao.id,
        ...setores,
      });
      this.fileSelectorImagem = fileSelectorImagem;
    }
  }
  componentWillMount() {
    this.props.getSetores();
    if (this.props.usuario.primeiroAcesso) {
      window.confirm(
        "Você ainda está com a senha temporária. Cadastre uma nova senha."
      );
    }
  }

  render() {
    let readOnly = this.props.excluir ? "readOnly" : "";
    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            <Row>
              <LabelAndInput
                label="Nome *"
                placeholder="Informe o nome completo"
                cols="12 12 12 12"
                readOnly={readOnly || this.props.usuario.primeiroAcesso}
                value={this.props.formularioValues.nome}
                onChange={(e) => {
                  this.props.initForm({
                    ...this.props.formularioValues,
                    nome: e.target.value.toUpperCase(),
                  });
                }}
              />
              <Field
                name="email"
                component={LabelAndInput}
                label="E-mail *"
                placeholder="Informe o e-mail"
                cols="12 12 12 12"
                readOnly={readOnly || this.props.usuario.primeiroAcesso}
              />
              <LabelAndInput
                label="Nome de Usuário *"
                placeholder="Informe o nome de usuário"
                cols="12 12 12 12"
                readOnly={readOnly || this.props.usuario.primeiroAcesso}
                value={this.props.formularioValues.nome_de_usuario}
                onChange={(e) => {
                  this.props.initForm({
                    ...this.props.formularioValues,
                    nome_de_usuario: e.target.value.toUpperCase(),
                  });
                }}
              />
              <Field
                name="senha"
                component={LabelAndInput}
                type="password"
                label="Senha *"
                placeholder="Informe a senha"
                cols="12 12 12 12"
                readOnly={readOnly}
                active
              />
              <Field
                name="matricula"
                component={LabelAndInput}
                label="Matrícula"
                placeholder="Informe a matrícula"
                cols="12 12 12 12"
                readOnly={readOnly}
                active
              />
              <Field
                name="cpf"
                component={LabelAndInput}
                label="CPF"
                placeholder="Informe o CPF"
                cols="12 12 12 12"
                readOnly={readOnly}
                active
              />
              <Field
                name="id_perfil_usuario"
                component={Select}
                options={this.props.listaPerfil.map((item) => ({
                  id: item.id,
                  valor: item.nome,
                }))}
                label="Nível de Acesso *"
                placeholder="Informe o nível de permissão do usuário"
                cols="12 12 6 6"
                readOnly={
                  readOnly ||
                  (this.props.registro.id &&
                    !["ADMINISTRADOR", "RH"].includes(
                      this.props.usuario.nome_perfil_usuario
                    ))
                }
                defaultSelected="PADRÃO"
              />
            </Row>
            <Row>
              <Grid
                cols="12 12 12 12"
                style={{ paddingTop: 5, paddingBottom: 20 }}
              >
                <b>Setores *</b>
              </Grid>
            </Row>
            <Row>
              {this.props.setores.map((item) => (
                <Field
                  style={{ marginRight: 10 }}
                  key={item.id}
                  component={LabelAndCheckbox}
                  cols="12 12 12 12"
                  name={item.nome.toLowerCase()}
                  label={item.nome}
                  readOnly={
                    readOnly ||
                    (this.props.registro.id &&
                      !["ADMINISTRADOR", "RH"].includes(
                        this.props.usuario.nome_perfil_usuario
                      ))
                  }
                />
              ))}

              <LabelAndInput
                label="Cargo *"
                placeholder="Informe o Cargo"
                cols="12 12 12 12"
                value={this.props.formularioValues.cargo}
                readOnly={
                  readOnly ||
                  (this.props.registro.id &&
                    !["ADMINISTRADOR", "RH"].includes(
                      this.props.usuario.nome_perfil_usuario
                    ))
                }
                onChange={(e) => {
                  this.props.initForm({
                    ...this.props.formularioValues,
                    cargo: e.target.value.toUpperCase(),
                  });
                }}
              />
            </Row>
            <Row>
              <Grid cols="12 12 12 12">
                <label>Imagem de Perfil</label>
              </Grid>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {this.props.formularioValues.imagem_perfil && (
                  <>
                    <span className="btn btn-outline-primary fileinput-button dz-clickable">
                      <img
                        src={this.props.formularioValues.imagem_perfil}
                        style={{ height: 150 }}
                        alt="Imagem de perfil"
                      />
                    </span>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingRight: 2,
                        marginTop: 4,
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-flat btn-outline-primary"
                        style={{ width: "48%" }}
                        onClick={() => {
                          window.open(
                            this.props.formularioValues.imagem_perfil,
                            "_blank"
                          );
                        }}
                      >
                        <i className="fas fa-download"></i>
                      </button>

                      <button
                        type="button"
                        className="btn btn-flat btn-outline-danger"
                        style={{ width: "48%" }}
                        disabled={readOnly}
                        onClick={() => {
                          this.props.initForm({
                            ...this.props.formularioValues,
                            imagem_perfil: undefined,
                          });
                        }}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </>
                )}
              </div>

              {!this.props.formularioValues.imagem_perfil ? (
                <span
                  className="btn btn-outline-primary fileinput-button dz-clickable"
                  onClick={() => this.fileSelectorImagem.click()}
                  style={{ marginLeft: "5px" }}
                  title="Adicionar imagem"
                >
                  <img
                    src={imagemAdd}
                    style={{ height: 30 }}
                    alt="Adicionar imagem"
                  />
                </span>
              ) : null}
            </Row>
          </ContentCardBody>
          <ContentCardFooter>
            <Row alignCenter>
              <Grid cols="6 6 4 3">
                {this.props.excluir ? (
                  <Button
                    text="Excluir"
                    submit
                    type={"danger"}
                    icon={"fa fa-trash"}
                  />
                ) : (
                  <Button
                    text="Salvar"
                    submit
                    type={"success"}
                    icon={"fa fa-check"}
                  />
                )}
              </Grid>
              <Grid cols="6 6 4 3">
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => {
                    if (this.props.modoTela === "alteracao") {
                      this.props.setModoTela("lista");
                      window.location.href = "#/";
                    }
                    this.props.setModoTela("lista");
                  }}
                  disabled={this.props.usuario.primeiroAcesso}
                />
              </Grid>
            </Row>
          </ContentCardFooter>
        </Form>
      </ContentCard>
    );
  }
}
UsuarioForm = reduxForm({ form: "usuarioForm", destroyOnUnmount: false })(
  UsuarioForm
);
const mapStateToProps = (state) => ({
  formularioValues: getFormValues("usuarioForm")(state),
  registro: state.usuario.registro,
  usuario: state.auth.usuario,
  listaPerfil: state.usuario.listaPerfil,
  modoTela: state.usuario.modoTela,
  setores: state.setor.lista,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setModoTela, setModoTelaMemorando, getSetores, initForm },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
