/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateFormat from "../common/dateFormat/DateFormat";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./solicitacaoHoraExtraForm";

import {
  setModoTela,
  initForm,
  initFormCarregado,
  salvar,
  excluir,
  getLista,
  getHorarioAtual,
} from "./solicitacaoHoraExtraActions";

import { getLista as getListaHorarios } from "../horarioSolicitacaoHoraExtra/horarioSolicitacaoHoraExtraActions";
import { getLista as getListaMotivoSolicitacao } from "../motivoSolicitacaoHoraExtra/motivoSolicitacaoHoraExtraActions";
import { getLista as getListaMotivoRecusa } from "../motivoRecusaSolicitacaoHoraExtra/motivoRecusaSolicitacaoHoraExtraActions";
import { getLista as getListaUsuario } from "../usuario/usuarioActions";
class SolicitacaoHoraExtra extends Component {
  state = {
    linhaSelecionada: null,
    pesquisar: "",
  };

  componentWillMount() {
    this.props.getHorarioAtual();
    this.props.getLista();
    this.props.getListaHorarios();
    this.props.getListaMotivoSolicitacao();
    this.props.getListaMotivoRecusa();
    this.props.getListaUsuario();
    setInterval(() => {
      this.props.getHorarioAtual();
      this.props.getLista();
      this.props.getListaHorarios();
      this.props.getListaMotivoSolicitacao();
      this.props.getListaMotivoRecusa();
      this.props.getListaUsuario();
    }, 60000);
  }

  render() {
    return (
      <div>
        <Content>
          {this.props.modoTela !== "lista" &&
          this.props.modoTela !== "exclusao" ? (
            <Form onSubmit={this.props.salvar} />
          ) : null}
          {this.props.modoTela === "exclusao" ? (
            <Form excluir onSubmit={this.props.excluir} />
          ) : null}
          {this.props.modoTela === "lista" ? this.lista() : null}
        </Content>
      </div>
    );
  }

  lista() {
    return (
      <ContentCard style={{ paddingLeft: 10 }}>
        <ContentCardHeader>
          <Row>
            <Grid cols="6 6 4 2">
              <Button
                text="Adicionar"
                disabled={
                  !validarHorarioSolicitacao(
                    this.props.horarioAtual,
                    this.props.listaHorarios
                  )
                }
                type={"success"}
                icon={"fa fa-plus"}
                event={() => {
                  this.props.setModoTela("cadastro");
                  this.props.initForm({
                    data_hora_extra: new Date().toISOString().substring(0, 10),
                    quantidade_minutos: 30,
                    aprovada: false,
                  });
                }}
              />
            </Grid>
            {!validarHorarioSolicitacao(
              this.props.horarioAtual,
              this.props.listaHorarios
            ) && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 15,
                }}
              >
                Período de solicitação encerrado.
              </div>
            )}
          </Row>
        </ContentCardHeader>
        <ContentCardBody>
          <Row>
            <input
              className="form-control"
              placeholder="Pesquisar por solicitante, colaborador ou supervisor"
              type="text"
              value={this.state.pesquisar}
              onChange={(e) => {
                this.setState({ ...this.state, pesquisar: e.target.value });
              }}
              style={{ marginInline: "5px", marginBottom: "20px" }}
            />
          </Row>
          <Table responsive>
            <THead>
              <Tr>
                <Th>Solicitante</Th>
                <Th>Colaborador</Th>
                <Th>Aprovador 1º Nível</Th>
                {/* <Th>Supervisor Intermediário</Th> */}
                <Th alignCenter>Aprovação 1º Nível</Th>
                {/* <Th alignCenter>Aprovação Intermediária</Th> */}
                <Th>Aprovador 2º Nível</Th>
                {/* <Th>Supervisor Final</Th> */}
                <Th alignCenter>Aprovação 2º Nível</Th>
                {/* <Th alignCenter>Aprovação Final</Th> */}
                <Th alignCenter>Data</Th>
                <Th alignCenter>Duração</Th>
                <Th>Motivo da Solicitação</Th>
                <Th>Motivo da Recusa</Th>
                <Th></Th>
              </Tr>
            </THead>
            <TBody>
              {this.props.lista
                .filter((item) => {
                  return this.state.pesquisar
                    ? (item.nome_solicitante &&
                        item.nome_solicitante
                          .toUpperCase()
                          .includes(this.state.pesquisar.toUpperCase())) ||
                        (item.nome_usuario &&
                          item.nome_usuario
                            .toUpperCase()
                            .includes(this.state.pesquisar.toUpperCase())) ||
                        (item.nome_supervisor &&
                          item.nome_supervisor
                            .toUpperCase()
                            .includes(this.state.pesquisar.toUpperCase()))
                    : true;
                })
                .filter((item) => {
                  let itemOk = false;
                  for (let i = 0; i < this.props.usuario.setores.length; i++) {
                    if (
                      item.setores.includes(
                        this.props.usuario.setores[i].id_setor
                      )
                    ) {
                      itemOk = true;
                      break;
                    }
                  }
                  if (
                    this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR"
                  )
                    return true;
                  if (
                    this.props.usuario.nome_perfil_usuario ===
                      "SUPERVISOR FINAL" &&
                    this.props.usuario.id !== item.id_solicitante &&
                    ((item.id_motivo_recusa && !item.id_supervisor_final) ||
                      !item.aprovada_intermediario)
                  ) {
                    return false;
                  }
                  return (
                    itemOk &&
                    (item.id_usuario === this.props.usuario.id ||
                      item.id_solicitante === this.props.usuario.id ||
                      !["PADRÃO", "SOLICITANTE GRUPO"].includes(
                        this.props.usuario.nome_perfil_usuario
                      ))
                  );
                })
                .map((item) => (
                  <Tr
                    key={item.id}
                    className={`${
                      this.state.linhaSelecionada === item.id ? "hover" : ""
                    }`}
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        linhaSelecionada: item.id,
                      })
                    }
                    onMouseEnter={() =>
                      this.setState({
                        ...this.state,
                        linhaSelecionada: item.id,
                      })
                    }
                  >
                    <Td nowrap minwidth={100}>
                      {item.nome_solicitante}
                    </Td>
                    <Td nowrap minwidth={100}>
                      {item.nome_usuario}
                    </Td>
                    <Td nowrap minwidth={100}>
                      {item.nome_supervisor_intermediario || (
                        <div style={{ textAlign: "center" }}>-</div>
                      )}
                    </Td>
                    <Td nowrap alignCenter minwidth={80}>
                      {!item.id_motivo_recusa &&
                      !item.aprovada_intermediario ? (
                        <span style={{ backgroundColor: "#fa2", padding: 10 }}>
                          Pendente
                        </span>
                      ) : item.aprovada_intermediario ? (
                        <div style={{ padding: 10 }}>Aprovada</div>
                      ) : (
                        <div style={{ padding: 10 }}> Reprovada</div>
                      )}
                    </Td>
                    <Td nowrap minwidth={100}>
                      {item.nome_supervisor_final || (
                        <div style={{ textAlign: "center" }}>-</div>
                      )}
                    </Td>

                    <Td nowrap alignCenter minwidth={80}>
                      {!item.id_supervisor_final && !item.id_motivo_recusa ? (
                        <span style={{ backgroundColor: "#fa2", padding: 10 }}>
                          Pendente
                        </span>
                      ) : item.id_motivo_recusa && !item.id_supervisor_final ? (
                        "-"
                      ) : item.aprovada_final ? (
                        <div style={{ padding: 10 }}>Aprovada</div>
                      ) : (
                        <div style={{ padding: 10 }}> Reprovada</div>
                      )}
                    </Td>
                    <Td nowrap alignCenter minwidth={100}>
                      {new Date(item.data_hora_extra).toLocaleDateString(
                        "pt-BR",
                        { dateStyle: "short" }
                      )}
                    </Td>

                    <Td nowrap alignCenter minwidth={100}>
                      {item.quantidade_minutos + " min"}
                    </Td>
                    <Td nowrap minwidth={100}>
                      {item.descricao_motivo_solicitacao}
                    </Td>
                    <Td nowrap minwidth={100}>
                      {item.descricao_motivo_recusa || (
                        <div style={{ textAlign: "center" }}>-</div>
                      )}
                    </Td>

                    <Td alignright="true" minwidth={70}>
                      {[
                        "SUPERVISOR INTERMEDIÁRIO",
                        "SUPERVISOR FINAL",
                      ].includes(this.props.usuario.nome_perfil_usuario) && (
                        <div>
                          <ButtonTable
                            type={"success"}
                            title={"Aprovar"}
                            icon={"fas fa-check"}
                            disabled={
                              item.id_supervisor_final ||
                              item.id_motivo_recusa ||
                              (item.aprovada_intermediario &&
                                this.props.usuario.nome_perfil_usuario ===
                                  "SUPERVISOR INTERMEDIÁRIO")
                            }
                            visible={this.state.linhaSelecionada === item.id}
                            event={() => {
                              this.props.initFormCarregado(item.id, "aprovar");
                            }}
                          />
                          <ButtonTable
                            type={"danger"}
                            title={"Reprovar"}
                            icon={"fas fa-times"}
                            disabled={
                              item.id_supervisor_final ||
                              item.id_motivo_recusa ||
                              (item.aprovada_intermediario &&
                                this.props.usuario.nome_perfil_usuario ===
                                  "SUPERVISOR INTERMEDIÁRIO")
                            }
                            visible={this.state.linhaSelecionada === item.id}
                            event={() => {
                              this.props.initFormCarregado(item.id, "reprovar");
                            }}
                          />
                        </div>
                      )}
                      {(this.props.usuario.id === item.id_usuario ||
                        item.id_solicitante === this.props.usuario.id) && (
                        <div>
                          <ButtonTable
                            disabled={
                              item.id_supervisor_intermediario ||
                              item.id_supervisor_final ||
                              item.id_motivo_recusa ||
                              item.id_solicitante !== this.props.usuario.id ||
                              !validarHorarioSolicitacao(
                                this.props.horarioAtual,
                                this.props.listaHorarios
                              )
                            }
                            type={"warning"}
                            title={"Alterar"}
                            icon={"fas fa-pencil-alt"}
                            visible={this.state.linhaSelecionada === item.id}
                            event={() => {
                              this.props.initFormCarregado(item.id, "cadastro");
                            }}
                          />

                          <ButtonTable
                            style={{ width: 100 }}
                            disabled={
                              item.id_supervisor_intermediario ||
                              item.id_supervisor_final ||
                              item.id_motivo_recusa ||
                              item.id_solicitante !== this.props.usuario.id ||
                              !validarHorarioSolicitacao(
                                this.props.horarioAtual,
                                this.props.listaHorarios
                              )
                            }
                            type={"danger"}
                            title={"Remover"}
                            icon={"fas fa-trash-alt"}
                            visible={this.state.linhaSelecionada === item.id}
                            event={() => {
                              this.props.initFormCarregado(item.id, "exclusao");
                            }}
                          />
                        </div>
                      )}
                    </Td>
                  </Tr>
                ))}
            </TBody>
          </Table>
        </ContentCardBody>
      </ContentCard>
    );
  }
}

const mapStateToProps = (state) => ({
  modoTela: state.solicitacaoHoraExtra.modoTela,
  lista: state.solicitacaoHoraExtra.lista,
  horarioAtual: state.solicitacaoHoraExtra.horarioAtual,
  listaHorarios: state.horarioSolicitacaoHoraExtra.lista,
  filtro: state.solicitacaoHoraExtra.filtro,
  usuario: state.auth.usuario,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
      initFormCarregado,
      salvar,
      excluir,
      getLista,
      getHorarioAtual,
      getListaHorarios,
      getListaMotivoSolicitacao,
      getListaMotivoRecusa,
      getListaUsuario,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitacaoHoraExtra);

function validarHorarioSolicitacao(horarioAtual, horarios) {
  const diaSemana = new Date(horarioAtual)
    .toLocaleDateString("pt-BR", {
      weekday: "long",
    })
    .replace("-", "_")
    .replace("ç", "c")
    .replace("á", "a");

  if (!horarios[0]) return false;
  const listaHorarios = horarios[0][diaSemana];

  if (!listaHorarios || !listaHorarios.length) return false;
  const resultados = listaHorarios.map((item) => {
    const horario = JSON.parse(item);
    const horarioInicio = horario.inicio.split(":");

    const horaInicio = horarioInicio[0];
    const minInicio = horarioInicio[1];
    const hojeIni = new Date(horarioAtual);
    hojeIni.setHours(horaInicio);
    hojeIni.setMinutes(minInicio);

    if (hojeIni > new Date(horarioAtual)) return false;

    const horarioFim = horario.fim.split(":");
    const horaFim = horarioFim[0];
    const minFim = horarioFim[1];
    const hojeFim = new Date(horarioAtual);
    hojeFim.setHours(horaFim);
    hojeFim.setMinutes(minFim);
    if (hojeFim < new Date(horarioAtual)) return false;
    return true;
  });
  return resultados.some((item) => item);
}
