import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function MenuItem(props) {
  return (
    <li
      className="nav-item"
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      {props.usuario.primeiroAcesso ? (
        <Link
          to={"/usuario"}
          className={`nav-link ${props.active ? "active" : ""}`}
          style={{
            paddingLeft: props.submenu ? 25 : null,
            backgroundColor: props.active && "#e91d25",
          }}
        >
          <i className={`nav-icon ${props.icon}`}></i>
          <p>
            {props.label}
            <span className={`badge badge-${props.spanType} right`}>
              {props.span}
            </span>
          </p>
        </Link>
      ) : (
        <Link
          to={props.path}
          className={`nav-link ${props.active ? "active" : ""}`}
          style={{
            paddingLeft: props.submenu ? 25 : null,
            backgroundColor: props.active && "#e91d25",
          }}
        >
          <i className={`nav-icon ${props.icon}`}></i>
          <p>
            {props.label}
            <span className={`badge badge-${props.spanType} right`}>
              {props.span}
            </span>
          </p>
        </Link>
      )}
    </li>
  );
}
const mapStateToProps = (state) => ({ usuario: state.auth.usuario });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
