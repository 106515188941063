import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import imagemLogo from "../assets/images/logo.png";
import imagemTesoura from "../assets/images/tesoura.png";

export function gerarPDF(memorando) {
  const nomeArquivo =
    "memorando_nr-" +
    memorando.numeracao +
    "_" +
    memorando.datahora_inclusao.replace(/\/|:/g, "-").replace(/\s/g, "_") +
    ".pdf";

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  //logo     nr memorando     data_criação
  const logo = {
    image: imagemLogo,
    fit: [100, 100],
    alignment: "left",
  };

  const tesoura = {
    image: imagemTesoura,
    fit: [20, 20],
    alignment: "left",
  };

  const tabela1 = {
    table: {
      widths: ["50%", "50%"],
      heights: [20, 20, 40, 10, 80, 80, 40, 10],
      headerRows: 0,
      //keepWithHeaderRows: 1,
      body: [
        [
          {
            columns: [
              logo,
              {
                text: "Memorando Nº " + memorando.numeracao,
                style: "titulo",
                alignment: "left",
                margin: [0, 5, 0, 0],
              },
              {
                text: "Data: " + memorando.datahora_inclusao.substring(0, 10),
                style: "titulo",
                alignment: "left",
                margin: [0, 5, 0, 0],
              },
            ],
            colSpan: 2,
          },
          {},
        ],
        [
          {
            text: "De:",
            style: "titulo",
            alignment: "left",
            margin: [0, 5, 0, 0],
          },
          {
            text: "Para:",
            style: "titulo",
            alignment: "left",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          [
            {
              text: [
                {
                  text: "Nome: ",
                  style: "titulo",
                  alignment: "left",
                },
                {
                  text:
                    memorando.nome_usuario_origem &&
                    memorando.nome_usuario_origem.length > 50
                      ? memorando.nome_usuario_origem.slice(0, 50) + "..."
                      : memorando.nome_usuario_origem,
                  alignment: "left",
                },
              ],
            },
            {
              text: [
                {
                  text: "E-mail: ",
                  style: "titulo",
                  alignment: "left",
                },
                {
                  text:
                    memorando.email_usuario_origem &&
                    memorando.email_usuario_origem.length > 50
                      ? memorando.email_usuario_origem.slice(0, 50) + "..."
                      : memorando.email_usuario_origem,
                  alignment: "left",
                },
              ],
            },
            {
              text: [
                {
                  text: "Local: ",
                  style: "titulo",
                  alignment: "left",
                },
                {
                  text:
                    memorando.descricao_local_origem &&
                    memorando.descricao_local_origem.length > 50
                      ? memorando.descricao_local_origem.slice(0, 50) + "..."
                      : memorando.descricao_local_origem,
                  alignment: "left",
                },
              ],
            },
          ],
          [
            {
              text: [
                {
                  text: "Nome: ",
                  style: "titulo",
                  alignment: "left",
                },
                {
                  text:
                    (memorando.nome_usuario_destino &&
                    memorando.nome_usuario_destino.length > 50
                      ? memorando.nome_usuario_destino.slice(0, 50) + "..."
                      : memorando.nome_usuario_destino) ||
                    (memorando.nome_destinatario_externo &&
                    memorando.nome_destinatario_externo.length > 50
                      ? memorando.nome_destinatario_externo.slice(0, 50) + "..."
                      : memorando.nome_destinatario_externo),
                  alignment: "left",
                },
              ],
            },
            {
              text: [
                {
                  text: "E-mail: ",
                  style: "titulo",
                  alignment: "left",
                },
                {
                  text:
                    (memorando.email_usuario_destino &&
                    memorando.email_usuario_destino.length > 50
                      ? memorando.email_usuario_destino.slice(0, 50) + "..."
                      : memorando.email_usuario_destino) ||
                    (memorando.email_destinatario_externo &&
                    memorando.email_destinatario_externo.length > 50
                      ? memorando.email_destinatario_externo.slice(0, 50) +
                        "..."
                      : memorando.email_destinatario_externo),
                  alignment: "left",
                },
              ],
            },
            {
              text: [
                {
                  text: "Local: ",
                  style: "titulo",
                  alignment: "left",
                },
                {
                  text:
                    memorando.descricao_local_destino &&
                    memorando.descricao_local_destino.length > 50
                      ? memorando.descricao_local_destino.slice(0, 50) + "..."
                      : memorando.descricao_local_destino,
                  alignment: "left",
                },
              ],
            },
          ],
        ],
        [
          {
            columns: [
              {
                text: [
                  {
                    text: "Assunto: ",
                    style: "titulo",
                    alignment: "left",
                  },
                  {
                    text:
                      memorando.assunto && memorando.assunto.length > 112
                        ? memorando.assunto.slice(0, 112) + "..."
                        : memorando.assunto,
                    alignment: "left",
                    margin: [0, 3, 0, 0],
                  },
                ],
              },
            ],
            colSpan: 2,
          },
          {},
        ],
        [
          {
            columns: [
              {
                text: [
                  {
                    text: "Comentário: ",
                    style: "titulo",
                    alignment: "left",
                  },
                  {
                    text:
                      memorando.comentario && memorando.comentario.length > 845
                        ? memorando.comentario.slice(0, 845) + "..."
                        : memorando.comentario,
                    alignment: "left",
                    margin: [0, 3, 0, 0],
                  },
                ],
              },
            ],
            colSpan: 2,
          },
          {},
        ],
        [
          {
            columns: [
              {
                text: [
                  {
                    text: "Anotações: ",
                    style: "titulo",
                    alignment: "left",
                  },
                  {
                    text:
                      memorando.anotacao && memorando.anotacao.length > 845
                        ? memorando.anotacao.slice(0, 845) + "..."
                        : memorando.anotacao,
                    alignment: "left",
                    margin: [0, 3, 0, 0],
                  },
                ],
              },
            ],
            colSpan: 2,
          },
          {},
        ],
        [
          { text: "", margin: [0, 40, 0, 0] },
          { text: "", margin: [0, 40, 0, 0] },
        ],
        [
          { text: "Visto Entregador", alignment: "center", style: "titulo" },
          { text: "Visto Recebedor", alignment: "center", style: "titulo" },
        ],
      ],
    },
  };

  const tabela2 = JSON.parse(JSON.stringify(tabela1));

  const pontilhadoCorte = {
    table: {
      body: [
        [
          tesoura,
          "_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _",
        ],
      ],
    },
    layout: { defaultBorder: false },
    margin: [0, 10, 0, 10],
  };

  const documento = {
    pageSize: "A4",
    pageMargins: [15, 45, 15, 0],
    info: {
      title: nomeArquivo,
    },
    content: [tabela1, pontilhadoCorte, tabela2],
    styles: { titulo: { fontSize: 11, bold: true } },
    defaultStyle: {
      fontSize: 10,
    },
  };

  pdfMake.createPdf(documento).open();
}
