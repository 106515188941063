import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import { connect } from "react-redux";
import Memorando from "../memorando/memorando";
import TipoMemorando from "../tipoMemorando/tipoMemorando";
import Local from "../local/local";
import MotivoSolicitacaoHoraExtra from "../motivoSolicitacaoHoraExtra/motivoSolicitacaoHoraExtra";
import MotivoRecusaSolicitacaoHoraExtra from "../motivoRecusaSolicitacaoHoraExtra/motivoRecusaSolicitacaoHoraExtra";
import HorarioSolicitacaoHoraExtra from "../horarioSolicitacaoHoraExtra/horarioSolicitacaoHoraExtra";
import SolicitacaoHoraExtra from "../solicitacaoHoraExtra/solicitacaoHoraExtra";
import SolicitacaoHoraExtraRelatorioPorSolicitacao from "../solicitacaoHoraExtra/solicitacaoHoraExtraRelatorioPorSolicitacao";
import SolicitacaoHoraExtraRelatorioPorColaborador from "../solicitacaoHoraExtra/solicitacaoHoraExtraRelatorioPorColaborador";
import Usuario from "../usuario/usuario";
import Setor from "../setor/setor";
import ESocial from "../esocial/esocial";

export default function (props) {
  const usuario = props.usuario || {};
  const acessoSetor = usuario.nome_perfil_usuario === "ADMINISTRADOR";
  const acessoTipoMemorando = usuario.nome_perfil_usuario === "ADMINISTRADOR";
  const acessoLocal = usuario.nome_perfil_usuario === "ADMINISTRADOR";
  const acessoSolicitacaoHoraExtraRelatorioPorSolicitacao =
    usuario.nome_perfil_usuario === "ADMINISTRADOR";
  const acessoSolicitacaoHoraExtraRelatorioPorColaborador = ![
    "PADRÃO",
    "RH",
    "SOLICITANTE GRUPO",
  ].includes(usuario.nome_perfil_usuario);

  const acessoMotivoSolicitacaoHoraExtra =
    usuario.nome_perfil_usuario === "ADMINISTRADOR";

  const acessoMotivoRecusaSolicitacaoHoraExtra =
    usuario.nome_perfil_usuario === "ADMINISTRADOR";

  const acessoHorarioSolicitacaoHoraExtra =
    usuario.nome_perfil_usuario === "ADMINISTRADOR";

  return (
    <div id="id-content-wrapper" className="content-wrapper">
      <Switch>
        <Route path="/memorando" component={Memorando} />
        <Route path="/usuario" component={Usuario} />
        <Route
          path="/tipoMemorando"
          component={acessoTipoMemorando ? TipoMemorando : Memorando}
        />
        <Route path="/local" component={acessoLocal ? Local : Memorando} />
        <Route path="/setor" component={acessoSetor ? Setor : Memorando} />
        <Route
          path="/solicitacaoHoraExtraRelatorioPorSolicitacao"
          component={
            acessoSolicitacaoHoraExtraRelatorioPorSolicitacao
              ? SolicitacaoHoraExtraRelatorioPorSolicitacao
              : Memorando
          }
        />
        <Route
          path="/solicitacaoHoraExtraRelatorioPorColaborador"
          component={
            acessoSolicitacaoHoraExtraRelatorioPorColaborador
              ? SolicitacaoHoraExtraRelatorioPorColaborador
              : Memorando
          }
        />
        <Route
          path="/motivoSolicitacaoHoraExtra"
          component={
            acessoMotivoSolicitacaoHoraExtra
              ? MotivoSolicitacaoHoraExtra
              : Memorando
          }
        />
        <Route
          path="/motivoRecusaSolicitacaoHoraExtra"
          component={
            acessoMotivoRecusaSolicitacaoHoraExtra
              ? MotivoRecusaSolicitacaoHoraExtra
              : Memorando
          }
        />
        <Route
          path="/horarioSolicitacaoHoraExtra"
          component={
            acessoHorarioSolicitacaoHoraExtra
              ? HorarioSolicitacaoHoraExtra
              : Memorando
          }
        />
        <Route path="/solicitacaoHoraExtra" component={SolicitacaoHoraExtra} />
        <Route path="/eSocial" component={ESocial} />
        {props.usuario.primeiroAcesso && <Redirect from="*" to="/usuario" />}
        <Redirect from="*" to="/memorando" />
      </Switch>
    </div>
  );
}
