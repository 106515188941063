import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";

import { setModoTela, initForm } from "./setorActions";

class SetorForm extends Component {
  state = {};

  componentWillMount() {}

  render() {
    let readOnly = this.props.excluir ? "readOnly" : "";
    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            <Row>
              <Field
                name="nome"
                component={LabelAndInput}
                label="Nome"
                placeholder="Informe o nome do setor"
                cols="12 12 12 12"
                readOnly={readOnly}
              />
            </Row>
          </ContentCardBody>
          <ContentCardFooter>
            <Row alignCenter>
              <Grid cols="6 6 4 3">
                {this.props.excluir ? (
                  <Button
                    text="Excluir"
                    submit
                    type={"danger"}
                    icon={"fa fa-trash"}
                  />
                ) : (
                  <Button
                    text="Salvar"
                    submit
                    type={"success"}
                    icon={"fa fa-check"}
                  />
                )}
              </Grid>
              <Grid cols="6 6 4 3">
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => this.props.setModoTela("lista")}
                />
              </Grid>
            </Row>
          </ContentCardFooter>
        </Form>
      </ContentCard>
    );
  }
}

SetorForm = reduxForm({
  form: "setorForm",
  destroyOnUnmount: false,
})(SetorForm);
const mapStateToProps = (state) => ({
  formularioValues: getFormValues("setorForm")(state),
  registro: state.setor.registro,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SetorForm);
