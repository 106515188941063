/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateFormat from "../common/dateFormat/DateFormat";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Select from "../common/form/select";

import { salvar, excluir, getRelatorio } from "./solicitacaoHoraExtraActions";
import { getLista as getListaUsuario } from "../usuario/usuarioActions";
class SolicitacaoHoraExtraRelatorio extends Component {
  state = {
    linhaSelecionada: null,
    filtro: {
      colaborador: null,
      dataInicial: DateFormat.formatarDataSqlParaTela(
        DateFormat.getDataAnterior(30)
      ),
      dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
    },
    usuarios: [],
  };

  componentWillMount() {
    this.props.getListaUsuario();
    this.props.getRelatorio(this.state.filtro);
    this.setState({
      ...this.state,
      usuarios: this.props.listaUsuario.filter((item) => {
        if (
          [
            "SUPERVISOR INTERMEDIÁRIO",
            "SUPERVISOR FINAL",
            "ADMINISTRADOR",
          ].includes(item.nome_perfil_usuario)
        )
          return;
        const idSetores = item.setores.map((item) => item.id_setor);
        let itemOk = false;
        for (let i = 0; i < this.props.usuario.setores.length; i++) {
          if (idSetores.includes(this.props.usuario.setores[i].id_setor)) {
            itemOk = true;
            break;
          }
        }
        return (
          itemOk || this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR"
        );
      }),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listaUsuario.length !== this.props.listaUsuario.length)
      this.setState({
        ...this.state,
        usuarios: this.props.listaUsuario.filter((item) => {
          if (
            [
              "SUPERVISOR INTERMEDIÁRIO",
              "SUPERVISOR FINAL",
              "ADMINISTRADOR",
            ].includes(item.nome_perfil_usuario)
          )
            return;
          const idSetores = item.setores.map((item) => item.id_setor);
          let itemOk = false;
          for (let i = 0; i < this.props.usuario.setores.length; i++) {
            if (idSetores.includes(this.props.usuario.setores[i].id_setor)) {
              itemOk = true;
              break;
            }
          }
          return (
            itemOk || this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR"
          );
        }),
      });
  }

  render() {
    return (
      <ContentCard style={{ paddingLeft: 10 }}>
        <ContentCardBody>
          <Row>
            {!this.state.usuarios.length && (
              <span style={{ paddingLeft: 8, paddingBlock: 10, color: "#F55" }}>
                É necessário estar vinculado a algum setor para emitir
                relatórios.
              </span>
            )}
          </Row>
          <Row>
            <Select
              options={this.state.usuarios.map((item) => ({
                id: item.id,
                valor: item.nome,
              }))}
              label="Colaborador"
              cols="12 6 6"
              isClearable
              placeholder="Selecione o colaborador"
              value={this.state.filtro.colaborador}
              readOnly={!this.state.usuarios.length}
              onChange={(value) => {
                const filtro = {
                  ...this.state.filtro,
                  colaborador: value,
                };
                this.setState({
                  ...this.state,
                  filtro: filtro,
                });
                this.props.getRelatorio(filtro);
              }}
            />
            <LabelAndInputMask
              label="Data/Hora Inicial"
              placeholder="Informe a data inicial"
              cols="12 3 3"
              mask="99/99/9999"
              value={this.state.filtro.dataInicial}
              readOnly={!this.state.usuarios.length}
              onChange={(data) => {
                const filtro = {
                  ...this.state.filtro,
                  dataInicial: data.target.value,
                };
                this.setState({
                  ...this.state,
                  filtro: filtro,
                });
                this.props.getRelatorio(filtro);
              }}
            />

            <LabelAndInputMask
              label="Data/Hora Final"
              placeholder="Informe a data final"
              cols="12 3 3"
              mask="99/99/9999"
              value={this.state.filtro.dataFinal}
              readOnly={!this.state.usuarios.length}
              onChange={(data) => {
                const filtro = {
                  ...this.state.filtro,
                  dataFinal: data.target.value,
                };
                this.setState({
                  ...this.state,
                  filtro: filtro,
                });
                this.props.getRelatorio(filtro);
              }}
            />
          </Row>
          <Table responsive>
            <TBody>
              <Tr
                style={{ borderBottom: "1px solid #ddd" }}
                className={`${
                  this.state.linhaSelecionada === 1 ? "hover" : ""
                }`}
                onClick={() =>
                  this.setState({
                    ...this.state,
                    linhaSelecionada: 1,
                  })
                }
                onMouseEnter={() =>
                  this.setState({
                    ...this.state,
                    linhaSelecionada: 1,
                  })
                }
              >
                <Th>Colaborador</Th>
                <Th>Periodo</Th>
                <Th>Total de Solicitações Pendentes</Th>
                <Th>Total de Solicitações Aprovadas</Th>
                <Th>Total de Solicitações Reprovadas</Th>
                <Th>Tempo Total de Hora Extra Cumprida</Th>
              </Tr>
              {this.props.relatorio
                .filter((item) =>
                  this.state.usuarios.find((usuario) => {
                    return usuario.nome === item.nome;
                  })
                )
                .map((item, i) => (
                  <Tr
                    key={i}
                    style={{ borderBottom: "1px solid #ddd" }}
                    className={`${
                      this.state.linhaSelecionada === 2 ? "hover" : ""
                    }`}
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        linhaSelecionada: 2,
                      })
                    }
                    onMouseEnter={() =>
                      this.setState({
                        ...this.state,
                        linhaSelecionada: 2,
                      })
                    }
                  >
                    <Td>
                      <div style={{ padding: 10 }}>{item.nome}</div>
                    </Td>
                    <Td>
                      {this.state.filtro.dataInicial +
                        " à " +
                        this.state.filtro.dataFinal}
                    </Td>
                    <Td>{item.pendentes}</Td>
                    <Td>{item.aprovadas}</Td>
                    <Td>{item.reprovadas}</Td>
                    <Td>
                      {item.horas_minutos &&
                        item.horas_minutos.replace(":", "h") + " min"}
                    </Td>
                  </Tr>
                ))}
            </TBody>
          </Table>
        </ContentCardBody>
      </ContentCard>
    );
  }
}

const mapStateToProps = (state) => ({
  relatorio: state.solicitacaoHoraExtra.relatorio,
  listaUsuario: state.usuario.lista,
  usuario: state.auth.usuario,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      salvar,
      excluir,
      getListaUsuario,
      getRelatorio,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitacaoHoraExtraRelatorio);
