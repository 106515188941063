import React from "react";

export default (props) => (
  <button
    type={"button"}
    className={`btn btn-flat ${props.small ? "btn-xs" : ""} btn-outline-${
      props.type
    }`}
    title={props.title}
    onClick={props.event}
    disabled={props.disabled}
    style={{
      minWidth: 40,
      minHeight: 40,
      margin: 2,
      visibility: props.visible ? "visible" : "collapse",
    }}
  >
    <i className={props.icon}></i>
  </button>
);

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
