/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import MenuItem from "./menuItem";
import { withRouter } from "react-router-dom";

class Menu extends Component {
  state = {
    menuMemorandoAberto: false,
    menuHoraExtraAberto: false,
  };

  render() {
    let selecionado = "";
    if (this.props.location && this.props.location.pathname) {
      selecionado = this.props.location.pathname;
    }

    return (
      <nav
        className="mt-2"
        onClick={() => {
          if (this.props.usuario.primeiroAcesso) {
            this.setState({
              ...this.state,
              menuMemorandoAberto: false,
              menuHoraExtraAberto: false,
            });
            window.confirm(
              "Você ainda está com a senha temporária. Cadastre uma nova senha."
            );
          }
        }}
      >
        <ul className="nav nav-sidebar flex-column">
          {["ADMINISTRADOR", "RH"].includes(
            this.props.usuario.nome_perfil_usuario
          ) && (
            <MenuItem
              path="usuario"
              label="Usuários"
              icon="fas fa-users"
              active={selecionado === "/usuario"}
              onClick={() => {
                this.setState({
                  ...this.state,
                  menuMemorandoAberto: false,
                  menuHoraExtraAberto: false,
                });
              }}
            />
          )}
          {this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR" && (
            <>
              <MenuItem
                path="setor"
                label="Setores"
                icon="fab fa-buromobelexperte"
                active={selecionado === "/setor"}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    menuMemorandoAberto: false,
                    menuHoraExtraAberto: false,
                  });
                }}
              />
            </>
          )}
          <li className={`nav-item has-treeview`}>
            <a
              role="button"
              className="nav-link"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  ...this.state,
                  menuMemorandoAberto: !this.state.menuMemorandoAberto,
                });
              }}
            >
              <i className="nav-icon fas fa fa-clipboard-list"></i>
              <p>Memorandos</p>
            </a>
            <ul
              className="nav flex-column nav-sidebar"
              style={{
                display: this.state.menuMemorandoAberto ? "block" : "none",
              }}
            >
              <MenuItem
                path="memorando"
                label="Cadastrar/Listar"
                submenu
                icon="fa fa-tasks"
                active={selecionado === "/memorando"}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    menuMemorandoAberto: true,
                    menuHoraExtraAberto: false,
                  });
                }}
              />
              {this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR" && (
                <>
                  <MenuItem
                    path="tipoMemorando"
                    label="Tipos"
                    submenu
                    icon="fa fa-paste"
                    active={selecionado === "/tipoMemorando"}
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        menuMemorandoAberto: true,
                        menuHoraExtraAberto: false,
                      });
                    }}
                  />

                  <MenuItem
                    path="local"
                    label="Locais"
                    submenu
                    icon="fa fa-warehouse"
                    active={selecionado === "/local"}
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        menuMemorandoAberto: true,
                        menuHoraExtraAberto: false,
                      });
                    }}
                  />
                </>
              )}
            </ul>
          </li>
          <li className={`nav-item has-treeview`}>
            <a
              role="button"
              className="nav-link"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  ...this.state,
                  menuHoraExtraAberto: !this.state.menuHoraExtraAberto,
                });
              }}
            >
              <i className="nav-icon fas fa-business-time"></i>
              <p>Hora Extra</p>
            </a>
            <ul
              className="nav flex-column nav-sidebar"
              style={{
                display: this.state.menuHoraExtraAberto ? "block" : "none",
              }}
            >
              <MenuItem
                path="solicitacaoHoraExtra"
                label="Cadastrar/Listar"
                icon="fas fa-user-clock"
                submenu
                active={selecionado === "/solicitacaoHoraExtra"}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    menuHoraExtraAberto: true,
                    menuMemorandoAberto: false,
                  });
                }}
              />
              {this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR" && (
                <MenuItem
                  path="solicitacaoHoraExtraRelatorioPorSolicitacao"
                  label="Relatório por Solicitação"
                  icon="fas fa-file-alt"
                  submenu
                  active={
                    selecionado ===
                    "/solicitacaoHoraExtraRelatorioPorSolicitacao"
                  }
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      menuHoraExtraAberto: true,
                      menuMemorandoAberto: false,
                    });
                  }}
                />
              )}
              {!["PADRÃO", "RH", "SOLICITANTE GRUPO"].includes(
                this.props.usuario.nome_perfil_usuario
              ) && (
                <MenuItem
                  path="solicitacaoHoraExtraRelatorioPorColaborador"
                  label="Relatório por Colaborador"
                  icon="fas fa-file-alt"
                  submenu
                  active={
                    selecionado ===
                    "/solicitacaoHoraExtraRelatorioPorColaborador"
                  }
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      menuHoraExtraAberto: true,
                      menuMemorandoAberto: false,
                    });
                  }}
                />
              )}
              {this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR" && (
                <>
                  <MenuItem
                    path="motivoSolicitacaoHoraExtra"
                    label="Motivo de Solicitação"
                    icon="fas fa-question-circle"
                    submenu
                    active={selecionado === "/motivoSolicitacaoHoraExtra"}
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        menuHoraExtraAberto: true,
                        menuMemorandoAberto: false,
                      });
                    }}
                  />
                  <MenuItem
                    path="motivoRecusaSolicitacaoHoraExtra"
                    label="Motivo de Recusa"
                    icon="fas fa-ban"
                    submenu
                    active={selecionado === "/motivoRecusaSolicitacaoHoraExtra"}
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        menuHoraExtraAberto: true,
                        menuMemorandoAberto: false,
                      });
                    }}
                  />

                  <MenuItem
                    path="horarioSolicitacaoHoraExtra"
                    label="Horários de Solicitação"
                    icon="fas fa-clock"
                    submenu
                    active={selecionado === "/horarioSolicitacaoHoraExtra"}
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        menuHoraExtraAberto: true,
                        menuMemorandoAberto: false,
                      });
                    }}
                  />
                </>
              )}
            </ul>
          </li>
          {(this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR" || this.props.usuario.nome_perfil_usuario === "RH") && (
            <>
              <MenuItem
                path="eSocial"
                label="eSocial"
                icon="fas fa-file-import"
                active={selecionado === "/eSocial"}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    menuMemorandoAberto: false,
                    menuHoraExtraAberto: false,
                  });
                }}
              />
            </>
          )}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default withRouter(connect(mapStateToProps)(Menu));
