import DateFormat from "../common/dateFormat/DateFormat";

const INITIAL_STATE = {
  registro: {},
  modoTela: "lista",
  filtro: {
    data_inicial_inclusao: DateFormat.formatarDataSqlParaTela(
      DateFormat.getDataAnterior(30)
    ),
    data_final_inclusao: DateFormat.formatarDataSqlParaTela(
      DateFormat.getDataAtual()
    ),
    tipo: null,
  },
  lista: [],
  listaTipoMemorando: [],
  listaLocal: [],
  listaUsuario: [],
  memorandoIncluido: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "MEMORANDO_MODO_TELA":
      return {
        ...state,
        memorandoIncluido:
          action.payload.modo === "cadastro" ? {} : state.memorandoIncluido,
        modoTela: action.payload.modo,
        registro: action.payload.registro,
      };

    case "MEMORANDO_LISTADO":
      action.payload.data.forEach(
        (memorando, index, listaDeMemorandos) =>
          (listaDeMemorandos[index] = formataDatasMemorando(memorando))
      );
      return {
        ...state,
        lista: action.payload.data,
      };

    case "MEMORANDO_INCLUIDO":
      return {
        ...state,
        memorandoIncluido: formataDatasMemorando(action.payload.data[0]),
      };
    case "DATA_MEMORANDO_FILTRO":
      return { ...state, filtro: action.payload.filtro };

    case "TIPO_MEMORANDO_LISTADO":
      return {
        ...state,
        listaTipoMemorando: action.payload.data,
      };
    case "LOCAL_LISTADO":
      return {
        ...state,
        listaLocal: action.payload.data,
      };
    case "USUARIO_LISTADO":
      return {
        ...state,
        listaUsuario: action.payload.data,
      };

    default:
      return state;
  }
};

function formataDatasMemorando(memorando) {
  return {
    ...memorando,
    datahora_inclusao: DateFormat.formatarDataHoraSqlParaTela(
      memorando.datahora_inclusao
    ),
    datahora_alteracao: DateFormat.formatarDataHoraSqlParaTela(
      memorando.datahora_alteracao
    ),
    datahora_envio: DateFormat.formatarDataHoraSqlParaTela(
      memorando.datahora_envio
    ),
    datahora_recebimento: DateFormat.formatarDataHoraSqlParaTela(
      memorando.datahora_recebimento
    ),
  };
}
