import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess, setError } from "../common/toast/toast";
import { initialize } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";

export function setModoTela(modo) {
  return {
    type: "SOLICITACAO_HORA_EXTRA_MODO_TELA",
    payload: {
      modo: modo,
    },
  };
}
export function initForm(registro = {}) {
  return [initialize("solicitacaoHoraExtraForm", registro)];
}

export function initFormCarregado(id, modoTela) {
  return (dispatch, getState) => {
    axios
      .get(`${consts.API_URL}/solicitacaoHoraExtra?id=${id}`)
      .then((resp) => {
        const resposta = resp.data[0];
        if (!resposta) {
          dispatch(getLista());
          dispatch(setModoTela("lista"));
          setError("A solicitação já foi excluída pelo solicitante");
        } else if (
          resposta.id_supervisor_final ||
          resposta.id_motivo_recusa ||
          (resposta.aprovada_intermediario &&
            resposta.id_supervisor_intermediario &&
            getState().auth.usuario.nome_perfil_usuario !== "SUPERVISOR FINAL")
        ) {
          dispatch(getLista());
          dispatch(setModoTela("lista"));
          setError("A solicitação já foi modificada pelo supervisor");
        } else {
          resposta.aprovada = modoTela === "aprovar";
          resposta.data_hora_extra = resposta.data_hora_extra.substring(0, 10);
          dispatch(initForm(resposta));
          dispatch(setModoTela(modoTela));
        }
      });
  };
}

export function getRelatorio({ colaborador, dataInicial, dataFinal }) {
  return (dispatch, getState) => {
    if (
      DateFormat.formatarDataTelaParaSql(dataInicial) === "Invalid DateTime" ||
      DateFormat.formatarDataTelaParaSql(dataFinal) === "Invalid DateTime"
    )
      return;
    axios
      .get(
        `${
          consts.API_URL
        }/solicitacaoHoraExtraRelatorio?colaborador=${colaborador}&dataInicial=${DateFormat.formatarDataTelaParaSql(
          dataInicial
        )}&dataFinal=${DateFormat.formatarDataTelaParaSql(dataFinal)}`
      )
      .then((resp) => {
        dispatch({
          type: "SOLICITACAO_RELATORIO",
          payload: resp,
        });
      });
  };
}

export function getHorarioAtual() {
  return (dispatch, getState) => {
    axios.get(`${consts.API_URL}/horarioAtual`).then((resp) => {
      dispatch({
        type: "HORARIO_ATUAL_LISTADO",
        payload: resp,
      });
    });
  };
}

export function getLista() {
  return (dispatch, getState) => {
    axios.get(`${consts.API_URL}/solicitacaoHoraExtra`).then((resp) => {
      dispatch({
        type: "SOLICITACAO_HORA_EXTRA_LISTADO",
        payload: resp,
      });
    });
  };
}

export function salvar(registro) {
  return (dispatch, getState) => {
    if (!registro.id) {
      axios
        .post(`${consts.API_URL}/solicitacaoHoraExtra`, {
          ...registro,
        })
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch(getLista());
          dispatch(setModoTela("lista"));
        })
        .catch((e) => {
          setErroAPI(e);
        });
    } else {
      const usuario = getState().auth.usuario;

      if (usuario.nome_perfil_usuario === "SUPERVISOR INTERMEDIÁRIO") {
        if (registro.id_motivo_recusa) {
          registro.id_supervisor_intermediario = usuario.id;
        }
        if (registro.aprovada) {
          registro.id_supervisor_intermediario = usuario.id;
          delete registro.aprovada;
          registro.aprovada_intermediario = true;
        }
      }

      if (usuario.nome_perfil_usuario === "SUPERVISOR FINAL") {
        if (registro.id_motivo_recusa) {
          registro.id_supervisor_final = usuario.id;
        }
        if (registro.aprovada) {
          registro.id_supervisor_final = usuario.id;
          delete registro.aprovada;
          registro.aprovada_final = true;
        }
      }

      axios
        .put(`${consts.API_URL}/solicitacaoHoraExtra`, {
          ...registro,
        })
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch(getLista());
          dispatch(setModoTela("lista"));
        })
        .catch((e) => {
          setErroAPI(e);
        });
    }
  };
}

export function excluir(registro) {
  return (dispatch) => {
    axios
      .delete(`${consts.API_URL}/solicitacaoHoraExtra?id=${registro.id}`)
      .then((resp) => {
        setSuccess("Operação Realizada com sucesso.");
        dispatch(getLista());
        dispatch(setModoTela("lista"));
      })
      .catch((e) => {
        setErroAPI(e);
      });
  };
}
