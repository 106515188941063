/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "../../auth/authActions";
import { setModoTela, initForm, salvar } from "../../usuario/usuarioActions";
import Content from "./content";
import Form from "../../usuario/usuarioForm";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  changeOpen() {
    this.setState({ open: !this.state.open });
  }
  render() {
    const { imagem_perfil, matricula, nome, nome_de_usuario } =
      this.props.usuario;

    return (
      <>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-user"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">Perfil</span>
              <div style={{ textAlign: "center", padding: "10px" }}>
                {imagem_perfil ? (
                  <img
                    src={imagem_perfil}
                    style={{
                      height: 100,
                      maxWidth: 200,
                      objectFit: "contain",
                    }}
                    alt="Imagem de perfil"
                  />
                ) : (
                  <i
                    className="fas fa-user"
                    style={{
                      height: 100,
                      padding: 30,
                      fontSize: 50,
                      color: "#777",
                    }}
                  ></i>
                )}
              </div>
              <div className="dropdown-divider"></div>
              <span className="dropdown-item">
                <i className="fas fa-list-ol mr-2"></i> Matrícula
                <span className="float-right text-muted text-sm">
                  {matricula}
                </span>
              </span>
              <a role="button" className="dropdown-item">
                <i className="fas fa-id-card mr-2"></i> Nome
                <span className="float-right text-muted text-sm">{nome}</span>
              </a>
              <div className="dropdown-divider"></div>
              <a
                style={{ minHeight: 60 }}
                href="#/usuario"
                title="Configurações"
                className="dropdown-item"
                onClick={() => {
                  this.props.setModoTela("alteracao", {
                    ...this.props.usuario,
                    senha: null,
                  });
                  this.props.initForm({
                    ...this.props.usuario,
                    senha: null,
                  });
                }}
              >
                <i className="fas fa-user-cog mr-2"></i> Usuário
                <span className="float-right text-muted text-sm">
                  {nome_de_usuario}
                </span>
              </a>
              <div className="dropdown-divider"></div>
              <a
                href="#"
                onClick={this.props.logout}
                className="btn btn-block btn-primary btn-sm"
              >
                Sair
              </a>
            </div>
          </li>
        </ul>
        {this.props.modoTela === "cadastro" && (
          <div>
            <Content>
              <Form onSubmit={this.props.salvar} />
            </Content>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout, setModoTela, initForm, salvar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
