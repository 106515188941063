import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

import AuthReducer from "../auth/authReducer";
import MemorandoReducer from "../memorando/memorandoReducer";
import TipoMemorandoReducer from "../tipoMemorando/tipoMemorandoReducer";
import LocalReducer from "../local/localReducer";
import MotivoSolicitacaoHoraExtraReducer from "../motivoSolicitacaoHoraExtra/motivoSolicitacaoHoraExtraReducer";
import MotivoRecusaSolicitacaoHoraExtraReducer from "../motivoRecusaSolicitacaoHoraExtra/motivoRecusaSolicitacaoHoraExtraReducer";
import HorarioSolicitacaoHoraExtraReducer from "../horarioSolicitacaoHoraExtra/horarioSolicitacaoHoraExtraReducer";
import SolicitacaoHoraExtraReducer from "../solicitacaoHoraExtra/solicitacaoHoraExtraReducer";
import UsuarioReducer from "../usuario/usuarioReducer";
import SetorReducer from "../setor/setorReducer";
import ESocialReducer from "../esocial/esocialReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  memorando: MemorandoReducer,
  tipoMemorando: TipoMemorandoReducer,
  local: LocalReducer,
  motivoSolicitacaoHoraExtra: MotivoSolicitacaoHoraExtraReducer,
  motivoRecusaSolicitacaoHoraExtra: MotivoRecusaSolicitacaoHoraExtraReducer,
  horarioSolicitacaoHoraExtra: HorarioSolicitacaoHoraExtraReducer,
  solicitacaoHoraExtra: SolicitacaoHoraExtraReducer,
  usuario: UsuarioReducer,
  setor: SetorReducer,
  eSocial: ESocialReducer,
  form: formReducer,
  toastr: toastrReducer,
});

export default rootReducer;
