import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInputDateTime from "../common/form/labelAndInputDateTime";
import Select from "../common/form/select";

import { setModoTela, initForm } from "./horarioSolicitacaoHoraExtraActions";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";

class HorarioSolicitacaoHoraExtraForm extends Component {
  state = {
    horarios: {
      segunda_feira: [],
      terca_feira: [],
      quarta_feira: [],
      quinta_feira: [],
      sexta_feira: [],
      sabado: [],
      domingo: [],
    },
    horariosEmDigitacao: {
      segunda_feira: {},
      terca_feira: {},
      quarta_feira: {},
      quinta_feira: {},
      sexta_feira: {},
      sabado: {},
      domingo: {},
    },
    diaSelecionado: "segunda_feira",
  };

  componentWillMount() {}

  incluirHorario() {
    const estado = JSON.parse(JSON.stringify(this.state));
    const horario = estado.horariosEmDigitacao[this.state.diaSelecionado];

    if (
      !horario.inicio ||
      horario.inicio.includes(" ") ||
      !horario.fim ||
      horario.fim.includes(" ")
    )
      return;
    estado.horarios[this.state.diaSelecionado].push(
      estado.horariosEmDigitacao[this.state.diaSelecionado]
    );
    estado.horariosEmDigitacao[this.state.diaSelecionado] = {};
    this.setState(estado);
    this.props.initForm({ ...estado.horarios });
  }

  render() {
    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            {/* passa o nome no label e o nome da propriedade que está no state */}
            {this.renderizarInputHorarios()}
            {this.renderizarTabelaHorarios()}
          </ContentCardBody>
          <ContentCardFooter>
            <Row alignCenter>
              <Grid cols="6 6 4 3">
                <Button
                  text="Salvar"
                  submit
                  disabled={this.props.usuario.nome_perfil_usuario === "PADRÃO"}
                  type={"success"}
                  icon={"fa fa-check"}
                />
              </Grid>
              <Grid cols="6 6 4 3">
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => this.props.setModoTela("lista")}
                />
              </Grid>
            </Row>
          </ContentCardFooter>
        </Form>
      </ContentCard>
    );
  }

  renderizarTabelaHorarios() {
    const exibeHorario = (item, i) => (
      <p key={i}>{`${item.inicio}h às ${item.fim}h`}</p>
    );
    return (
      <Table responsive>
        <THead>
          <Tr>
            <Th>Segunda-feira</Th>
            <Th>Terça-feira</Th>
            <Th>Quarta-feira</Th>
            <Th>Quinta-feira</Th>
            <Th>Sexta-feira</Th>
            <Th>Sábado</Th>
            <Th>Domingo</Th>
          </Tr>
        </THead>
        <TBody>
          <Tr>
            <Td>{this.state.horarios.segunda_feira.map(exibeHorario)}</Td>
            <Td>{this.state.horarios.terca_feira.map(exibeHorario)}</Td>
            <Td>{this.state.horarios.quarta_feira.map(exibeHorario)}</Td>
            <Td>{this.state.horarios.quinta_feira.map(exibeHorario)}</Td>
            <Td>{this.state.horarios.sexta_feira.map(exibeHorario)}</Td>
            <Td>{this.state.horarios.sabado.map(exibeHorario)}</Td>
            <Td>{this.state.horarios.domingo.map(exibeHorario)}</Td>
          </Tr>
        </TBody>
      </Table>
    );
  }

  renderizarInputHorarios() {
    const listaDias = [
      { id: "segunda_feira", valor: "Segunda-feira" },
      { id: "terca_feira", valor: "Terça-feira" },
      { id: "quarta_feira", valor: "Quarta-feira" },
      { id: "quinta_feira", valor: "Quinta-feira" },
      { id: "sexta_feira", valor: "Sexta-feira" },
      { id: "sabado", valor: "Sábado" },
      { id: "domingo", valor: "Domingo" },
    ];
    return (
      <Row alignitems="end">
        <Grid cols="12 12 3 3">
          <Select
            name="dias_da_semana"
            defaultSelected="Segunda-feira"
            options={listaDias}
            onChange={(dia) => {
              this.setState({
                ...this.state,
                diaSelecionado: dia,
              });
            }}
          />
        </Grid>
        <LabelAndInputDateTime
          label="Início"
          mask="99:99"
          placeholder="00:00"
          cols="12 12 3 3"
          value={
            this.state.horariosEmDigitacao[this.state.diaSelecionado].inicio ||
            ""
          }
          onChange={(e) => {
            const horario = formataHorario(e.target.value);
            const estado = JSON.parse(JSON.stringify(this.state));
            estado.horariosEmDigitacao[this.state.diaSelecionado].inicio =
              horario;
            this.setState(estado);
          }}
        />
        <LabelAndInputDateTime
          label="Fim"
          mask="99:99"
          placeholder="00:00"
          cols="12 12 3 3"
          value={
            this.state.horariosEmDigitacao[this.state.diaSelecionado].fim || ""
          }
          onChange={(e) => {
            const horario = formataHorario(e.target.value);
            const estado = JSON.parse(JSON.stringify(this.state));
            estado.horariosEmDigitacao[this.state.diaSelecionado].fim = horario;
            this.setState(estado);
          }}
        />
        <Grid cols="12 12 3 3" style={{ marginBlock: 10 }}>
          <Button
            text="Incluir Horário"
            disabled={this.props.usuario.nome_perfil_usuario === "PADRÃO"}
            event={() => this.incluirHorario()}
            type={"info"}
          />
        </Grid>
      </Row>
    );
  }
}

HorarioSolicitacaoHoraExtraForm = reduxForm({
  form: "horarioSolicitacaoHoraExtraForm",
  destroyOnUnmount: false,
})(HorarioSolicitacaoHoraExtraForm);
const mapStateToProps = (state) => ({
  formularioValues: getFormValues("horarioSolicitacaoHoraExtraForm")(state),
  registro: state.horarioSolicitacaoHoraExtra.registro,
  usuario: state.auth.usuario,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HorarioSolicitacaoHoraExtraForm);

function formataHorario(horario) {
  //recebe no formato hh:mm
  if (parseInt(horario[0]) > 2) return "0";
  if (parseInt(horario[0]) === 2 && parseInt(horario[1]) > 3) return "00";
  if (parseInt(horario[3]) > 5) return horario.substring(0, 3) + "0";
  return horario;
}
