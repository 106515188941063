const INITIAL_STATE = {
  registro: {},
  modoTela: "lista",
  lista: [
    { matricula: '2549', cpf: '58759530049', nome: 'ABEL LUIS VIESSER' },
    { matricula: '799', cpf: '02897399007', nome: 'ADAM LUCAS MUNIZ MULINA' },
    { matricula: '2035', cpf: '23653358884', nome: 'ADAMA SALL' },
    { matricula: '2584', cpf: '02582076040', nome: 'ADAO LIMA DOS SANTOS JUNIOR' },
    { matricula: '441', cpf: '01366261062', nome: 'ADELAR NUGLISCH' },
    { matricula: '401', cpf: '78996120049', nome: 'ADEMAR JOAO ZAYAEZKOSKI' },
    { matricula: '720', cpf: '00113282079', nome: 'ADEMIR TEIXEIRA DA ROSA' },
    { matricula: '402', cpf: '50214764087', nome: 'ADILSON LEMOS DA SILVA' },
    { matricula: '89', cpf: '43825036049', nome: 'ADOLFO JAKOBSON' },
    { matricula: '2152', cpf: '00077264061', nome: 'ADRIANA VIEGAS DE OLIVEIRA SILVA' },
    { matricula: '2573', cpf: '04441439989', nome: 'ADRIANO CORDEIRO RODRIGUES' },
    { matricula: '2246', cpf: '02972117069', nome: 'ADRIANO DA ROSA' },
    { matricula: '2037', cpf: '00972534059', nome: 'ADRIANO DE JESUS NASCIMENTO GABERT' },
    { matricula: '88', cpf: '88422917068', nome: 'ADRIANO SIQUEIRA PEREIRA' },
    { matricula: '1777', cpf: '01664951075', nome: 'AFONSO KUNTZ BOHN' },
    { matricula: '2526', cpf: '02920591088', nome: 'AGATHA NILA CORREA MACIEL' },
    { matricula: '2145', cpf: '89882881068', nome: 'AIRES FABIANO DOS ANJOS CASTRO' },
    { matricula: '204', cpf: '53567811053', nome: 'AIRTON ALMIR BARRETO DE BITTENCOURT' },
    { matricula: '2360', cpf: '77702760044', nome: 'AIRTON ROSA DORNELES' },
    { matricula: '679', cpf: '00333269080', nome: 'AISLAN MINUSSI COLPO' },
    { matricula: '2594', cpf: '04034696001', nome: 'ALAN CRISTIAN DE SOUZA SARAIVA' },
    { matricula: '2252', cpf: '03919265009', nome: 'ALANA CLEMENTE DA COSTA' },
    { matricula: '274', cpf: '68200013049', nome: 'ALCINDO ARAUJO DOS SANTOS' },
    { matricula: '45', cpf: '34491945004', nome: 'ALCINO LOPES RAMOS' },
    { matricula: '2533', cpf: '04705964063', nome: 'ALECSANDER SCHONWALD DE OLIVEIRA' },
    { matricula: '316', cpf: '04080224001', nome: 'ALESANDRA FATIMA DA SILVA SCHEUER' },
    { matricula: '211', cpf: '06149491900', nome: 'ALESANDRO GLINSKI' },
    { matricula: '2577', cpf: '02731186097', nome: 'ALESSANDRA DOS SANTOS NICOLETTI' },
    { matricula: '1798', cpf: '03335075099', nome: 'ALESSANDRA MALLMANN MONTEIRO' },
    { matricula: '1866', cpf: '03649316048', nome: 'ALESSANDRO DA SILVA MATTNER' },
    { matricula: '226', cpf: '76737667015', nome: 'ALESSANDRO MARQUES ETCHEVERRY' },
    { matricula: '2104', cpf: '03139615060', nome: 'ALESSANDRO SILVA DA ROSA' },
    { matricula: '307', cpf: '00681622008', nome: 'ALESSANDRO SILVEIRA FAGUNDES' },
    { matricula: '2008', cpf: '05016425957', nome: 'ALEX APARECIDO FERREIRA' },
    { matricula: '2593', cpf: '01182288030', nome: 'ALEX DUARTE SANTOS' },
    { matricula: '2110', cpf: '02962481000', nome: 'ALEX JUNIOR CRUZ DA ROSA' },
    { matricula: '163', cpf: '10052124908', nome: 'ALEX JUNIOR DE CASTRO' },
    { matricula: '397', cpf: '04461455050', nome: 'ALEX SANDER DA VIDES DA SILVA' },
    { matricula: '2426', cpf: '01844844056', nome: 'ALEX SANDRO CANABARRO REGASSON' },
    { matricula: '2200', cpf: '01461175062', nome: 'ALEX SANDRO SOUZA DO CANTO' },
    { matricula: '262', cpf: '00053462076', nome: 'ALEXANDRE ANDRADE KUNT' },
    { matricula: '407', cpf: '04458909088', nome: 'ALEXANDRE DA VIDES DA SILVA' },
    { matricula: '2442', cpf: '00307116069', nome: 'ALEXANDRE E SILVA DA SILVA' },
    { matricula: '1835', cpf: '01060078074', nome: 'ALEXANDRE RODRIGUES PINTO' },
    { matricula: '2171', cpf: '70352208015', nome: 'ALEXSANDRO BATISTA VEIGA' },
    { matricula: '2364', cpf: '02727031092', nome: 'ALEXSANDRO MUNIZ BARRETO VERA' },
    { matricula: '220', cpf: '01999910060', nome: 'ALISON DA ROSA VELASQUE' },
    { matricula: '2361', cpf: '02460049080', nome: 'ALISON NUNES DE CARVALHO' },
    { matricula: '2598', cpf: '03276124017', nome: 'ALISSON OLIVEIRA SCHIMIT' },
    { matricula: '666', cpf: '03227586029', nome: 'ALISSON SCHRODER NIGOLINO' },
    { matricula: '1778', cpf: '04103163933', nome: 'ALTAMIRO RODRIGUES JUNIOR' },
    { matricula: '1900', cpf: '00508162092', nome: 'ALVARINO DOMINGUES' },
    { matricula: '2410', cpf: '04479034030', nome: 'AMANDA BONES SCHIRMANN' },
    { matricula: '2295', cpf: '03464756009', nome: 'AMANDA LACERDA DE ALMEIDA' },
    { matricula: '1872', cpf: '01489985930', nome: 'AMAURI SEBASTIAO RIBEIRO GONSALVES' },
    { matricula: '1917', cpf: '53213343587', nome: 'ANARY MACHADO DOS SANTOS' },
    { matricula: '2589', cpf: '00170031055', nome: 'ANDERSON BATISTA TRINDADE' },
    { matricula: '2542', cpf: '98602829000', nome: 'ANDERSON DE MELLO SCHEFFER' },
    { matricula: '418', cpf: '32640019856', nome: 'ANDERSON DE MOURA DA SILVA' },
    { matricula: '2245', cpf: '01016522088', nome: 'ANDERSON DOS SANTOS' },
    { matricula: '2224', cpf: '03489931025', nome: 'ANDERSON FELIPE COSTA VEIGA' },
    { matricula: '400', cpf: '03236348003', nome: 'ANDERSON FELIPE MALLMANN MONTEIRO' },
    { matricula: '2415', cpf: '07144575902', nome: 'ANDERSON GRABOWSKI' },
    { matricula: '2248', cpf: '01377806057', nome: 'ANDERSON LANSING MARTINS' },
    { matricula: '449', cpf: '02536239012', nome: 'ANDERSON OLIVEIRA DA CRUZ' },
    { matricula: '255', cpf: '00159624070', nome: 'ANDERSON SANTOS DE LIMA' },
    { matricula: '2281', cpf: '95944885068', nome: 'ANDRE ADEMIR CANABARRO NUNES' },
    { matricula: '2509', cpf: '03124626036', nome: 'ANDRE GLITZ BORGES' },
    { matricula: '2492', cpf: '03360734025', nome: 'ANDRE MACHADO DE LIMA JUNIOR' },
    { matricula: '1930', cpf: '83780319004', nome: 'ANDRE OLIVEIRA DE ALMEIDA E SILVA' },
    { matricula: '1907', cpf: '00046122052', nome: 'ANDRE OLIVEIRA DEMBINSKI' },
    { matricula: '2499', cpf: '01882038002', nome: 'ANDRE SALLES PROENCA' },
    { matricula: '127', cpf: '96339357091', nome: 'ANDRE SANTOS DE LIMA' },
    { matricula: '430', cpf: '31444535870', nome: 'ANDREA ANDRADE ALVES' },
    { matricula: '2497', cpf: '91310350515', nome: 'ANDREA BARROS MOREIRA' },
    { matricula: '2092', cpf: '10169561933', nome: 'ANDREI FABIO LEITE' },
    { matricula: '2613', cpf: '03159535002', nome: 'ANDREI FELIPE DA SILVA HAMMARSTROM' },
    { matricula: '2612', cpf: '03796704026', nome: 'ANDREI LUZ DE MOURA' },
    { matricula: '336', cpf: '00622624083', nome: 'ANDREI REGIS DORN COPPETTI' },
    { matricula: '2270', cpf: '01034908090', nome: 'ANGELO ANTONIO VIANA LOPES' },
    { matricula: '2011', cpf: '01833142047', nome: 'ANIZIO NUNES DA VEIGA NETO' },
    { matricula: '1915', cpf: '01580120032', nome: 'ANTONIO AUGUSTO AFONSO ALVES' },
    { matricula: '420', cpf: '33764334886', nome: 'ANTONIO MARCOS DE MENESES' },
    { matricula: '76', cpf: '65683960010', nome: 'ANTONIO MARCOS MARONEZE DALENOGARE' },
    { matricula: '26', cpf: '00452743044', nome: 'ANTONIO RUI GROMOWSKI' },
    { matricula: '2257', cpf: '01963701917', nome: 'ANTONIO VANDERLEI RIBEIRO' },
    { matricula: '752', cpf: '00176704035', nome: 'ARCENIO ANTONIO STEFFENS' },
    { matricula: '2569', cpf: '04678062092', nome: 'ARMANDO IRASSOQUE FERREIRA' },
    { matricula: '7', cpf: '26969750515', nome: 'ARNALDO AMARAL' },
    { matricula: '736', cpf: '28409043068', nome: 'ARNILDO HELIO LECHEVETZ' },
    { matricula: '180', cpf: '90534069053', nome: 'BENHUR FREITAS OLIVEIRA' },
    { matricula: '2580', cpf: '02782382039', nome: 'BENHUR PAIM' },
    { matricula: '814', cpf: '69655669068', nome: 'BIANOR BECK FREIBERG' },
    { matricula: '438', cpf: '38418264829', nome: 'BRENON SCHIMITH DE SIQUEIRA' },
    { matricula: '2513', cpf: '02970329093', nome: 'BRUNA SCHNEIDER PADILHA' },
    { matricula: '2477', cpf: '03161155092', nome: 'BRUNO ALBUQUERQUE MARTINS' },
    { matricula: '2523', cpf: '04765917088', nome: 'BRUNO DA SILVA CARVALHO' },
    { matricula: '2601', cpf: '03878399928', nome: 'BRUNO DE OLIVEIRA DA SILVA' },
    { matricula: '2438', cpf: '08114913401', nome: 'BRUNO DIEGO SILVA MENDES DE LIMA' },
    { matricula: '2028', cpf: '03518126016', nome: 'BRUNO DO NASCIMENTO SILVA OLIVEIRA' },
    { matricula: '2355', cpf: '02648501061', nome: 'BRUNO GONCALVES' },
    { matricula: '2243', cpf: '01999408004', nome: 'BRUNO MACHADO DE OLIVEIRA' },
    { matricula: '2575', cpf: '87983273053', nome: 'BRUNO PEREIRA PORTELA' },
    { matricula: '2043', cpf: '49393491879', nome: 'CAIO OLIVEIRA TORRES' },
    { matricula: '2020', cpf: '03575484007', nome: 'CAION OLIVEIRA MUNARETO' },
    { matricula: '1828', cpf: '03724551070', nome: 'CARLA PATRICIA ZANDONA DINAT' },
    { matricula: '2582', cpf: '00134720032', nome: 'CARLOS ALBERTO MONTEIRO CORREA' },
    { matricula: '2468', cpf: '02797188020', nome: 'CARLOS ALEXANDRE DA CRUZ FIGUEIREDO' },
    { matricula: '75', cpf: '01415804028', nome: 'CARLOS ALEXANDRE PEREIRA DE SOUZA' },
    { matricula: '766', cpf: '00158427033', nome: 'CARLOS EDUARDO MACHADO DA ROSA' },
    { matricula: '1830', cpf: '21890842885', nome: 'CARLOS EDUARDO PEREIRA' },
    { matricula: '1795', cpf: '02331838763', nome: 'CARLOS GUALBERTO GOMES' },
    { matricula: '2557', cpf: '52264963034', nome: 'CARLOS ROBERTO DA SILVA WERNER' },
    { matricula: '598', cpf: '60080906087', nome: 'CARLOS SIDINEI AIRES DA SILVA' },
    { matricula: '419', cpf: '35244341855', nome: 'CASSIO RICARDO FERREIRA' },
    { matricula: '673', cpf: '02892333059', nome: 'CATIA ANDRESSA FORTES BUZANELLO' },
    { matricula: '812', cpf: '90955021120', nome: 'CELSO DA SILVA' },
    { matricula: '759', cpf: '80941095053', nome: 'CESAR AUGUSTO CORDEIRO KEITEL' },
    { matricula: '454', cpf: '02129577083', nome: 'CESAR IURI PRESTES MARQUES' },
    { matricula: '2391', cpf: '01533415056', nome: 'CHARLIE FRANCO SUDATTI' },
    { matricula: '455', cpf: '03258131082', nome: 'CHRISTIAN KLINGER' },
    { matricula: '2320', cpf: '09016372970', nome: 'CHRISTIAN SILVA VARELA' },
    { matricula: '787', cpf: '99005816953', nome: 'CIRO CESAR CORREA DE ARAUJO' },
    { matricula: '689', cpf: '76922375068', nome: 'CLAITON JUNIOR LA ROQUE DE LIMA' },
    { matricula: '312', cpf: '00774099062', nome: 'CLARISSA CATHERINE BRAUNER' },
    { matricula: '456', cpf: '64925056034', nome: 'CLAUDIMAR DA SILVA LORENSI' },
    { matricula: '1773', cpf: '04440124003', nome: 'CLAUDINEI BALEJOS GOMES' },
    { matricula: '174', cpf: '68363028053', nome: 'CLAUDIO CEZAR PRUNZEL' },
    { matricula: '1815', cpf: '00567439046', nome: 'CLAUDIO FAGUNDES' },
    { matricula: '52', cpf: '88935230006', nome: 'CLAUDIO ROGERIO PEREIRA' },
    { matricula: '379', cpf: '66667089034', nome: 'CLAUDIO SILVEIRA MENDONCA JUNIOR' },
    { matricula: '217', cpf: '92157211004', nome: 'CLAUDIONIR PEREIRA DOS SANTOS' },
    { matricula: '2459', cpf: '66945429091', nome: 'CLEBER SILVA DOS SANTOS' },
    { matricula: '2296', cpf: '02180559003', nome: 'CLEBIO RIOS DE OLIVEIRA' },
    { matricula: '130', cpf: '89256212072', nome: 'CLEIDIMAR FERNER TAVARES' },
    { matricula: '298', cpf: '19615574015', nome: 'CLETO DE SOUZA PORTELA' },
    { matricula: '811', cpf: '03293643086', nome: 'CLODOALDO DE OLIVEIRA' },
    { matricula: '2336', cpf: '02381756000', nome: 'CRISTIAN DE LIMA ALVES' },
    { matricula: '1860', cpf: '01679103024', nome: 'CRISTIAN EDUARDO DA ROSA ACHILLES' },
    { matricula: '648', cpf: '03715919027', nome: 'CRISTIAN LOPES DOS SANTOS' },
    { matricula: '790', cpf: '02220706095', nome: 'CRISTIAN MARCEL DA SILVA SELCH' },
    { matricula: '391', cpf: '03568485079', nome: 'CRISTIAN RENAN DUARTE' },
    { matricula: '375', cpf: '01816591076', nome: 'CRISTIANE ISABEL MORAIS BRUM' },
    { matricula: '2429', cpf: '00359402046', nome: 'CRISTIANO AIRES SILVA' },
    { matricula: '625', cpf: '02330284047', nome: 'CRISTIANO MACHADO FARIAS' },
    { matricula: '2427', cpf: '82339210097', nome: 'CRISTIANO RODRIGUES PETIM' },
    { matricula: '2503', cpf: '03427124037', nome: 'DANIEL CARVALHO CORREA' },
    { matricula: '2537', cpf: '39124730807', nome: 'DANIEL DOS SANTOS SOUZA' },
    { matricula: '358', cpf: '01525206001', nome: 'DANIEL LEMOS DA SILVA' },
    { matricula: '2567', cpf: '42923713850', nome: 'DANIELA RIBEIRO AUTORI' },
    { matricula: '2122', cpf: '96856238053', nome: 'DARCI OBALSKI' },
    { matricula: '2591', cpf: '02480208036', nome: 'DARLAN BOCACIO VIEIRA' },
    { matricula: '2502', cpf: '04045111042', nome: 'DARLAN DUBAL GAUDENCIO' },
    { matricula: '2406', cpf: '01052570011', nome: 'DARLAN MARQUES GARCIA' },
    { matricula: '2504', cpf: '00092714064', nome: 'DARWEY ODY DORNELLES' },
    { matricula: '421', cpf: '37091209850', nome: 'DAVID BARBOSA DIAS' },
    { matricula: '1969', cpf: '01870331028', nome: 'DAVID ROCHA PEREIRA' },
    { matricula: '2460', cpf: '04369290074', nome: 'DAVID STEFAN FERREIRA CANANEA' },
    { matricula: '1939', cpf: '02284921060', nome: 'DEIVISOM CARNEIRO TEIXEIRA' },
    { matricula: '2489', cpf: '01162998008', nome: 'DELMACI DUTRA FERREIRA' },
    { matricula: '2269', cpf: '36305279810', nome: 'DENNYS FELIPE SILVA DE OLIVEIRA' },
    { matricula: '2496', cpf: '01737962063', nome: 'DEOCLIDES SOARES MACHADO' },
    { matricula: '1888', cpf: '92388388015', nome: 'DERLI DE MELO DA SILVA' },
    { matricula: '382', cpf: '02887636022', nome: 'DIEGO ALEXANDRE DE MOURA DA ROSA' },
    { matricula: '2306', cpf: '01794139028', nome: 'DIEGO BISOGNIN FELIN' },
    { matricula: '339', cpf: '01474171044', nome: 'DIEGO FABIANO METZTORF MALLMANN' },
    { matricula: '2272', cpf: '02756140503', nome: 'DIEGO MARLEI JESUS DA CONCEICAO' },
    { matricula: '2312', cpf: '00464496047', nome: 'DIEGO MARTINS DA SILVA' },
    { matricula: '1932', cpf: '00896530094', nome: 'DIEGO MELLO MENDONCA' },
    { matricula: '1836', cpf: '02884743090', nome: 'DIEGO MENDES ACOSTA' },
    { matricula: '2261', cpf: '03085784063', nome: 'DIEGO OLIVEIRA SCHIMIT' },
    { matricula: '2237', cpf: '99277557087', nome: 'DIEGO RIGHI NICOLOSO' },
    { matricula: '107', cpf: '02174058006', nome: 'DIEGO RODRIGUES FONSECA' },
    { matricula: '582', cpf: '43673943871', nome: 'DIENY JANUARIA OLIVEIRA SANTOS' },
    { matricula: '2196', cpf: '86026810030', nome: 'DIESER FERNANDO PETTER JOHANNS' },
    { matricula: '1842', cpf: '61589780000', nome: 'DILSEU RODRIGUES DOS SANTOS' },
    { matricula: '2485', cpf: '01794660038', nome: 'DILVAN PIVETTA' },
    { matricula: '32', cpf: '61762431068', nome: 'DINIZ AGUIRRE ACOSTA' },
    { matricula: '2183', cpf: '03895689025', nome: 'DINIZ ROGERIO BAPTISTA SOBRINHO' },
    { matricula: '2546', cpf: '88530990030', nome: 'DIOGO GOULART PAIM' },
    { matricula: '374', cpf: '94356106091', nome: 'DIOGO WEINERT SCHROER' },
    { matricula: '2192', cpf: '00667531041', nome: 'DIONATA KOWALSKI DAVIES' },
    { matricula: '70', cpf: '02284897924', nome: 'DIONEI MACHADO' },
    { matricula: '2574', cpf: '01362176052', nome: 'DIORGES OSORIO PADILHA' },
    { matricula: '685', cpf: '02510885022', nome: 'DIOSE CORREA BORGES' },
    { matricula: '2527', cpf: '04001678080', nome: 'DIOVANA TIECKER DECKMANN' },
    { matricula: '2251', cpf: '02057331075', nome: 'DIOZE CARDOSO AGUIAR' },
    { matricula: '20', cpf: '97179582034', nome: 'DJONATAN ROGERIO SIPERT' },
    { matricula: '2345', cpf: '07276233979', nome: 'DOUGLAS NIJO DA SILVA' },
    { matricula: '61', cpf: '01643246070', nome: 'DOUGLAS PILAR COELHO' },
    { matricula: '1788', cpf: '00098424033', nome: 'EDER DOS SANTOS DA COSTA' },
    { matricula: '2424', cpf: '02965144030', nome: 'EDER MACHADO' },
    { matricula: '2484', cpf: '95726578015', nome: 'EDER OLIVEIRA DE VARGAS' },
    { matricula: '2074', cpf: '04075198073', nome: 'EDER PAZINI LUNARDI' },
    { matricula: '2095', cpf: '01261182057', nome: 'EDEVALDO FERREIRA DA SILVA' },
    { matricula: '1975', cpf: '88301109068', nome: 'EDILON BRENO BECKER DE FREITAS' },
    { matricula: '214', cpf: '81926537068', nome: 'EDILSON LUIS GALVAM' },
    { matricula: '2146', cpf: '95692592053', nome: 'EDILSON MESSA DE SOUZA' },
    { matricula: '2606', cpf: '08599347993', nome: 'EDINEI CLEITON PARASTCHUK' },
    { matricula: '2482', cpf: '03560625033', nome: 'EDISON JORGE NUNES GUILET JUNIOR' },
    { matricula: '2470', cpf: '70044805004', nome: 'EDIVALDO CECCIN DA SILVA' },
    { matricula: '513', cpf: '89352750004', nome: 'EDMILSON DA SILVA SERPA' },
    { matricula: '2061', cpf: '02228379000', nome: 'EDSON BREITENBACH' },
    { matricula: '305', cpf: '00976749076', nome: 'EDSON MOREIRA GARCIA' },
    { matricula: '637', cpf: '97110531015', nome: 'EDSON ORTIZ' },
    { matricula: '270', cpf: '00680047026', nome: 'EDSON RAFAEL DOS SANTOS' },
    { matricula: '184', cpf: '00144254085', nome: 'EDSON ROQUE ADAMS' },
    { matricula: '2264', cpf: '04142706063', nome: 'EDUARDA BUENO DE MORAES' },
    { matricula: '2420', cpf: '04162797080', nome: 'EDUARDO ARRIERA RIBEIRO' },
    { matricula: '2528', cpf: '03679389019', nome: 'EDUARDO DA SILVA GUIMARAES' },
    { matricula: '267', cpf: '17243485820', nome: 'EDUARDO HENRIQUE LENTI' },
    { matricula: '120', cpf: '03101538084', nome: 'EDUARDO MIGUEL VAZ DOS SANTOS' },
    { matricula: '1948', cpf: '03969970075', nome: 'ELBER DIONIS GARCIA MANZINE' },
    { matricula: '2102', cpf: '03559702011', nome: 'ELIABE VALDEIR DA SILVA' },
    { matricula: '2604', cpf: '10367173808', nome: 'ELIANA APARECIDA MENDONCA' },
    { matricula: '1774', cpf: '04912693073', nome: 'ELIANDRO SILVA DA SILVA' },
    { matricula: '2330', cpf: '86600800097', nome: 'ELIEZER ISRAEL SILVA DA SILVA' },
    { matricula: '362', cpf: '00099941066', nome: 'ELINEIO BRIZOLLA DE VARGAS' },
    { matricula: '357', cpf: '02062185081', nome: 'ELINILTON BOLZAN CEOLIN' },
    { matricula: '2505', cpf: '04543729064', nome: 'ELISANGELA LIRA' },
    { matricula: '2611', cpf: '03098786086', nome: 'ELIVELTON DOS SANTOS ENGROFF' },
    { matricula: '6', cpf: '78241596049', nome: 'ELTON JORGE SANTOS TRINDADE' },
    { matricula: '2445', cpf: '01872428010', nome: 'ELTON PALHANO ZIMMER' },
    { matricula: '429', cpf: '40766264866', nome: 'ERISSON ERMERSON ALVES DA SILVA' },
    { matricula: '2488', cpf: '00734308035', nome: 'ESTEVAO FERNANDES DA ROCHA' },
    { matricula: '10', cpf: '89697502072', nome: 'EVANDRO DOS REIS ROSA' },
    { matricula: '381', cpf: '83334645053', nome: 'EVANDRO ORTIZ COLATTO' },
    { matricula: '261', cpf: '66955041053', nome: 'EVANDRO VASCONCELOS' },
    { matricula: '2241', cpf: '59292180053', nome: 'EVANILDO AMARAL DOS SANTOS' },
    { matricula: '2315', cpf: '00746562012', nome: 'EVERSON EDU COSTA' },
    { matricula: '1954', cpf: '02870671067', nome: 'EVERSON JANDT BARWALDT' },
    { matricula: '2476', cpf: '04785722045', nome: 'EVERTON AGERTTE DE MOURA' },
    { matricula: '2291', cpf: '03164085060', nome: 'EVERTON DE MOURA CAMARGO' },
    { matricula: '2155', cpf: '01765155002', nome: 'EZIQUIEL PINHEIRO DA SILVA' },
    { matricula: '436', cpf: '22286646856', nome: 'FABIANA RIBEIRO DE ALMEIDA' },
    { matricula: '2314', cpf: '02441574051', nome: 'FABIANO CONTREIRA ROCHA' },
    { matricula: '2284', cpf: '03205190076', nome: 'FABIANO RICARDO MENDONCA' },
    { matricula: '2440', cpf: '08961676741', nome: 'FABIANO SOUZA DA SILVA' },
    { matricula: '2058', cpf: '03419030940', nome: 'FABIO ADRIANO BANAVITZ CALDAS' },
    { matricula: '1945', cpf: '04557515959', nome: 'FABIO ALVES DA SILVA' },
    { matricula: '2022', cpf: '01993297022', nome: 'FABIO DA ROSA ARRUDA' },
    { matricula: '128', cpf: '70920982034', nome: 'FABIO HOFFMEISTER' },
    { matricula: '2522', cpf: '00836482085', nome: 'FABIO JANUARIO DE OLIVEIRA REI' },
    { matricula: '1991', cpf: '00111445051', nome: 'FABIO NASCIMENTO DE OLIVEIRA' },
    { matricula: '377', cpf: '95578676049', nome: 'FABIO ROGERIO SCHALKO' },
    { matricula: '1961', cpf: '01577917006', nome: 'FABRICIO MACHADO' },
    { matricula: '2602', cpf: '00430577052', nome: 'FAGNER DUTRA DE VARGAS' },
    { matricula: '1952', cpf: '02580417060', nome: 'FAGNER FRA FERNANDES' },
    { matricula: '2000', cpf: '02983829030', nome: 'FELIPE ANANIAS CAMILLO FAGUNDES' },
    { matricula: '2451', cpf: '02781275093', nome: 'FELIPE CARDOSO CORREA' },
    { matricula: '344', cpf: '04342605090', nome: 'FELIPE FUCILINI RUVER' },
    { matricula: '1995', cpf: '01518721044', nome: 'FELIPE VASEM' },
    { matricula: '2223', cpf: '95276220000', nome: 'FERNANDO BARRILARI LISBOA' },
    { matricula: '2197', cpf: '84485230082', nome: 'FERNANDO SANTOS DA SILVA' },
    { matricula: '244', cpf: '91035449072', nome: 'FERNANDO SIDINEI DA SILVA FAGUNDES' },
    { matricula: '427', cpf: '05171604550', nome: 'FLAVIANE RODRIGUES OLIVEIRA' },
    { matricula: '2289', cpf: '82611475091', nome: 'FLAVIO DIONISIO PETTENON' },
    { matricula: '2363', cpf: '03242049063', nome: 'FLAVIO FERNANDES MARIANO' },
    { matricula: '2387', cpf: '95393749015', nome: 'FLAVIO LEMES DE LIMA' },
    { matricula: '166', cpf: '92428444034', nome: 'FLAVIO PEREIRA ALVES' },
    { matricula: '2395', cpf: '02589683030', nome: 'FLAVIO PORTE GOMES' },
    { matricula: '2490', cpf: '04292240019', nome: 'FLAVIO RUARO' },
    { matricula: '2371', cpf: '03227527014', nome: 'FRANCINE AYAME OCAMPOS KETZER' },
    { matricula: '2351', cpf: '01325491071', nome: 'FRANCIS ROBERTO KUMMER MACHT' },
    { matricula: '2553', cpf: '30312891881', nome: 'FRANCISCO CARLOS MAZZONI' },
    { matricula: '2595', cpf: '03515021060', nome: 'FRANCISCO MOURA DE SOUZA' },
    { matricula: '260', cpf: '91688884068', nome: 'FRANK CHAMORRO DOS SANTOS' },
    { matricula: '2545', cpf: '02372104012', nome: 'GABRIEL LUBAS FERREIRA' },
    { matricula: '2540', cpf: '02563365040', nome: 'GABRIEL MOROZ DE LIMA PACHECO' },
    { matricula: '518', cpf: '02222031028', nome: 'GABRIEL PAIXAO HOISLER' },
    { matricula: '365', cpf: '03662279045', nome: 'GABRIEL RIBAS TAMIOZZO' },
    { matricula: '2267', cpf: '03683251050', nome: 'GABRIELLI DA ROSA COSTA' },
    { matricula: '351', cpf: '01011149079', nome: 'GABRYEL ESPINDOLA E SILVA' },
    { matricula: '2556', cpf: '98766198020', nome: 'GASPAR DORNELES DA CUNHA' },
    { matricula: '819', cpf: '03842133081', nome: 'GEAN CLEBER PINHEIRO DA SILVA' },
    { matricula: '356', cpf: '01935381059', nome: 'GEAN MICHEL GEHM' },
    { matricula: '771', cpf: '03255402093', nome: 'GEAN PEREIRA DE OLIVEIRA' },
    { matricula: '155', cpf: '03488378012', nome: 'GEDER ROQUE SOARES BASSO' },
    { matricula: '194', cpf: '67931880030', nome: 'GEOVANI ROSSATO RIGO' },
    { matricula: '325', cpf: '03954499010', nome: 'GESSICA MEDEIROS AGUIAR' },
    { matricula: '2422', cpf: '02440410080', nome: 'GIANO GOMES PEREIRA' },
    { matricula: '2026', cpf: '01752575075', nome: 'GIDIONE MIZAEL NUNES BITTENCOURT' },
    { matricula: '332', cpf: '01127303023', nome: 'GIL FELIPE STOLZ' },
    { matricula: '15', cpf: '16992555049', nome: 'GIL FRANCISCO DE OLIVEIRA PORTES' },
    { matricula: '112', cpf: '79147992034', nome: 'GILBERTO APARICIO BARBOSA OLIVEIRA' },
    { matricula: '1992', cpf: '03066590003', nome: 'GILBERTO ROBALLO ESPINDOLA' },
    { matricula: '529', cpf: '36521558072', nome: 'GILMAR ALDINO COPPETTI' },
    { matricula: '1782', cpf: '01925641023', nome: 'GILMAR RIBEIRO CARVALHO' },
    { matricula: '410', cpf: '00614547024', nome: 'GILMAR SOARES FERNANDES' },
    { matricula: '656', cpf: '00040316017', nome: 'GILSON BARBOSA DOS SANTOS' },
    { matricula: '1', cpf: '59552352053', nome: 'GILVAN JOHANSON SILVEIRA' },
    { matricula: '528', cpf: '89183959068', nome: 'GLAUCO WARPECHOWSKI' },
    { matricula: '2271', cpf: '03017975010', nome: 'GREICE KELLI HAAG RIBEIRO' },
    { matricula: '2017', cpf: '01959954067', nome: 'GUILHERME CUSTODIO CARVALHO' },
    { matricula: '1967', cpf: '03848533073', nome: 'GUILHERME DE JESUS LIMA DA COSTA' },
    { matricula: '2308', cpf: '06728166955', nome: 'GUILHERME DOZOREC DE SOUZA' },
    { matricula: '1998', cpf: '01528887050', nome: 'GUILHERME MACEDO PEREIRA VIEIRA' },
    { matricula: '341', cpf: '02972716019', nome: 'GUILHERME SOST HENNRICHS' },
    { matricula: '2614', cpf: '03147966062', nome: 'GUSTAVO DE LIMA' },
    { matricula: '2439', cpf: '02403751065', nome: 'GUSTAVO VARGAS PRESTES' },
    { matricula: '1926', cpf: '00700758674', nome: 'HELBERT COSTA LEONCIO' },
    { matricula: '472', cpf: '03815703042', nome: 'HELENA BEATRIZ FISCHER' },
    { matricula: '17', cpf: '90284267015', nome: 'HELIO LUCIANO HUBNER' },
    { matricula: '823', cpf: '92901280030', nome: 'HERIBERTO VALDEZ PONTEL' },
    { matricula: '2150', cpf: '04099420060', nome: 'IAGO AMARAL DE MOURA' },
    { matricula: '424', cpf: '48656341855', nome: 'IGOR ALVES DE OLIVEIRA' },
    { matricula: '69', cpf: '48186449000', nome: 'ILARIO GOTTFRIED PANIZ' },
    { matricula: '2609', cpf: '00701723076', nome: 'ILSON MINHOS MELO' },
    { matricula: '2079', cpf: '73081272068', nome: 'IRIO RIBAS GOMES' },
    { matricula: '2469', cpf: '00923245090', nome: 'ISAAC CHARLES DIAS DA SILVA' },
    { matricula: '473', cpf: '03189504059', nome: 'ISADORA KONARZEWSKY PATZER' },
    { matricula: '404', cpf: '02389375006', nome: 'ISAQUE CESAR DA SILVA PORTELA' },
    { matricula: '639', cpf: '58503021072', nome: 'ISRAEL FERREIRA' },
    { matricula: '2154', cpf: '70085986020', nome: 'ITAMAR LUIS PEREIRA' },
    { matricula: '723', cpf: '03069500900', nome: 'ITOR BORGES DE LIMA FILHO' },
    { matricula: '47', cpf: '25915673015', nome: 'IVAN GODOY ARCE' },
    { matricula: '2362', cpf: '00107799065', nome: 'IVAN RODRIGUES DA SILVA' },
    { matricula: '2265', cpf: '71513119150', nome: 'IVAN TOMAS MOLINA' },
    { matricula: '12', cpf: '95144889034', nome: 'IVONIR ADAMSKI SASSI' },
    { matricula: '653', cpf: '00059520000', nome: 'IZAIAS PAZ' },
    { matricula: '31', cpf: '44340745049', nome: 'JACINTO NERI MARSARO' },
    { matricula: '628', cpf: '01952125090', nome: 'JACKSON CARLOS TOEBE' },
    { matricula: '2167', cpf: '00734052057', nome: 'JACQUES DE ALMEIDA' },
    { matricula: '224', cpf: '01849400016', nome: 'JACSON FRANCISCO MORAES PRESTES' },
    { matricula: '2605', cpf: '05116221989', nome: 'JADER CRISTIANO FERREIRA' },
    { matricula: '2182', cpf: '98528785068', nome: 'JADERSON RODINEI BACELAR RODRIGUES' },
    { matricula: '2283', cpf: '00103416099', nome: 'JAIME DE OLIVEIRA GARCIA' },
    { matricula: '192', cpf: '61753033004', nome: 'JAIME RAMOS FERNANDES' },
    { matricula: '635', cpf: '59637242015', nome: 'JAIR MUMBACH' },
    { matricula: '119', cpf: '43249680044', nome: 'JAIR NOSTER' },
    { matricula: '5', cpf: '43136397053', nome: 'JAIRO NOSTER' },
    { matricula: '2592', cpf: '01107188016', nome: 'JAISON MADRIEL MACEDO' },
    { matricula: '1776', cpf: '02019238071', nome: 'JANIO BUTZGE DA SILVA' },
    { matricula: '2175', cpf: '03261434082', nome: 'JARDEL FRANCA HUDSON' },
    { matricula: '2128', cpf: '02844510027', nome: 'JEAN CARLOS KUMMER LANZ' },
    { matricula: '2551', cpf: '03740352027', nome: 'JEAN CARLOS OLIVEIRA CASTRO' },
    { matricula: '392', cpf: '03424700002', nome: 'JEAN PEDRO DA SILVA' },
    { matricula: '2242', cpf: '01754606040', nome: 'JEFERSON CARVALHO' },
    { matricula: '2506', cpf: '03170091093', nome: 'JEFERSON DA SILVA DA ROSA' },
    { matricula: '2588', cpf: '76209709087', nome: 'JEFERSON MARTINS PORTELA' },
    { matricula: '53', cpf: '97602256034', nome: 'JEFERSON PEREIRA DOS SANTOS' },
    { matricula: '417', cpf: '37933717861', nome: 'JEFFERSON DA CRUZ CARDOSO' },
    { matricula: '2401', cpf: '03310583082', nome: 'JEFFERSON LUIDGI RIBEIRO DA SILVA' },
    { matricula: '2339', cpf: '01387306014', nome: 'JEFFERSON LUIS PERES DA SILVA' },
    { matricula: '193', cpf: '01694453057', nome: 'JEFFERSON PERES DUMERQUI' },
    { matricula: '106', cpf: '00071551077', nome: 'JEMERSON WACHHOLZ DOS SANTOS' },
    { matricula: '750', cpf: '04289132022', nome: 'JENIFER PAOLA WACHTER' },
    { matricula: '478', cpf: '73083399049', nome: 'JOAO ALBERTO MACHADO DA COSTA' },
    { matricula: '40', cpf: '41434811034', nome: 'JOAO ALFREDO MACHADO TEIXEIRA' },
    { matricula: '181', cpf: '30444918000', nome: 'JOAO ANTONIO FERREIRA PITAMEA' },
    { matricula: '42', cpf: '64379434087', nome: 'JOAO BATISTA FREITAS RODRIGUES' },
    { matricula: '263', cpf: '92650074000', nome: 'JOAO CARLOS ANTUNES MELO' },
    { matricula: '176', cpf: '41101677015', nome: 'JOAO CARLOS ELY' },
    { matricula: '2169', cpf: '61895830087', nome: 'JOAO CARLOS FERREIRA MACHADO' },
    { matricula: '44', cpf: '94912815004', nome: 'JOAO DANIEL NUNES BRUM' },
    { matricula: '199', cpf: '00232721041', nome: 'JOAO EZEQUIEL VIANA LIMA' },
    { matricula: '768', cpf: '99410010063', nome: 'JOAO GABRIEL GUARESI' },
    { matricula: '2385', cpf: '08902728919', nome: 'JOAO HENRIQUE MARCON' },
    { matricula: '694', cpf: '00452090067', nome: 'JOAO LUIZ DA COSTA NASCIMENTO' },
    { matricula: '1891', cpf: '01135441030', nome: 'JOAO LUIZ DOS SANTOS MARTINS' },
    { matricula: '479', cpf: '66585929004', nome: 'JOAO PEDRO NUNES DA SILVA' },
    { matricula: '2405', cpf: '02930885009', nome: 'JOAO RICARDO DE MORAIS HICKEMBICK' },
    { matricula: '2282', cpf: '04625004039', nome: 'JOAO VITOR CASTRO DO ROSARIO' },
    { matricula: '86', cpf: '60374365091', nome: 'JOAQUIM DEVANIR NASCIMENTO RODRIGUES' },
    { matricula: '56', cpf: '01824719000', nome: 'JOAQUIM ROCHA RIBEIRO' },
    { matricula: '189', cpf: '00997398019', nome: 'JOARES SILVA DE MATOS' },
    { matricula: '1813', cpf: '02550404076', nome: 'JOBSON MOYANO MACHADO' },
    { matricula: '1882', cpf: '03153202060', nome: 'JOCEMIR SILVA SANTOS' },
    { matricula: '568', cpf: '02997095077', nome: 'JOEL DA SILVA GONZAGA' },
    { matricula: '662', cpf: '02107886075', nome: 'JOHN ZOEI DA SILVEIRA VIEIRA' },
    { matricula: '2285', cpf: '01649622040', nome: 'JOHNATAN FIESS MACHADO' },
    { matricula: '2060', cpf: '02911262000', nome: 'JONAS FARIAS GARCIA' },
    { matricula: '2203', cpf: '03361673003', nome: 'JONAS RODRIGUES DE ALMEIDA' },
    { matricula: '2473', cpf: '87396637015', nome: 'JONAS RODRIGUES DOS SANTOS' },
    { matricula: '810', cpf: '01627433007', nome: 'JONAS TADEU DA LUZ KAMINSKI' },
    { matricula: '2564', cpf: '01967623082', nome: 'JONATAN RENAN ALVES' },
    { matricula: '46', cpf: '07843118977', nome: 'JONATHAN CENTOFANTE' },
    { matricula: '2034', cpf: '02578906025', nome: 'JONATHAN PERES RODRIGUES' },
    { matricula: '2179', cpf: '04065019044', nome: 'JONATHAS FUZIGER REIMANN' },
    { matricula: '145', cpf: '66956471000', nome: 'JORGE ALBERTO MESSA' },
    { matricula: '3', cpf: '47468491020', nome: 'JORGE FIDELIS SOARES' },
    { matricula: '522', cpf: '54106818000', nome: 'JORGE ROGERIO KAMPHORST PINHEIRO' },
    { matricula: '681', cpf: '41106040015', nome: 'JOSE ADERLI BILHALVA DA SILVA' },
    { matricula: '2450', cpf: '03887798023', nome: 'JOSE ANATALICIO MOTTA RAMOS' },
    { matricula: '2441', cpf: '01702167054', nome: 'JOSE ANSELMO DE SOUZA JUNIOR' },
    { matricula: '2430', cpf: '93639295072', nome: 'JOSE AUGUSTO DE DEUS ACOSTA' },
    { matricula: '9', cpf: '41603923004', nome: 'JOSE DE JESUS FERREIRA NASCIMENTO' },
    { matricula: '2332', cpf: '62175505049', nome: 'JOSE EMIR MENEZES DE MORAIS' },
    { matricula: '585', cpf: '00317382063', nome: 'JOSE FAGUNDES NETO' },
    { matricula: '2491', cpf: '01613868065', nome: 'JOSE FERNANDO DOS SANTOS KISSEL' },
    { matricula: '740', cpf: '02087554030', nome: 'JOSE FERNANDO PEREIRA' },
    { matricula: '620', cpf: '02087548064', nome: 'JOSE FRANCISCO PEREIRA MACHADO' },
    { matricula: '287', cpf: '03806896003', nome: 'JOSE LUCAS DA SILVA' },
    { matricula: '2263', cpf: '00089228022', nome: 'JOSE LUIS GOMES DA COSTA' },
    { matricula: '791', cpf: '58511636072', nome: 'JOSE NENE DA CRUZ' },
    { matricula: '338', cpf: '10590200097', nome: 'JOSE PAULO DE OLIVEIRA' },
    { matricula: '57', cpf: '18820808072', nome: 'JOSE VICENTE ALMERON' },
    { matricula: '1883', cpf: '00432706119', nome: 'JOSE VIEIRA MUNIZ' },
    { matricula: '1929', cpf: '06355336931', nome: 'JOSEMAR MOISSA' },
    { matricula: '730', cpf: '91172438072', nome: 'JOTA SILVIO FONSECA' },
    { matricula: '2453', cpf: '01830362046', nome: 'JUARES PALHARINO JUNIOR' },
    { matricula: '805', cpf: '03185132009', nome: 'JULIANO ESTIVALET MAYER' },
    { matricula: '1877', cpf: '03080469003', nome: 'JULIANO FALCAO DE BASTOS' },
    { matricula: '1904', cpf: '01534037055', nome: 'JULIANO MACHADO DOS SANTOS' },
    { matricula: '2538', cpf: '02592717056', nome: 'JULIE RAFAELI ZWICK JUNG' },
    { matricula: '2525', cpf: '03490952057', nome: 'JULIO ALVES BANDEIRA NETO' },
    { matricula: '619', cpf: '99635356072', nome: 'JULIO CAVALHEIRO' },
    { matricula: '71', cpf: '97329452068', nome: 'JULIO ISIQUIEL' },
    { matricula: '2562', cpf: '80566316072', nome: 'JULIZE ANTUNES PORTO' },
    { matricula: '2348', cpf: '01010714937', nome: 'JUNIOR LOURENCO' },
    { matricula: '2554', cpf: '01694055086', nome: 'KAILANY PEREIRA MARTINS ZALUSKI' },
    { matricula: '1960', cpf: '03242928059', nome: 'KELVIN HELIAS SCHINDLER SAVARIZ' },
    { matricula: '355', cpf: '02561002098', nome: 'KELVIN LIMA DE LEMOS' },
    { matricula: '1935', cpf: '04383921086', nome: 'KELVIN SCHMALZ DE OLIVEIRA' },
    { matricula: '426', cpf: '44034371846', nome: 'KENETHY MAXWEL DE LIMA COELHO' },
    { matricula: '1868', cpf: '01821411013', nome: 'KETLEN SILVA DA SILVA' },
    { matricula: '2552', cpf: '04402826080', nome: 'KETLIN MORAES HEIN' },
    { matricula: '2507', cpf: '02795003007', nome: 'KLEITON PRESTES AMARAL' },
    { matricula: '712', cpf: '00759560048', nome: 'LADIMIR MOISES MACHADO DO NASCIMENTO' },
    { matricula: '1931', cpf: '03098357045', nome: 'LAONE BASSO JUNIOR' },
    { matricula: '2352', cpf: '01832529932', nome: 'LARI VALMOR BOTTCHER' },
    { matricula: '126', cpf: '99975122000', nome: 'LAURI DOS SANTOS MACHADO' },
    { matricula: '113', cpf: '01999191005', nome: 'LAURI SCHNEIDER PADILHA' },
    { matricula: '1821', cpf: '00234894059', nome: 'LEANDRO AMARAL DE PAULA' },
    { matricula: '2356', cpf: '08357114989', nome: 'LEANDRO CORDEIRO DOS SANTOS' },
    { matricula: '2144', cpf: '97080918049', nome: 'LEANDRO DA SILVA' },
    { matricula: '213', cpf: '96457104053', nome: 'LEANDRO DOS SANTOS BRUM' },
    { matricula: '2534', cpf: '09874814799', nome: 'LEANDRO DOS SANTOS DE OLIVEIRA' },
    { matricula: '2098', cpf: '00826636020', nome: 'LEANDRO LAXES PINTO' },
    { matricula: '135', cpf: '65046242087', nome: 'LEANDRO MARCELO GRAEF' },
    { matricula: '2109', cpf: '75835827091', nome: 'LEANDRO MARTINS' },
    { matricula: '2408', cpf: '10584265913', nome: 'LEANDRO VALDEMIRO LUIZ' },
    { matricula: '2418', cpf: '02855958067', nome: 'LEONARDO BREUNIG BRANDENBURG' },
    { matricula: '2529', cpf: '04273429020', nome: 'LEONARDO DE OLIVEIRA RODRIGUES' },
    { matricula: '1850', cpf: '04449482018', nome: 'LEONARDO GARCIA DE JESUS' },
    { matricula: '2397', cpf: '02072962005', nome: 'LEONARDO GONCALVES SCHMIDT' },
    { matricula: '1852', cpf: '04860363086', nome: 'LEONARDO MULLER MAASS' },
    { matricula: '2396', cpf: '86314661072', nome: 'LEONARDO OMAR DOS SANTOS' },
    { matricula: '2215', cpf: '03799237003', nome: 'LEONARDO PIEGAS RAMOS' },
    { matricula: '2436', cpf: '04226183061', nome: 'LEONARDO RODRIGUES VEIGA' },
    { matricula: '2531', cpf: '99929236015', nome: 'LINOMAR BANDEIRA TELLES' },
    { matricula: '2338', cpf: '00745465030', nome: 'LOURIVAL DOS SANTOS GONCALVES' },
    { matricula: '2539', cpf: '01583274030', nome: 'LUANA NICOLETTI DE OLIVEIRA' },
    { matricula: '2114', cpf: '03773428073', nome: 'LUCAS CASSIANO ZOCHE' },
    { matricula: '519', cpf: '02687789062', nome: 'LUCAS DOS SANTOS SERPA' },
    { matricula: '2563', cpf: '03442441056', nome: 'LUCAS EDUARDO GHISLENI' },
    { matricula: '2327', cpf: '03544891000', nome: 'LUCAS FERNANDO ARAUJO RUTSATZ' },
    { matricula: '2376', cpf: '00202859061', nome: 'LUCAS FLORES' },
    { matricula: '2202', cpf: '01596636017', nome: 'LUCAS MELO DE MATOS' },
    { matricula: '243', cpf: '01752131002', nome: 'LUCAS PEDROSO DA FONTOURA' },
    { matricula: '380', cpf: '02713184061', nome: 'LUCAS UILIAM FRANCO FREITAS' },
    { matricula: '1955', cpf: '02702278060', nome: 'LUCAS UMPIERRE CARDOSO' },
    { matricula: '2236', cpf: '03459954060', nome: 'LUCAS VELASQUE' },
    { matricula: '309', cpf: '00298623021', nome: 'LUCIANA CARVALHO BUSSMANN SCHIRMANN' },
    { matricula: '2444', cpf: '02592350080', nome: 'LUCIANO ADI BARTSCH' },
    { matricula: '28', cpf: '00843349085', nome: 'LUCIANO BRIZOLLA DE VARGAS' },
    { matricula: '563', cpf: '00178690058', nome: 'LUCIANO PAIVA CARLOSSO' },
    { matricula: '2585', cpf: '03425504037', nome: 'LUCIANO SCHETTERT FERNANDEZ' },
    { matricula: '2437', cpf: '77594452020', nome: 'LUCIO SERGIO NUNES DE LIMA' },
    { matricula: '72', cpf: '02877305902', nome: 'LUIS ANTES PETTER' },
    { matricula: '207', cpf: '47375965072', nome: 'LUIS ANTONIO MILDNER' },
    { matricula: '67', cpf: '00424278006', nome: 'LUIS ANTONIO OLIVEIRA DE SOUZA' },
    { matricula: '2462', cpf: '10342151916', nome: 'LUIS CARLOS BENEDITO ALVES' },
    { matricula: '1808', cpf: '90295765020', nome: 'LUIS CARLOS VAZ GOMES' },
    { matricula: '178', cpf: '46025901015', nome: 'LUIS CLAUDIO FREITAS TABORDA' },
    { matricula: '2596', cpf: '01867191083', nome: 'LUIS FERNANDO COSTA BATISTA' },
    { matricula: '2300', cpf: '01414743009', nome: 'LUIS FERNANDO WACHTER MARKOSKI' },
    { matricula: '657', cpf: '03938171057', nome: 'LUIS HENRIQUE SILVA RODRIGUES' },
    { matricula: '489', cpf: '03141915008', nome: 'LUIS OSORIO CARPES FRANQUE' },
    { matricula: '4', cpf: '40866009000', nome: 'LUIZ ANTONIO HECK' },
    { matricula: '571', cpf: '01799128016', nome: 'LUIZ CRISTIANO MENDONCA DA SILVA' },
    { matricula: '512', cpf: '25017279830', nome: 'LUIZ DE BARROS CAMPELO JUNIOR' },
    { matricula: '2335', cpf: '01347800000', nome: 'LUIZ EVANDRO CORREA ANTUNES' },
    { matricula: '491', cpf: '50129916803', nome: 'LUIZ FERNANDO DA SILVA' },
    { matricula: '2201', cpf: '98788299015', nome: 'LUIZ FERNANDO DOS SANTOS' },
    { matricula: '222', cpf: '70990441091', nome: 'LUIZ FERNANDO PEREIRA DA SILVA' },
    { matricula: '2018', cpf: '02715254016', nome: 'LUIZ FERNANDO SANTOS DOS SANTOS' },
    { matricula: '2216', cpf: '63967901068', nome: 'LUIZ FLAVIO DALMOLIN MELLO' },
    { matricula: '607', cpf: '02688189085', nome: 'MAICO DE QUADROS RODRIGUES' },
    { matricula: '2508', cpf: '04194804040', nome: 'MAICON DA ROSA BARRETO' },
    { matricula: '2423', cpf: '04271302040', nome: 'MAICON LEONARDO DO CANTO BRITES' },
    { matricula: '2452', cpf: '04166565010', nome: 'MAICON MACHADO ORTIS' },
    { matricula: '115', cpf: '00850167051', nome: 'MAICON MARTINS BAMBIL' },
    { matricula: '1829', cpf: '09179166970', nome: 'MAICON RICARDO SMANITTO' },
    { matricula: '765', cpf: '03176626002', nome: 'MAICON VINICIUS DOS SANTOS PINTO' },
    { matricula: '2479', cpf: '03894200006', nome: 'MAIKE SILVA DE OLIVEIRA' },
    { matricula: '2532', cpf: '03316926003', nome: 'MAILSON ALMEIDA DA SILVA' },
    { matricula: '2030', cpf: '00532475070', nome: 'MAIQUEL EILERT' },
    { matricula: '1887', cpf: '55200567068', nome: 'MANFREDO PRANTE' },
    { matricula: '2620', cpf: '03666507000', nome: 'MARCELO ARILSSON AIRES RODRIGUES' },
    { matricula: '2256', cpf: '01461173019', nome: 'MARCELO DA SILVA HIRT' },
    { matricula: '124', cpf: '57437793091', nome: 'MARCELO DA SILVEIRA' },
    { matricula: '831', cpf: '03089652056', nome: 'MARCELO DOS SANTOS FERNANDES' },
    { matricula: '1802', cpf: '80137113072', nome: 'MARCELO FERRO DE MEDEIROS' },
    { matricula: '2097', cpf: '29746378848', nome: 'MARCELO MARTINS FARIAS' },
    { matricula: '1974', cpf: '02196369071', nome: 'MARCELO SILVA GARCIA' },
    { matricula: '2222', cpf: '92378951000', nome: 'MARCELO SOARES REBELO' },
    { matricula: '412', cpf: '11502050838', nome: 'MARCIA APARECIDA DIAS CORREA' },
    { matricula: '2240', cpf: '90297571087', nome: 'MARCIO ANDRE VIDAL' },
    { matricula: '1902', cpf: '00039924033', nome: 'MARCIO CALISTO NOTARGIACOMO' },
    { matricula: '1914', cpf: '90205391087', nome: 'MARCIO DA SILVA ASSUNÇAO' },
    { matricula: '2297', cpf: '96429577015', nome: 'MARCIO LOPES' },
    { matricula: '2465', cpf: '28066304870', nome: 'MARCIO ROGERIO SAMIR SANTANA' },
    { matricula: '764', cpf: '30361320078', nome: 'MARCO ANTONIO ALVES GOMES' },
    { matricula: '13', cpf: '51815150963', nome: 'MARCO AURELIO RODRIGUES GARCIA' },
    { matricula: '43', cpf: '00555089010', nome: 'MARCO EZEQUIEL NUNES BRUM' },
    { matricula: '133', cpf: '02227087005', nome: 'MARCOS AMARILHO PACHECO' },
    { matricula: '1861', cpf: '03531977938', nome: 'MARCOS ANDRE MARTINS' },
    { matricula: '2007', cpf: '09453481939', nome: 'MARCOS DANIEL ROCHA TERENCIO' },
    { matricula: '2447', cpf: '81125585072', nome: 'MARCOS DOS SANTOS FERREIRA' },
    { matricula: '1803', cpf: '00699939046', nome: 'MARCOS LEANDRO GARCIA VALLES' },
    { matricula: '2024', cpf: '00173750028', nome: 'MARCOS LUCIANO SCHEUER' },
    { matricula: '1857', cpf: '07903033964', nome: 'MARCOS LUIZ MENDES' },
    { matricula: '291', cpf: '73019011000', nome: 'MARCOS NOE MUNAWEK' },
    { matricula: '803', cpf: '98433547020', nome: 'MARCOS ULISSES PEIXOTO' },
    { matricula: '2101', cpf: '36613523852', nome: 'MARCOS VINICIUS DE SOUZA VIEIRA' },
    { matricula: '2372', cpf: '04615276001', nome: 'MARIA EDUARDA DOS SANTOS ANTUNES' },
    { matricula: '2373', cpf: '02967644005', nome: 'MARIA EDUARDA HOFF' },
    { matricula: '520', cpf: '02389722083', nome: 'MARIANI GONCALVES RODRIGUES' },
    { matricula: '146', cpf: '99555280010', nome: 'MARIO BARBOZA' },
    { matricula: '2229', cpf: '00050557092', nome: 'MARISTELA SOARES BECK' },
    { matricula: '714', cpf: '03460221003', nome: 'MARLON MACHADO SARMENTO JUNIOR' },
    { matricula: '2610', cpf: '01750215039', nome: 'MARTA DENILCE DA SILVA BRAZ' },
    { matricula: '515', cpf: '29242495204', nome: 'MASARU AOKI' },
    { matricula: '2514', cpf: '11815522909', nome: 'MATEUS ALBINO BARBOZA' },
    { matricula: '384', cpf: '03421609071', nome: 'MATEUS JACQUES COSTA' },
    { matricula: '368', cpf: '03252595001', nome: 'MATEUS SANTOS DA CRUZ' },
    { matricula: '2393', cpf: '04604241066', nome: 'MATHEUS DA SILVA FIGUERA' },
    { matricula: '2615', cpf: '03883577030', nome: 'MATHEUS VOLLMER' },
    { matricula: '2357', cpf: '03089957086', nome: 'MATHIAS AUGUSTO ZIMMERMANN' },
    { matricula: '2367', cpf: '05576012070', nome: 'MATHIAS DOS SANTOS SCHNEIDER' },
    { matricula: '2501', cpf: '04371783074', nome: 'MAURICIO BASTIDE PETRI' },
    { matricula: '2313', cpf: '03287623001', nome: 'MAURICIO DE ABREU' },
    { matricula: '609', cpf: '02376624002', nome: 'MAURICIO DOS SANTOS DA ROSA' },
    { matricula: '2274', cpf: '99943263091', nome: 'MAURICIO FLORES MACHADO' },
    { matricula: '313', cpf: '01358632014', nome: 'MAURICIO JUNIOR SFALCIN' },
    { matricula: '646', cpf: '03623706051', nome: 'MAURICIO LUIZ PADILHA' },
    { matricula: '237', cpf: '02180177070', nome: 'MAURICIO OLIVEIRA QUARESMA DA SILVA' },
    { matricula: '2305', cpf: '38942726860', nome: 'MAURICIO PENHA DOS SANTOS' },
    { matricula: '77', cpf: '90400020025', nome: 'MAURO ALOISIO HEINECK JUNG' },
    { matricula: '54', cpf: '46213686053', nome: 'MAURO CESAR DO NASCIMENTO URODA' },
    { matricula: '800', cpf: '03443215092', nome: 'MAURO GALILEU DE SOUZA VIEIRA' },
    { matricula: '2599', cpf: '86214411015', nome: 'MAURO JORGE MATOS' },
    { matricula: '2262', cpf: '93647654000', nome: 'MAURO JOSE KUZNIEWSKI' },
    { matricula: '403', cpf: '01292754095', nome: 'MAURO LUIZ DOS SANTOS' },
    { matricula: '2324', cpf: '07897911969', nome: 'MAYCOM MICHAEL FINSTERBUSCH' },
    { matricula: '23', cpf: '25436600034', nome: 'MIGUEL DA SILVA' },
    { matricula: '276', cpf: '56151870000', nome: 'MILTON DOS SANTOS VARGAS' },
    { matricula: '2014', cpf: '00927745046', nome: 'MISAEL AUGUSTO CARVALHO ALVES' },
    { matricula: '2278', cpf: '87186772072', nome: 'MOMATH SALL' },
    { matricula: '2535', cpf: '02629131009', nome: 'MURYEL CASTRO DIAS' },
    { matricula: '388', cpf: '02344675094', nome: 'NATAN DOS SANTOS' },
    { matricula: '2344', cpf: '02780441070', nome: 'NAYARA DE OLIVEIRA ALVES' },
    { matricula: '2189', cpf: '91121264034', nome: 'NERI JARDINELLO' },
    { matricula: '1903', cpf: '33857520000', nome: 'NIHAD CASTRO ABDALLAH' },
    { matricula: '2386', cpf: '02483388035', nome: 'NILSON DA COSTA GONCALVES' },
    { matricula: '693', cpf: '94544867053', nome: 'NILSON ELISANDRO DOS SANTOS GREFF PEREIRA' },
    { matricula: '102', cpf: '02263717005', nome: 'NILSON JUNIOR DE OLIVEIRA SCHEFFER' },
    { matricula: '240', cpf: '73790737020', nome: 'NILTON CESAR DORNELES SALES' },
    { matricula: '14', cpf: '56309740091', nome: 'ODILON ALMEIDA LOPES' },
    { matricula: '129', cpf: '36066869000', nome: 'ODONIR SILVEIRA' },
    { matricula: '2188', cpf: '83203940078', nome: 'OSVALDO VON MUHLEN' },
    { matricula: '2333', cpf: '00651687047', nome: 'OTEMAR SOARES DE LIMA NETO' },
    { matricula: '2247', cpf: '03355793027', nome: 'OTONIEL SIQUEIRA FRANCA' },
    { matricula: '2603', cpf: '02582580040', nome: 'PABLO NUNES PASSAMANI' },
    { matricula: '370', cpf: '03054046010', nome: 'PATRICIA KISCHNER' },
    { matricula: '599', cpf: '00789778009', nome: 'PATRICIA SOARES MASSAFRA' },
    { matricula: '1786', cpf: '03172782077', nome: 'PATRIQUE DOS SANTOS ROMERO' },
    { matricula: '314', cpf: '02884846069', nome: 'PAULA GIOVANA DA ROCHA DE OLIVEIRA' },
    { matricula: '2524', cpf: '02754165002', nome: 'PAULO ADRIANO PRESTES DA SILVA' },
    { matricula: '594', cpf: '01794823000', nome: 'PAULO ANDRE DA SILVA MACIEL' },
    { matricula: '2323', cpf: '52480038068', nome: 'PAULO CESAR RIBAS FROIS' },
    { matricula: '709', cpf: '95969020087', nome: 'PAULO CEZAR SOARES RIBEIRO' },
    { matricula: '92', cpf: '37524801149', nome: 'PAULO DARCY COFFI SANCHOTENE' },
    { matricula: '2517', cpf: '29264639870', nome: 'PAULO DIEGO MATHIAS' },
    { matricula: '100', cpf: '71406395072', nome: 'PAULO GIOVANI MACHADO' },
    { matricula: '821', cpf: '10306936682', nome: 'PAULO JULIO RAMALHO DOS SANTOS' },
    { matricula: '286', cpf: '02877672050', nome: 'PAULO MARTINHO KLOCK KAEFER' },
    { matricula: '2029', cpf: '79219179091', nome: 'PAULO RENATO CORREA FONSECA' },
    { matricula: '2617', cpf: '01325398020', nome: 'PAULO RICARDO DIAS ALMEIDA' },
    { matricula: '798', cpf: '01095288083', nome: 'PAULO ROBERTO VIRIATO ALVARES' },
    { matricula: '2231', cpf: '77323971004', nome: 'PAULO SERGIO DOS SANTOS' },
    { matricula: '2121', cpf: '91438713053', nome: 'PAULO SERGIO MIRANDA KIN' },
    { matricula: '1994', cpf: '70046565000', nome: 'PAULO SERGIO PEREIRA' },
    { matricula: '622', cpf: '40349238812', nome: 'PAULO SILVA DE SANTANA' },
    { matricula: '39', cpf: '33357056020', nome: 'PEDRINHO RODRIGUES MORAES' },
    { matricula: '33', cpf: '45112703091', nome: 'PEDRO ALZIRO DE BRUM MORAES' },
    { matricula: '396', cpf: '33197750063', nome: 'PEDRO MANOEL DE SOUZA' },
    { matricula: '2448', cpf: '03930817020', nome: 'PETERSON SOARES DA SILVA' },
    { matricula: '801', cpf: '97713694072', nome: 'RAFAEL ALEX CORREA FLORES' },
    { matricula: '564', cpf: '02506453008', nome: 'RAFAEL AUGUSTO MELO ALVES' },
    { matricula: '354', cpf: '99438186034', nome: 'RAFAEL BELLINI MORAIS' },
    { matricula: '172', cpf: '04738261940', nome: 'RAFAEL CORDEIRO' },
    { matricula: '2536', cpf: '46842019885', nome: 'RAFAEL DA SILVA GAMA' },
    { matricula: '2258', cpf: '01320453031', nome: 'RAFAEL DOS ANJOS STROHHECKER' },
    { matricula: '34', cpf: '01812091010', nome: 'RAFAEL FALCAO DOS SANTOS' },
    { matricula: '310', cpf: '01651823006', nome: 'RAFAEL KAPP' },
    { matricula: '1784', cpf: '00516573098', nome: 'RAFAEL PUJOL' },
    { matricula: '1965', cpf: '31518833896', nome: 'RAFAEL RUAS DOS SANTOS' },
    { matricula: '745', cpf: '01461163056', nome: 'RAFAEL SANTIAGO PEREIRA' },
    { matricula: '2463', cpf: '00699479029', nome: 'RAFAEL SOUZA DE ANDRADE' },
    { matricula: '2411', cpf: '02284771076', nome: 'RAFAELA GRAUNCKE CHESANI' },
    { matricula: '2425', cpf: '01100414029', nome: 'RAMAO ANDRE DO NASCIMENTO FERREIRA' },
    { matricula: '171', cpf: '78239460097', nome: 'RAMAO RAMOS DA SILVA' },
    { matricula: '2221', cpf: '03502967075', nome: 'RAMIRO DE OLIVEIRA PENNA' },
    { matricula: '692', cpf: '04292157545', nome: 'RANGEL AMARAL' },
    { matricula: '503', cpf: '97404004004', nome: 'REGIS ADRIANO FERNANDES PEREIRA' },
    { matricula: '2307', cpf: '03007730058', nome: 'RENAN ANDREI CEZAR WEIGNER' },
    { matricula: '809', cpf: '04015629051', nome: 'RENAN FUCILINI' },
    { matricula: '111', cpf: '96323680025', nome: 'RENAN LUTZ' },
    { matricula: '41', cpf: '45158002053', nome: 'RENATO JACO SAUZEN' },
    { matricula: '433', cpf: '34268168818', nome: 'RENILDA CERQUEIRA BARBOSA' },
    { matricula: '18', cpf: '16647246053', nome: 'RENY DA SILVA BOSSLE' },
    { matricula: '2317', cpf: '77210603034', nome: 'RICARDO ALEXANDRE DE JESUS' },
    { matricula: '1964', cpf: '95116222004', nome: 'RICARDO FISCHER DIAS' },
    { matricula: '530', cpf: '32069668843', nome: 'RICARDO GABRIEL' },
    { matricula: '2294', cpf: '02069152090', nome: 'RICARDO LAZZARETTI NETO' },
    { matricula: '2158', cpf: '02107615047', nome: 'RICARDO MACHADO DAMASCENO' },
    { matricula: '517', cpf: '81605757004', nome: 'RICARDO MINOZZO KUCERA' },
    { matricula: '301', cpf: '01282406019', nome: 'RICARDO MOURA DELAM' },
    { matricula: '264', cpf: '02085451071', nome: 'RICARDO PABLO MACHADO NUNES' },
    { matricula: '415', cpf: '29119187890', nome: 'RICARDO RODRIGUES MAGALHAES' },
    { matricula: '2086', cpf: '35031893869', nome: 'RICARDO ROGERIO DOS SANTOS' },
    { matricula: '2292', cpf: '03857532092', nome: 'RICARDO TEIXEIRA CAVALHEIRO' },
    { matricula: '2055', cpf: '02549923073', nome: 'RICARDO VALENTE OLIVEIRA' },
    { matricula: '2618', cpf: '02272214002', nome: 'ROBERIO DE ALMEIDA' },
    { matricula: '643', cpf: '02200989059', nome: 'ROBERSON VARGAS DE OLIVEIRA' },
    { matricula: '1944', cpf: '03112937007', nome: 'ROBERTO ALVARAZ CHARAO FERNANDES' },
    { matricula: '772', cpf: '16937957874', nome: 'ROBERTO BARBOSA DE ANDRADE' },
    { matricula: '687', cpf: '00762516089', nome: 'ROBERTO SANTOS' },
    { matricula: '2346', cpf: '00834102013', nome: 'ROBINSON MACHADO PACHECO' },
    { matricula: '1889', cpf: '01040993052', nome: 'ROBSON LUCAS FREITAS DO NASCIMENTO' },
    { matricula: '376', cpf: '01842940023', nome: 'ROBSON MITTELSTAEDT' },
    { matricula: '2458', cpf: '03535995060', nome: 'ROBSON MOURA DELAM' },
    { matricula: '1772', cpf: '03012005056', nome: 'ROBSON SOARES DE OLIVEIRA' },
    { matricula: '225', cpf: '44909152920', nome: 'RODERLEI LONGUE' },
    { matricula: '2319', cpf: '07529736906', nome: 'RODOLFO VITOR SOARES PEREIRA' },
    { matricula: '2404', cpf: '02902586000', nome: 'RODRIGO AJALA' },
    { matricula: '762', cpf: '00804057036', nome: 'RODRIGO ARTAGABEITIA BEULK' },
    { matricula: '2608', cpf: '00644094060', nome: 'RODRIGO BELLAVER' },
    { matricula: '2322', cpf: '08056152962', nome: 'RODRIGO DA LUZ TABISZ' },
    { matricula: '1811', cpf: '92527957072', nome: 'RODRIGO ERNANDES FRANCO' },
    { matricula: '2041', cpf: '03593426048', nome: 'RODRIGO GODOIS ALVES' },
    { matricula: '1924', cpf: '95031901004', nome: 'RODRIGO MARANGON' },
    { matricula: '2590', cpf: '00875375081', nome: 'RODRIGO MARKUS' },
    { matricula: '1963', cpf: '02691944018', nome: 'RODRIGO SCHMIDT FALCAO' },
    { matricula: '514', cpf: '00464867045', nome: 'RODRIGO SOARES' },
    { matricula: '2530', cpf: '02530862052', nome: 'RODRIGO SOARES BONETO' },
    { matricula: '2571', cpf: '00859744043', nome: 'ROGER QUEVEDO DE MORAES' },
    { matricula: '409', cpf: '03206765013', nome: 'ROGERIO DE MOURA' },
    { matricula: '2038', cpf: '81952562015', nome: 'ROGERIO FABIANO CANABARRO NUNES' },
    { matricula: '523', cpf: '94417288020', nome: 'ROGERIO PASETTI' },
    { matricula: '22', cpf: '01394988001', nome: 'ROGERIO TREINES RIBEIRO' },
    { matricula: '8', cpf: '27303578072', nome: 'ROMARIO RAMOS MIRANDA' },
    { matricula: '2456', cpf: '52837556068', nome: 'RONALDO DOS SANTOS ZUCCO' },
    { matricula: '2446', cpf: '01906884080', nome: 'RONALDO VITORINO DOMINGUES' },
    { matricula: '684', cpf: '03747193064', nome: 'RONI MARCAL RIBEIRO DOS SANTOS' },
    { matricula: '2334', cpf: '98980327072', nome: 'ROQUE JOCEMAR GONCALVES ORTIZ' },
    { matricula: '634', cpf: '94418039015', nome: 'ROSENILDO FERNANDES PORTELA' },
    { matricula: '505', cpf: '87135280415', nome: 'ROSIVAN RODRIGUES' },
    { matricula: '59', cpf: '91589487087', nome: 'RUBIA TATIANA SCHMITZ DE ABREU' },
    { matricula: '769', cpf: '96542535004', nome: 'RUDINEI BARCELOS OLIVEIRA' },
    { matricula: '1896', cpf: '97270830053', nome: 'RUDINEI FARIAS MORAES' },
    { matricula: '2142', cpf: '01915806003', nome: 'RUDINEI FERREIRA DOS SANTOS' },
    { matricula: '2170', cpf: '02440406058', nome: 'RUDINEI RODRIGUES FERNANDES' },
    { matricula: '348', cpf: '81757859004', nome: 'SARAH BOSSARDI ZANETTE BRONZI' },
    { matricula: '2480', cpf: '03667682042', nome: 'SAYMON HALEX MORENO PARAIBA' },
    { matricula: '506', cpf: '54744288049', nome: 'SEBASTIAO TADEU FALEIRO' },
    { matricula: '2466', cpf: '00618171045', nome: 'SELMAR QUADROS DE MACEDO' },
    { matricula: '2015', cpf: '00535686056', nome: 'SERGIO FAGUNDES TEIXEIRA' },
    { matricula: '2225', cpf: '02446409040', nome: 'SERGIO RICARDO DA SILVA RAMOS' },
    { matricula: '63', cpf: '44625952034', nome: 'SERGIO ROBERTO LESKE' },
    { matricula: '2354', cpf: '01547990090', nome: 'SIDNEI DIAS PORTES' },
    { matricula: '275', cpf: '40686981049', nome: 'SILVIO GLASENAPP' },
    { matricula: '373', cpf: '01115886002', nome: 'SIRLENE PINHEIRO DA SILVA' },
    { matricula: '25', cpf: '41720989087', nome: 'SOLI ANTONIO DE PAULA' },
    { matricula: '2481', cpf: '02119086060', nome: 'STEFAN BAMBERG SAMPAIO' },
    { matricula: '2370', cpf: '04754800079', nome: 'STEFANNYE DOS SANTOS SELATCHEK' },
    { matricula: '2521', cpf: '03417287057', nome: 'TAINA DOS ANJOS NIGOLINO' },
    { matricula: '1770', cpf: '03759627005', nome: 'TAINARA DIDONE MARTINI' },
    { matricula: '1920', cpf: '00692063099', nome: 'TARCISIO HOFFMANN DA SILVA' },
    { matricula: '2518', cpf: '03664747070', nome: 'TATIANA KOCK PEREIRA' },
    { matricula: '2586', cpf: '03234748020', nome: 'TELMO AUGUSTO BONINI' },
    { matricula: '1983', cpf: '04810145000', nome: 'THAINE ISADORA DOS SANTOS THOME DA CRUZ' },
    { matricula: '2130', cpf: '02084580005', nome: 'THIAGO BENDER SALBEGO' },
    { matricula: '2414', cpf: '01176118080', nome: 'THIAGO GARCEZ TEN CATEN' },
    { matricula: '2288', cpf: '33481675828', nome: 'THIAGO RIBEIRO LOPES' },
    { matricula: '150', cpf: '81667965034', nome: 'TIAGO CAVALHEIRO ROMERO' },
    { matricula: '2045', cpf: '05634963982', nome: 'TIAGO DA ROSA MARTINS' },
    { matricula: '2402', cpf: '99586290034', nome: 'TIAGO DIAS NAPP' },
    { matricula: '611', cpf: '00382057007', nome: 'TIAGO MACHADO DOS SANTOS' },
    { matricula: '144', cpf: '03233874030', nome: 'TIAGO NORONHA VENANCIO' },
    { matricula: '2209', cpf: '12522926608', nome: 'TIAGO SAMUEL DE OLIVEIRA COSTA' },
    { matricula: '2286', cpf: '01301341126', nome: 'TIAGO VALTER RUPPENTHAL' },
    { matricula: '277', cpf: '03794782003', nome: 'TIARLES SILVA DOS SANTOS' },
    { matricula: '2250', cpf: '01512745090', nome: 'TIELE CHAIANA DE AZEVEDO PAIM' },
    { matricula: '818', cpf: '01738687031', nome: 'VAGNER ALVES AFONSO' },
    { matricula: '2416', cpf: '00446728020', nome: 'VAGNER DA CUNHA' },
    { matricula: '308', cpf: '16093495809', nome: 'VAGNER TOZZI JUNIOR' },
    { matricula: '190', cpf: '59821493068', nome: 'VALCIR PEREIRA DUARTE' },
    { matricula: '78', cpf: '46359770059', nome: 'VALDEMAR FERREIRA' },
    { matricula: '185', cpf: '88396274053', nome: 'VALDEMIR DE MOURA DA SILVA' },
    { matricula: '175', cpf: '17908469000', nome: 'VALDIR ANTONIO MELLER' },
    { matricula: '35', cpf: '30971314004', nome: 'VALDIR ITAMAR PEREIRA DOS SANTOS' },
    { matricula: '509', cpf: '54702178091', nome: 'VALMIR ALBRECHT' },
    { matricula: '2576', cpf: '26298271864', nome: 'VALMIR CANDIDO DOS SANTOS' },
    { matricula: '273', cpf: '02213439974', nome: 'VALMIR DOLIZETE COUTO' },
    { matricula: '62', cpf: '33187789072', nome: 'VALMOR ROQUE DA SILVA' },
    { matricula: '142', cpf: '38466538020', nome: 'VALTER DINIZ SOUZA' },
    { matricula: '132', cpf: '90513860010', nome: 'VANDERLEI FANFA RODRIGUES' },
    { matricula: '626', cpf: '00216565090', nome: 'VANDERLEI ORTIZ DINIZ' },
    { matricula: '2597', cpf: '03261345063', nome: 'VANESSA BRIZZI DA SILVA' },
    { matricula: '2467', cpf: '02238501100', nome: 'VANESSA CONCEICAO GOMES GIESELER' },
    { matricula: '2076', cpf: '03870934000', nome: 'VINICIO MACHADO REINOCO' },
    { matricula: '2583', cpf: '02397341069', nome: 'VINICIOS MATTER MILANI' },
    { matricula: '2454', cpf: '03255676037', nome: 'VINICIUS BRETSCHNEIDER PEREIRA' },
    { matricula: '2194', cpf: '38621888840', nome: 'VINICIUS OLIVEIRA DE PAULA' },
    { matricula: '16', cpf: '47248297020', nome: 'VITOR ARMINDO FISCHER FILHO' },
    { matricula: '1916', cpf: '01010305093', nome: 'VITOR RODRIGUES DIAS' },
    { matricula: '2474', cpf: '00962836028', nome: 'VOLNEI PEREIRA DOS SANTOS' },
    { matricula: '1996', cpf: '01095596063', nome: 'WAGNER NEVES LEAL' },
    { matricula: '832', cpf: '50133284034', nome: 'WALTER MACHADO NUNES' },
    { matricula: '346', cpf: '03100501039', nome: 'WELINSON PANKE OLEINICZAK' },
    { matricula: '2191', cpf: '03380110647', nome: 'WELLIMAR DA SILVA CASTRO' },
    { matricula: '658', cpf: '33212427884', nome: 'WELLINGTON CARDOSO CORREA' },
    { matricula: '372', cpf: '03465859022', nome: 'WELLINGTON WILDNER CARRILHO CORREA' },
    { matricula: '2449', cpf: '04614242073', nome: 'WENDEL LEITE SILVEIRA MENDONCA' },
    { matricula: '2366', cpf: '39125325833', nome: 'WILLIAM GUEDES DA CUNHA' },
    { matricula: '161', cpf: '02354631081', nome: 'WILLIAN DOS SANTOS COSTA' },
    { matricula: '2064', cpf: '03996359047', nome: 'WILLIAN DOUGLAS DA SILVA LANGNER' },
    { matricula: '2119', cpf: '03922129005', nome: 'WILLIAN FERREIRA SAUCEDO' },
    { matricula: '1895', cpf: '02718667052', nome: 'YURIKI FLAUBIANO PEREIRA' }
  ],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ESOCIAL_MODO_TELA":
      return {
        ...state,
        modoTela: action.payload.modo,
        registro: action.payload.registro,
      };

    case "ESOCIAL_LISTADO":
      return {
        ...state,
        lista: action.payload.data,
      };

    default:
      return state;
  }
};
