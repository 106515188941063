import React from "react";
import Grid from "../layout/grid";
import InputMask from "react-input-mask";

export default (props) => {
  return (
    <Grid cols={props.cols}>
      <div className="form-group">
        <label htmlFor={props.name}>{props.label}</label>
        <InputMask
          {...props.input}
          className="form-control"
          mask={props.mask || "99/99/9999 99:99"}
          maskChar=" "
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          type={props.type}
          onChange={props.onChange}
          onFocus={(e) => (e.target.value = e.target.value || "")}
          onBlur={(e) => (e.target.value = e.target.value || "00:00")}
          value={props.value}
        />
      </div>
    </Grid>
  );
};
