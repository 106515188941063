import React from "react";
import Grid from "../layout/grid";
import Select from "react-select";

export default (props) => (
  <Grid cols={props.cols} style={{ ...props.style }}>
    <div className="form-group">
      <label htmlFor={props.name}>{props.label}</label>

      {props.onChange ? (
        <Select
          style={{ minWidth: "max-content" }}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          defaultValue={
            props.input && props.input.value
              ? (props.options || [])
                  .map((opt) => ({ value: String(opt.id), label: opt.valor }))
                  .filter(
                    (opt) => opt.value === String(props.input.value)
                  )[0] || null
              : (props.options || [])
                  .map((opt) => ({ value: String(opt.id), label: opt.valor }))
                  .filter(
                    (opt) => opt.label === String(props.defaultSelected)
                  )[0]
          }
          isClearable={props.isClearable ? true : false}
          isSearchable={true}
          isDisabled={props.readOnly ? true : false}
          onChange={(item) => props.onChange(item ? item.value : null)}
          options={(props.options || []).map((opt) => ({
            value: opt.id,
            label: opt.valor,
          }))}
        />
      ) : (
        <Select
          style={{ minWidth: "max-content" }}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          isClearable={props.isClearable ? true : false}
          isSearchable={true}
          isDisabled={props.readOnly ? true : false}
          defaultValue={
            props.input && props.input.value
              ? (props.options || [])
                  .map((opt) => ({ value: String(opt.id), label: opt.valor }))
                  .filter(
                    (opt) => opt.value === String(props.input.value)
                  )[0] || null
              : (props.options || [])
                  .map((opt) => ({ value: String(opt.id), label: opt.valor }))
                  .filter(
                    (opt) => opt.label === String(props.defaultSelected)
                  )[0] || null
          }
          onChange={(item) => props.input.onChange(item ? item.value : null)}
          onBlur={(value) => props.input.onBlur()}
          options={(props.options || []).map((opt) => ({
            value: opt.id,
            label: opt.valor,
          }))}
        />
      )}
    </div>
  </Grid>
);
