import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import { setError } from "../common/toast/toast";
import DateFormat from "../common/dateFormat/DateFormat";
import XMLParser from "react-xml-parser";
import ReactExport from "react-export-excel-fixed-xlsx";
import LabelAndInputMask from "../common/form/labelAndInputMask";

import { setModoTela, getLista } from "./esocialActions";

class ESocial extends Component {
  state = {
    linhaSelecionada: null,
    lista220: [],
    lista240: [],
    filtro: {
      dataInicial: null,
      dataFinal: null,
    },
  };

  componentDidMount() {
    const fileSelector220 = document.createElement("input");
    fileSelector220.setAttribute("type", "file");
    fileSelector220.setAttribute("multiple", "multiple");
    fileSelector220.addEventListener("change", async (data) => {
      let files =
        data && data.path && data.path[0] && data.path[0].files
          ? data.path[0].files || []
          : [];
      if (!files.length) {
        files =
          data && data.target && data.target.files
            ? data.target.files || []
            : null;
      }

      if (files.length) {
        let arquivos = [];
        let lido = 0;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          lido++;

          if (!file.type.includes("text/xml")) {
            setError("Selecione apenas (.XML)");
          } else {
            var reader = new FileReader();
            reader.onload = function (event) {
              const xml = new XMLParser().parseFromString(event.target.result);

              try {
                for (
                  let i = 1;
                  i <
                  xml.children[0].children[3].children[1].children.length - 1;
                  i++
                ) {
                  let registro = {
                    Id: xml.children[0].attributes.Id,
                    "ns1:indRetif":
                      xml.children[0].children[0].children[0].value,
                    "ns1:tpAmb": xml.children[0].children[0].children[1].value,
                    "ns1:procEmi":
                      xml.children[0].children[0].children[2].value,
                    "ns1:verProc":
                      xml.children[0].children[0].children[3].value,
                    "ns1:tpInsc": xml.children[0].children[1].children[0].value,
                    "ns1:nrInsc": xml.children[0].children[1].children[1].value,
                    "ns1:cpfTrab": xml.children[0].children[2].children[0].value
                      .padStart(11, "0")
                      .trim(),
                    "ns1:nome": null,
                    "ns1:matricula": null,
                    "ns1:tpExameOcup":
                      xml.children[0].children[3].children[0].value,
                    "ns1:dtAso": xml.children[0].children[3].children[1]
                      .children[0].value
                      ? DateFormat.formatarDataSqlParaTela(
                          xml.children[0].children[3].children[1].children[0]
                            .value
                        )
                      : null,
                    "ns1:dtExm": xml.children[0].children[3].children[1]
                      .children[i].children[0].value
                      ? DateFormat.formatarDataSqlParaTela(
                          xml.children[0].children[3].children[1].children[i]
                            .children[0].value
                        )
                      : null,
                    "ns1:procRealizado":
                      xml.children[0].children[3].children[1].children[i]
                        .children[1].value,
                    "ns1:obsProc":
                      xml.children[0].children[3].children[1].children[i]
                        .children.length >= 4
                        ? xml.children[0].children[3].children[1].children[i]
                            .children[2].value
                        : 0,
                    "ns1:ordExame":
                      xml.children[0].children[3].children[1].children[i]
                        .children.length >= 4
                        ? xml.children[0].children[3].children[1].children[i]
                            .children[3].value
                        : xml.children[0].children[3].children[1].children[i]
                            .children[2].value,
                    "ns1:nmMed":
                      xml.children[0].children[3].children[1].children[
                        xml.children[0].children[3].children[1].children
                          .length - 1
                      ].children[0].value,
                    "ns1:nrCRM":
                      xml.children[0].children[3].children[1].children[
                        xml.children[0].children[3].children[1].children
                          .length - 1
                      ].children[1].value,
                    "ns1:ufCRM":
                      xml.children[0].children[3].children[1].children[
                        xml.children[0].children[3].children[1].children
                          .length - 1
                      ].children[2].value,
                    "ns1:nmResp":
                      xml.children[0].children[3].children[2].children[0].value,
                    "ns1:nrCRM2":
                      xml.children[0].children[3].children[2].children[1].value,
                    "ns1:ufCRM3":
                      xml.children[0].children[3].children[2].children[2].value,
                  };

                  arquivos.push(registro);
                }
              } catch (ex) {
                // console.log(ex);

                setError(`Erro ao ler arquivo ${file.name}`);
              }
            };
            reader.readAsText(file);
          }
        }

        let idInterval = setInterval(() => {
          if (lido == files.length) {
            clearInterval(idInterval);

            this.setState({ ...this.state, lista220: arquivos });
          }
        }, 1000);
      }
    });

    this.fileSelector220 = fileSelector220;

    const fileSelector240 = document.createElement("input");
    fileSelector240.setAttribute("type", "file");
    fileSelector240.setAttribute("multiple", "multiple");
    fileSelector240.addEventListener("change", async (data) => {
      let files =
        data && data.path && data.path[0] && data.path[0].files
          ? data.path[0].files || []
          : [];
      if (!files.length) {
        files =
          data && data.target && data.target.files
            ? data.target.files || []
            : null;
      }

      if (files.length) {
        let arquivos = [];
        let lido = 0;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          lido++;

          if (!file.type.includes("text/xml")) {
            setError("Selecione apenas (.XML)");
          } else {
            var reader = new FileReader();
            reader.onload = function (event) {
              const xml = new XMLParser().parseFromString(event.target.result);

              try {
                let registro = {
                  Id: xml.children[0].attributes.Id,
                  "ns1:indRetif": xml.children[0].children[0].children[0].value,
                  "ns1:tpAmb": xml.children[0].children[0].children[1].value,
                  "ns1:procEmi": xml.children[0].children[0].children[2].value,
                  "ns1:verProc": xml.children[0].children[0].children[3].value,
                  "ns1:tpInsc": xml.children[0].children[1].children[0].value,
                  "ns1:nrInsc": xml.children[0].children[1].children[1].value,
                  "ns1:cpfTrab": xml.children[0].children[2].children[0].value
                    .padStart(11, "0")
                    .trim(),
                  "ns1:nome": null,
                  "ns1:matricula": null,
                  "ns1:dtIniCondicao": xml.children[0].children[3].children[0]
                    .value
                    ? DateFormat.formatarDataSqlParaTela(
                        xml.children[0].children[3].children[0].value
                      )
                    : null,
                  "ns1:localAmb":
                    xml.children[0].children[3].children[1].children[0].value,
                  "ns1:dscSetor":
                    xml.children[0].children[3].children[1].children[1].value,
                  "ns1:tpInsc":
                    xml.children[0].children[3].children[1].children[2].value,
                  "ns1:nrInsc":
                    xml.children[0].children[3].children[1].children[3].value,
                  "ns1:dscAtivDes":
                    xml.children[0].children[3].children[2].children[0].value,
                  "ns1:codAgNoc":
                    xml.children[0].children[3].children[3].children[0].value,
                  "ns1:cpfResp":
                    xml.children[0].children[3].children[4].children[0].value,
                  "ns1:ideOC":
                    xml.children[0].children[3].children[4].children[1].value,
                  "ns1:nrOC":
                    xml.children[0].children[3].children[4].children[2].value,
                  "ns1:ufOC":
                    xml.children[0].children[3].children[4].children[3].value,
                };

                arquivos.push(registro);
              } catch (ex) {
                // console.log(ex);

                setError(`Erro ao ler arquivo ${file.name}`);
              }
            };
            reader.readAsText(file);
          }
        }

        let idInterval = setInterval(() => {
          if (lido == files.length) {
            clearInterval(idInterval);

            this.setState({ ...this.state, lista240: arquivos });
          }
        }, 1000);
      }
    });

    this.fileSelector240 = fileSelector240;
  }
  componentWillMount() {
    this.props.getLista();
  }

  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    let dataInicial = DateFormat.formatarDataTelaParaSql(
      this.state.filtro.dataInicial
    );
    let dataFinal = DateFormat.formatarDataTelaParaSql(
      this.state.filtro.dataFinal
    );

    let lista220 = this.state.lista220
      .filter(
        (item) =>
          (!dataInicial ||
            DateFormat.formatarDataTelaParaSql(item["ns1:dtAso"]) >=
              dataInicial) &&
          (!dataFinal ||
            DateFormat.formatarDataTelaParaSql(item["ns1:dtAso"]) <= dataFinal)
      )
      .map((item) => {
        let motorista = (this.props.lista || []).filter(
          (itemMotorista) =>
            itemMotorista.cpf.trim() == item["ns1:cpfTrab"].trim()
        )[0];

        return {
          ...item,
          "ns1:nome": motorista ? motorista.nome : "",
          "ns1:matricula": motorista ? motorista.matricula : "",
        };
      });

    let lista220Exportacao = lista220.map((item) => item);
    lista220Exportacao.push({
      Id: "Identificação única do evento",
      "ns1:indRetif": "Arquivo original / Retificado",
      "ns1:tpAmb": "Identificação do ambiente",
      "ns1:procEmi": "Processo de emissão do evento",
      "ns1:verProc": "Versão do processo de emissão do evento",
      "ns1:tpInsc": "Tipo de inscrição",
      "ns1:nrInsc": "Número de inscrição ",
      "ns1:cpfTrab": "CPF do trabalhador",
      "ns1:nome": "Nome do trabalhador",
      "ns1:matricula": "Matricula",
      "ns1:tpExameOcup": "Tipo do exame médico ocupacional",
      "ns1:dtAso": "Data de emissão do ASO",
      "ns1:dtExm": "Data do exame realizado",
      "ns1:procRealizado": "Código do exame realizado",
      "ns1:obsProc": "Observação sobre o exame realizado",
      "ns1:ordExame": "Ordem do exame",
      "ns1:nmMed": "Médico emitente do ASO",
      "ns1:nrCRM": "CRM médico emitente do ASO",
      "ns1:ufCRM": "UF CRM médico emitente do ASO",
      "ns1:nmResp": "Médico Responsável PCMSO",
      "ns1:nrCRM2": "CRM Médico Responsável PCMSO",
      "ns1:ufCRM3": "UF CRM Médico Responsável PCMSO",
    });

    let lista240 = this.state.lista240
      .filter(
        (item) =>
          (!dataInicial ||
            DateFormat.formatarDataTelaParaSql(item["ns1:dtIniCondicao"]) >=
              dataInicial) &&
          (!dataFinal ||
            DateFormat.formatarDataTelaParaSql(item["ns1:dtIniCondicao"]) <=
              dataFinal)
      )
      .map((item) => {
        let motorista = (this.props.lista || []).filter(
          (itemMotorista) =>
            itemMotorista.cpf.trim() == item["ns1:cpfTrab"].trim()
        )[0];

        return {
          ...item,
          "ns1:nome": motorista ? motorista.nome : "",
          "ns1:matricula": motorista ? motorista.matricula : "",
        };
      });

    let lista240Exportacao = lista240.map((item) => item);
    lista240Exportacao.push({
      Id: "Identificação única do evento",
      "ns1:indRetif": "Arquivo original / Retificado",
      "ns1:tpAmb": "Identificação do ambiente",
      "ns1:procEmi": "Processo de emissão do evento",
      "ns1:verProc": "Versão do processo de emissão do evento",
      "ns1:tpInsc": "Tipo de inscrição",
      "ns1:nrInsc": "Número de inscrição ",
      "ns1:cpfTrab": "CPF do trabalhador",
      "ns1:nome": "Nome do trabalhador",
      "ns1:matricula": "Matricula",
      "ns1:dtIniCondicao": "Data Início da Condição",
      "ns1:localAmb": "Local / Ambiente",
      "ns1:dscSetor": "Descrição do Setor",
      "ns1:tpInsc": "Tipo da Inscrição",
      "ns1:nrInsc": "Número da Inscrição",
      "ns1:dscAtivDes": "Descrição da Atividade",
      "ns1:codAgNoc": "",
      "ns1:cpfResp": "",
      "ns1:ideOC": "",
      "ns1:nrOC": "",
      "ns1:ufOC": "",
    });

    return (
      <>
        <ContentCard style={{ paddingLeft: 10 }}>
          <ContentCardBody>
            <Row>
              <LabelAndInputMask
                label="Data Inicial"
                placeholder="Informe a data inicial"
                cols="12 3 3"
                mask="99/99/9999"
                value={this.state.filtro.dataInicial}
                onChange={(data) => {
                  const filtro = {
                    ...this.state.filtro,
                    dataInicial: data.target.value,
                  };
                  this.setState({
                    ...this.state,
                    filtro: filtro,
                  });
                }}
              />

              <LabelAndInputMask
                label="Data Final"
                placeholder="Informe a data final"
                cols="12 3 3"
                mask="99/99/9999"
                value={this.state.filtro.dataFinal}
                onChange={(data) => {
                  const filtro = {
                    ...this.state.filtro,
                    dataFinal: data.target.value,
                  };
                  this.setState({
                    ...this.state,
                    filtro: filtro,
                  });
                }}
              />
            </Row>
          </ContentCardBody>
        </ContentCard>

        <ContentCard style={{ paddingLeft: 10 }}>
          <ContentCardHeader>
            <Row>
              {this.props.lista.length > 0 && (
                <Grid cols="6 6 4 2">
                  <Button
                    text="Importar 220"
                    type={"success"}
                    icon={"fa fa-chevron-left"}
                    event={() => this.fileSelector220.click()}
                  />
                </Grid>
              )}
              <Grid cols="6 6 3 2">
                <ExcelFile
                  filename={"esocial"}
                  element={
                    <Button
                      text="Exportar 220"
                      type={"primary"}
                      icon={"fa fa-plus"}
                    />
                  }
                >
                  <ExcelSheet
                    data={lista220Exportacao.map((item) => ({
                      ...item,
                    }))}
                    name="e-social"
                  >
                    <ExcelColumn label="Id" value="Id" />
                    <ExcelColumn label="ns1:indRetif" value="ns1:indRetif" />
                    <ExcelColumn label="ns1:tpAmb" value="ns1:tpAmb" />
                    <ExcelColumn label="ns1:procEmi" value="ns1:procEmi" />
                    <ExcelColumn label="ns1:verProc" value="ns1:verProc" />
                    <ExcelColumn label="ns1:tpInsc" value="ns1:tpInsc" />
                    <ExcelColumn label="ns1:nrInsc" value="ns1:nrInsc" />
                    <ExcelColumn label="ns1:cpfTrab" value="ns1:cpfTrab" />
                    <ExcelColumn label="ns1:nome" value="ns1:nome" />
                    <ExcelColumn label="ns1:matricula" value="ns1:matricula" />
                    <ExcelColumn
                      label="ns1:tpExameOcup"
                      value="ns1:tpExameOcup"
                    />
                    <ExcelColumn label="ns1:dtAso" value="ns1:dtAso" />
                    <ExcelColumn label="ns1:dtExm" value="ns1:dtExm" />
                    <ExcelColumn
                      label="ns1:procRealizado"
                      value="ns1:procRealizado"
                    />
                    <ExcelColumn label="ns1:obsProc" value="ns1:obsProc" />
                    <ExcelColumn label="ns1:ordExame" value="ns1:ordExame" />
                    <ExcelColumn label="ns1:nmMed" value="ns1:nmMed" />
                    <ExcelColumn label="ns1:nrCRM" value="ns1:nrCRM" />
                    <ExcelColumn label="ns1:ufCRM" value="ns1:ufCRM" />
                    <ExcelColumn label="ns1:nmResp" value="ns1:nmResp" />
                    <ExcelColumn label="ns1:nrCRM2" value="ns1:nrCRM2" />
                    <ExcelColumn label="ns1:ufCRM3" value="ns1:ufCRM3" />
                  </ExcelSheet>
                </ExcelFile>
              </Grid>
            </Row>
          </ContentCardHeader>
          <ContentCardBody>
            <Table responsive tableBordered>
              <THead>
                <Tr>
                  <Th alignCenter>Id</Th>
                  <Th alignCenter>ns1:indRetif</Th>
                  <Th alignCenter>ns1:tpAmb</Th>
                  <Th alignCenter>ns1:procEmi</Th>
                  <Th alignCenter>ns1:verProc</Th>
                  <Th alignCenter>ns1:tpInsc</Th>
                  <Th alignCenter>ns1:nrInsc</Th>
                  <Th alignCenter>ns1:cpfTrab</Th>
                  <Th alignCenter>ns1:nome</Th>
                  <Th alignCenter>ns1:matricula</Th>
                  <Th alignCenter>ns1:tpExameOcup</Th>
                  <Th alignCenter>ns1:dtAso</Th>
                  <Th alignCenter>ns1:dtExm</Th>
                  <Th alignCenter>ns1:procRealizado</Th>
                  <Th alignCenter>ns1:obsProc</Th>
                  <Th alignCenter>ns1:ordExame</Th>
                  <Th alignCenter>ns1:nmMed</Th>
                  <Th alignCenter>ns1:nrCRM</Th>
                  <Th alignCenter>ns1:ufCRM</Th>
                  <Th alignCenter>ns1:nmResp</Th>
                  <Th alignCenter>ns1:nrCRM2</Th>
                  <Th alignCenter>ns1:ufCRM3</Th>
                </Tr>
              </THead>
              <TBody>
                {lista220.map((item) => (
                  <Tr key={item.Id}>
                    <Td alignCenter>{item.Id}</Td>
                    <Td alignCenter>{item["ns1:indRetif"]}</Td>
                    <Td alignCenter>{item["ns1:tpAmb"]}</Td>
                    <Td alignCenter>{item["ns1:procEmi"]}</Td>
                    <Td alignCenter>{item["ns1:verProc"]}</Td>
                    <Td alignCenter>{item["ns1:tpInsc"]}</Td>
                    <Td alignCenter>{item["ns1:nrInsc"]}</Td>
                    <Td alignCenter>{item["ns1:cpfTrab"]}</Td>
                    <Td alignCenter>{item["ns1:nome"]}</Td>
                    <Td alignCenter>{item["ns1:matricula"]}</Td>
                    <Td alignCenter>{item["ns1:tpExameOcup"]}</Td>
                    <Td alignCenter>{item["ns1:dtAso"]}</Td>
                    <Td alignCenter>{item["ns1:dtExm"]}</Td>
                    <Td alignCenter>{item["ns1:procRealizado"]}</Td>
                    <Td alignCenter>{item["ns1:obsProc"]}</Td>
                    <Td alignCenter>{item["ns1:ordExame"]}</Td>
                    <Td alignCenter>{item["ns1:nmMed"]}</Td>
                    <Td alignCenter>{item["ns1:nrCRM"]}</Td>
                    <Td alignCenter>{item["ns1:ufCRM"]}</Td>
                    <Td alignCenter>{item["ns1:nmResp"]}</Td>
                    <Td alignCenter>{item["ns1:nrCRM2"]}</Td>
                    <Td alignCenter>{item["ns1:ufCRM3"]}</Td>
                  </Tr>
                ))}

                <Tr style={{ backgroundColor: "#b4c2ed" }}>
                  <Td alignCenter>Identificação única do evento</Td>
                  <Td alignCenter>Arquivo original / Retificado</Td>
                  <Td alignCenter>Identificação do ambiente</Td>
                  <Td alignCenter>Processo de emissão do evento</Td>
                  <Td alignCenter>Versão do processo de emissão do evento</Td>
                  <Td alignCenter>Tipo de inscrição</Td>
                  <Td alignCenter>Número de inscrição </Td>
                  <Td alignCenter>CPF do trabalhador</Td>
                  <Td alignCenter>Nome do trabalhador</Td>
                  <Td alignCenter>Matricula</Td>
                  <Td alignCenter>Tipo do exame médico ocupacional</Td>
                  <Td alignCenter>Data de emissão do ASO</Td>
                  <Td alignCenter>Data do exame realizado</Td>
                  <Td alignCenter>Código do exame realizado</Td>
                  <Td alignCenter>Observação sobre o exame realizado</Td>
                  <Td alignCenter>Ordem do exame</Td>
                  <Td alignCenter>Médico emitente do ASO</Td>
                  <Td alignCenter>CRM médico emitente do ASO</Td>
                  <Td alignCenter>UF CRM médico emitente do ASO</Td>
                  <Td alignCenter>Médico Responsável PCMSO</Td>
                  <Td alignCenter>CRM Médico Responsável PCMSO</Td>
                  <Td alignCenter>UF CRM Médico Responsável PCMSO</Td>
                </Tr>
              </TBody>
            </Table>
          </ContentCardBody>
        </ContentCard>

        <ContentCard style={{ paddingLeft: 10 }}>
          <ContentCardHeader>
            <Row>
              {this.props.lista.length > 0 && (
                <Grid cols="6 6 4 2">
                  <Button
                    text="Importar 240"
                    type={"success"}
                    icon={"fa fa-chevron-left"}
                    event={() => this.fileSelector240.click()}
                  />
                </Grid>
              )}
              <Grid cols="6 6 3 2">
                <ExcelFile
                  filename={"esocial"}
                  element={
                    <Button
                      text="Exportar 240"
                      type={"primary"}
                      icon={"fa fa-plus"}
                    />
                  }
                >
                  <ExcelSheet
                    data={lista240Exportacao.map((item) => ({
                      ...item,
                    }))}
                    name="e-social"
                  >
                    <ExcelColumn label="Id" value="Id" />
                    <ExcelColumn label="ns1:indRetif" value="ns1:indRetif" />
                    <ExcelColumn label="ns1:tpAmb" value="ns1:tpAmb" />
                    <ExcelColumn label="ns1:procEmi" value="ns1:procEmi" />
                    <ExcelColumn label="ns1:verProc" value="ns1:verProc" />
                    <ExcelColumn label="ns1:tpInsc" value="ns1:tpInsc" />
                    <ExcelColumn label="ns1:nrInsc" value="ns1:nrInsc" />
                    <ExcelColumn label="ns1:cpfTrab" value="ns1:cpfTrab" />
                    <ExcelColumn label="ns1:nome" value="ns1:nome" />
                    <ExcelColumn label="ns1:matricula" value="ns1:matricula" />
                    <ExcelColumn
                      label="ns1:dtIniCondicao"
                      value="ns1:dtIniCondicao"
                    />
                    <ExcelColumn label="ns1:localAmb" value="ns1:localAmb" />
                    <ExcelColumn label="ns1:dscSetor" value="ns1:dscSetor" />
                    <ExcelColumn label="ns1:tpInsc" value="ns1:tpInsc" />
                    <ExcelColumn label="ns1:nrInsc" value="ns1:nrInsc" />
                    <ExcelColumn
                      label="ns1:dscAtivDes"
                      value="ns1:dscAtivDes"
                    />
                    <ExcelColumn label="ns1:codAgNoc" value="ns1:codAgNoc" />
                    <ExcelColumn label="ns1:cpfResp" value="ns1:cpfResp" />
                    <ExcelColumn label="ns1:ideOC" value="ns1:ideOC" />
                    <ExcelColumn label="ns1:nrOC" value="ns1:nrOC" />
                    <ExcelColumn label="ns1:ufOC" value="ns1:ufOC" />
                  </ExcelSheet>
                </ExcelFile>
              </Grid>
            </Row>
          </ContentCardHeader>
          <ContentCardBody>
            <Table responsive tableBordered>
              <THead>
                <Tr>
                  <Th alignCenter>Id</Th>
                  <Th alignCenter>ns1:indRetif</Th>
                  <Th alignCenter>ns1:tpAmb</Th>
                  <Th alignCenter>ns1:procEmi</Th>
                  <Th alignCenter>ns1:verProc</Th>
                  <Th alignCenter>ns1:tpInsc</Th>
                  <Th alignCenter>ns1:nrInsc</Th>
                  <Th alignCenter>ns1:cpfTrab</Th>
                  <Th alignCenter>ns1:nome</Th>
                  <Th alignCenter>ns1:matricula</Th>
                  <Th alignCenter>ns1:dtIniCondicao</Th>
                  <Th alignCenter>ns1:localAmb</Th>
                  <Th alignCenter>ns1:dscSetor</Th>
                  <Th alignCenter>ns1:tpInsc</Th>
                  <Th alignCenter>ns1:nrInsc</Th>
                  <Th alignCenter>ns1:dscAtivDes</Th>
                  <Th alignCenter>ns1:codAgNoc</Th>
                  <Th alignCenter>ns1:cpfResp</Th>
                  <Th alignCenter>ns1:ideOC</Th>
                  <Th alignCenter>ns1:nrOC</Th>
                  <Th alignCenter>ns1:ufOC</Th>
                </Tr>
              </THead>
              <TBody>
                {lista240.map((item) => (
                  <Tr key={item.Id}>
                    <Td alignCenter>{item.Id}</Td>
                    <Td alignCenter>{item["ns1:indRetif"]}</Td>
                    <Td alignCenter>{item["ns1:tpAmb"]}</Td>
                    <Td alignCenter>{item["ns1:procEmi"]}</Td>
                    <Td alignCenter>{item["ns1:verProc"]}</Td>
                    <Td alignCenter>{item["ns1:tpInsc"]}</Td>
                    <Td alignCenter>{item["ns1:nrInsc"]}</Td>
                    <Td alignCenter>{item["ns1:cpfTrab"]}</Td>
                    <Td alignCenter>{item["ns1:nome"]}</Td>
                    <Td alignCenter>{item["ns1:matricula"]}</Td>
                    <Td alignCenter>{item["ns1:dtIniCondicao"]}</Td>
                    <Td alignCenter>{item["ns1:localAmb"]}</Td>
                    <Td alignCenter>{item["ns1:dscSetor"]}</Td>
                    <Td alignCenter>{item["ns1:tpInsc"]}</Td>
                    <Td alignCenter>{item["ns1:nrInsc"]}</Td>
                    <Td alignCenter>{item["ns1:dscAtivDes"]}</Td>
                    <Td alignCenter>{item["ns1:codAgNoc"]}</Td>
                    <Td alignCenter>{item["ns1:cpfResp"]}</Td>
                    <Td alignCenter>{item["ns1:ideOC"]}</Td>
                    <Td alignCenter>{item["ns1:nrOC"]}</Td>
                    <Td alignCenter>{item["ns1:ufOC"]}</Td>
                  </Tr>
                ))}
                <Tr style={{ backgroundColor: "#b4c2ed" }}>
                  <Td alignCenter>Identificação única do evento</Td>
                  <Td alignCenter>Arquivo original / Retificado</Td>
                  <Td alignCenter>Identificação do ambiente</Td>
                  <Td alignCenter>Processo de emissão do evento</Td>
                  <Td alignCenter>Versão do processo de emissão do evento</Td>
                  <Td alignCenter>Tipo de inscrição</Td>
                  <Td alignCenter>Número de inscrição </Td>
                  <Td alignCenter>CPF do trabalhador</Td>
                  <Td alignCenter>Nome do trabalhador</Td>
                  <Td alignCenter>Matricula</Td>
                  <Td alignCenter>Data Início da Condição</Td>
                  <Td alignCenter>Local / Ambiente</Td>
                  <Td alignCenter>Descrição do Setor</Td>
                  <Td alignCenter>Tipo da Inscrição</Td>
                  <Td alignCenter>Número da Inscrição</Td>
                  <Td alignCenter>Descrição da Atividade</Td>
                  <Td alignCenter></Td>
                  <Td alignCenter></Td>
                  <Td alignCenter></Td>
                  <Td alignCenter></Td>
                  <Td alignCenter></Td>
                  <Td alignCenter></Td>
                </Tr>
              </TBody>
            </Table>
          </ContentCardBody>
        </ContentCard>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modoTela: state.eSocial.modoTela,
  lista: state.eSocial.lista,
  usuario: state.auth.usuario,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setModoTela, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ESocial);
