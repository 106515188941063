import { toastr } from "react-redux-toastr";

export function setErroAPI(e) {
  if (e.response && e.response.status === 400) {
    if (e.response.data instanceof Array) {
      e.response.data.forEach((retorno) => {
        toastr.error("Erro", retorno.msg ? retorno.msg : retorno);
      });
    } else {
      toastr.error("Erro", e.response.data);
    }
  } else {
    toastr.error("Erro", "Erro ao realizar Operação!!");
  }
}

export function setSuccess(mensagem) {
  toastr.success("Sucesso", mensagem);
}

export function setError(mensagem) {
  toastr.error("Alerta", mensagem);
}
