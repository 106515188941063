import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Button from "../common/button/button";
import Form from "./horarioSolicitacaoHoraExtraForm";

import {
  setModoTela,
  initForm,
  salvar,
  getLista,
} from "./horarioSolicitacaoHoraExtraActions";

class HorarioSolicitacaoHoraExtra extends Component {
  state = {
    linhaSelecionada: null,
  };

  componentWillMount() {
    this.props.getLista();
  }

  exibeHorario = (item, i) => {
    const itemJSON = JSON.parse(item);
    return <p key={i}>{`${itemJSON.inicio}h às ${itemJSON.fim}h`}</p>;
  };

  render() {
    return (
      <div>
        <Content>
          {this.props.modoTela === "cadastro" ? (
            <Form onSubmit={this.props.salvar} />
          ) : null}
          {this.props.modoTela === "lista" ? this.lista() : null}
        </Content>
      </div>
    );
  }

  lista() {
    return (
      <ContentCard style={{ paddingLeft: 10 }}>
        <ContentCardHeader>
          {!["PADRÃO", "SOLICITANTE GRUPO"].includes(
            this.props.usuario.nome_perfil_usuario
          ) && (
            <Row>
              <Grid cols="6 6 4 2">
                <Button
                  text="Adicionar"
                  type={"success"}
                  icon={"fa fa-plus"}
                  event={() => {
                    this.props.setModoTela("cadastro", {});
                    this.props.initForm({});
                  }}
                />
              </Grid>
            </Row>
          )}
        </ContentCardHeader>
        <ContentCardBody>
          <Table responsive>
            <THead>
              <Tr>
                <Th>Segunda-feira</Th>
                <Th>Terça-feira</Th>
                <Th>Quarta-feira</Th>
                <Th>Quinta-feira</Th>
                <Th>Sexta-feira</Th>
                <Th>Sábado</Th>
                <Th>Domingo</Th>
              </Tr>
            </THead>
            <TBody>
              {this.props.lista.map((item) => (
                <Tr
                  key={item.id}
                  className={`${
                    this.state.linhaSelecionada === item.id ? "hover" : ""
                  }`}
                  onClick={() =>
                    this.setState({ ...this.state, linhaSelecionada: item.id })
                  }
                  onMouseEnter={() =>
                    this.setState({ ...this.state, linhaSelecionada: item.id })
                  }
                >
                  <Td>{item.segunda_feira.map(this.exibeHorario)}</Td>
                  <Td>{item.terca_feira.map(this.exibeHorario)}</Td>
                  <Td>{item.quarta_feira.map(this.exibeHorario)}</Td>
                  <Td>{item.quinta_feira.map(this.exibeHorario)}</Td>
                  <Td>{item.sexta_feira.map(this.exibeHorario)}</Td>
                  <Td>{item.sabado.map(this.exibeHorario)}</Td>
                  <Td>{item.domingo.map(this.exibeHorario)}</Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </ContentCardBody>
      </ContentCard>
    );
  }
}

const mapStateToProps = (state) => ({
  modoTela: state.horarioSolicitacaoHoraExtra.modoTela,
  lista: state.horarioSolicitacaoHoraExtra.lista,
  usuario: state.auth.usuario,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setModoTela, initForm, salvar, getLista }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HorarioSolicitacaoHoraExtra);
