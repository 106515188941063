import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";

export function setModoTela(modo, registro = {}) {
  return {
    type: "HORARIO_SOLICITACAO_HORA_EXTRA_MODO_TELA",
    payload: {
      modo: modo,
      registro: registro,
    },
  };
}
export function initForm(registro = {}) {
  return [initialize("horarioSolicitacaoHoraExtraForm", registro)];
}

export function getLista() {
  const request = axios.get(`${consts.API_URL}/horarioSolicitacaoHoraExtra`);
  return {
    type: "HORARIO_SOLICITACAO_HORA_EXTRA_LISTADO",
    payload: request,
  };
}

export function validarHorario() {
  const request = axios.get(
    `${consts.API_URL}/validarHorarioSolicitacaoHoraExtra`
  );
  return {
    type: "HORARIO_SOLICITACAO_VALIDADO",
    payload: request,
  };
}

export function salvar(registro) {
  return (dispatch, getState) => {
    axios
      .post(`${consts.API_URL}/horarioSolicitacaoHoraExtra`, registro)
      .then((resp) => {
        setSuccess("Operação Realizada com sucesso.");
        dispatch(getLista());
        dispatch(setModoTela("lista"));
      })
      .catch((e) => {
        setErroAPI(e);
      });
  };
}
