/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Navbar from "./navbar";

export default (props) => (
  <nav className="main-header navbar navbar-expand navbar-light navbar-white border-bottom-0">
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="#" role="button">
          <i className="fas fa-bars"></i>
        </a>
      </li>
    </ul>
    <Navbar />
  </nav>
);
