/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateFormat from "../common/dateFormat/DateFormat";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./memorandoForm";
import Select from "../common/form/select";
import { gerarPDF } from "../utils/gerarPDF";

import {
  setModoTela,
  initForm,
  salvar,
  excluir,
  setFiltro,
  getLista,
  getListaTipoMemorando,
  getListaLocal,
  getListaUsuario,
} from "./memorandoActions";

class Memorando extends Component {
  state = {
    linhaSelecionada: null,
    filtro: "Recebimento Pendente",
    pesquisar: "",
  };

  componentWillMount() {
    this.props.getLista();
    this.props.getListaTipoMemorando();
    this.props.getListaLocal();
    this.props.getListaUsuario();
  }

  render() {
    return (
      <div>
        <Content>
          {this.props.modoTela === "cadastro" ? (
            <Form onSubmit={this.props.salvar} />
          ) : null}
          {this.props.modoTela === "exclusao" ? (
            <Form excluir onSubmit={this.props.excluir} />
          ) : null}
          {this.props.modoTela === "confirmacao_recebimento" ? (
            <Form confirmacao_recebimento onSubmit={this.props.salvar} />
          ) : null}
          {this.props.modoTela === "confirmacao_envio" ? (
            <Form confirmacao_envio onSubmit={this.props.salvar} />
          ) : null}
          {this.props.modoTela === "lista" ? this.lista() : null}
        </Content>
      </div>
    );
  }

  lista() {
    return (
      <ContentCard style={{ paddingLeft: 10 }}>
        <ContentCardHeader>
          <Row>
            <Grid cols="6 6 4 2">
              <Button
                text="Adicionar"
                type={"success"}
                icon={"fa fa-plus"}
                event={() => {
                  this.props.setModoTela("cadastro", {});
                  this.props.initForm({});
                }}
              />
            </Grid>
          </Row>
        </ContentCardHeader>
        <ContentCardBody>
          <Row>
            <Grid cols="12 6 6 3">
              <b>Situação</b>
              <select
                className="form-control"
                style={{
                  marginBlock: 9,
                  width: "100%",
                }}
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    filtro: e.target.value,
                  });
                }}
              >
                <option value="Recebimento Pendente">
                  Recebimento Pendente
                </option>
                {/* <option value="Envio Pendente">Envio Pendente</option> */}
                <option value="Recebidos">Recebidos</option>
                <option value="Enviados">Enviados</option>
              </select>
            </Grid>
            <Grid cols="12 6 6 3">
              <b>Tipo</b>
              <select
                className="form-control"
                style={{
                  marginBlock: 9,
                  width: "100%",
                }}
                onChange={(e) => {
                  this.props.setFiltro({
                    ...this.props.filtro,
                    tipo: e.target.value,
                  });
                  this.props.getLista();
                }}
              >
                <option value="null">Todos</option>
                {this.props.listaTipoMemorando.map((item, i) => (
                  <option key={item.id} value={item.id}>
                    {item.tipo}
                  </option>
                ))}
              </select>
            </Grid>
            <LabelAndInputMask
              label="Data/Hora Inicial"
              placeholder="Informe o período inicial de cadastro do memorando"
              cols="12 6 6 2"
              mask="99/99/9999"
              value={this.props.filtro.data_inicial_inclusao}
              onChange={(e) => {
                this.props.setFiltro({
                  ...this.props.filtro,
                  data_inicial_inclusao: e.target.value,
                });
                this.props.getLista();
              }}
            />
            <LabelAndInputMask
              label="Data/Hora Final"
              placeholder="Informe o período final de cadastro do memorando"
              cols="12 6 6 2"
              mask="99/99/9999"
              value={this.props.filtro.data_final_inclusao}
              onChange={(e) => {
                this.props.setFiltro({
                  ...this.props.filtro,
                  data_final_inclusao: e.target.value,
                });
                this.props.getLista();
              }}
            />
          </Row>
          <Row>
            <input
              className="form-control"
              placeholder="Pesquisar por assunto, usuário origem ou usuário destino"
              type="text"
              value={this.state.pesquisar}
              onChange={(e) => {
                this.setState({ ...this.state, pesquisar: e.target.value });
              }}
              style={{ marginInline: "5px", marginBottom: "20px" }}
            />
          </Row>
          <Table responsive>
            <THead>
              <Tr>
                <Th>Número</Th>
                <Th>Tipo</Th>
                <Th>Assunto</Th>
                {this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR" && (
                  <Th>Usuário de Origem</Th>
                )}
                <Th>Local de Origem</Th>
                <Th>Usuário de Destino</Th>
                <Th>Local de Destino</Th>
                <Th>Data/Hora Cadastro</Th>
                {/* <Th>Data/Hora Envio</Th> */}
                <Th>Data/Hora Recebimento</Th>
                <Th></Th>
              </Tr>
            </THead>
            <TBody>
              {this.props.lista
                .filter((item) => {
                  // if (this.state.filtro === "Envio Pendente")
                  //   return (
                  //     !item.datahora_envio &&
                  //     !item.datahora_recebimento &&
                  //     item.id_usuario_origem === this.props.usuario.id
                  //   );
                  if (this.state.filtro === "Recebimento Pendente") {
                    return (
                      (item.id_usuario_origem === this.props.usuario.id ||
                        item.id_usuario_destino === this.props.usuario.id ||
                        this.props.usuario.nome_perfil_usuario ===
                          "ADMINISTRADOR") &&
                      item.datahora_envio &&
                      !item.datahora_recebimento
                    );
                  }
                  if (this.state.filtro === "Enviados") {
                    return (
                      (item.id_usuario_origem === this.props.usuario.id ||
                        this.props.usuario.nome_perfil_usuario ===
                          "ADMINISTRADOR") &&
                      item.datahora_envio
                    );
                  }
                  if (this.state.filtro === "Recebidos") {
                    return (
                      (item.id_usuario_destino === this.props.usuario.id ||
                        this.props.usuario.nome_perfil_usuario ===
                          "ADMINISTRADOR") &&
                      item.datahora_recebimento
                    );
                  }
                  return false;
                })
                .filter((item) => {
                  if (
                    this.state.pesquisar &&
                    !`${item.assunto} ${item.nome_usuario_origem} ${item.nome_usuario_destino}`
                      .toUpperCase()
                      .includes(this.state.pesquisar.toUpperCase())
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((item) => (
                  <Tr
                    key={item.id}
                    className={`${
                      this.state.linhaSelecionada === item.id ? "hover" : ""
                    }`}
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        linhaSelecionada: item.id,
                      })
                    }
                    onMouseEnter={() =>
                      this.setState({
                        ...this.state,
                        linhaSelecionada: item.id,
                      })
                    }
                  >
                    <Td nowrap="true" minwidth={50}>
                      {item.numeracao}
                    </Td>
                    <Td nowrap="true" minwidth={80}>
                      {item.tipo_memorando}
                    </Td>
                    <Td nowrap="true" minwidth={100}>
                      {item.assunto}
                    </Td>
                    {this.props.usuario.nome_perfil_usuario ===
                      "ADMINISTRADOR" && (
                      <Td nowrap="true" minwidth={80}>
                        {item.nome_usuario_origem}
                      </Td>
                    )}
                    <Td nowrap="true" minwidth={80}>
                      {item.descricao_local_origem}
                    </Td>
                    <Td nowrap="true" minwidth={80}>
                      {item.nome_usuario_destino ||
                        item.nome_destinatario_externo + " (externo)"}
                    </Td>
                    <Td nowrap="true" minwidth={80}>
                      {item.descricao_local_destino}
                    </Td>
                    <Td minwidth={110}>{item.datahora_inclusao}</Td>
                    {/* <Td minwidth={110}>
                      {item.datahora_envio ||
                        (this.state.filtro === "Envio Pendente" ? (
                          <a
                            href="#"
                            onClick={() => {
                              this.props.initForm({
                                ...item,
                                datahora_envio:
                                  DateFormat.formatarDataHoraSqlParaTela(
                                    DateFormat.getDataHoraAtual()
                                  ),
                              });
                              this.props.setModoTela("confirmacao_envio", {
                                ...item,
                                datahora_envio:
                                  DateFormat.formatarDataHoraSqlParaTela(
                                    DateFormat.getDataHoraAtual()
                                  ),
                              });
                            }}
                          >
                            Confirmar Envio
                          </a>
                        ) : (
                          "Envio Pendente"
                        ))}
                    </Td> */}
                    <Td minwidth={110}>
                      {item.datahora_recebimento ||
                        (this.state.filtro === "Recebimento Pendente" &&
                        (item.id_usuario_destino === this.props.usuario.id ||
                          this.props.usuario.email === "ti@letsara.com") ? (
                          <a
                            href="#"
                            onClick={() => {
                              this.props.initForm({
                                ...item,
                                datahora_recebimento:
                                  DateFormat.formatarDataHoraSqlParaTela(
                                    DateFormat.getDataHoraAtual()
                                  ),
                                destinatario_externo:
                                  !!item.nome_destinatario_externo,
                              });
                              this.props.setModoTela(
                                "confirmacao_recebimento",
                                {
                                  ...item,
                                  datahora_recebimento:
                                    DateFormat.formatarDataHoraSqlParaTela(
                                      DateFormat.getDataHoraAtual()
                                    ),
                                  destinatario_externo:
                                    !!item.nome_destinatario_externo,
                                }
                              );
                            }}
                          >
                            Confirmar Recebimento
                          </a>
                        ) : (
                          "Recebimento Pendente"
                        ))}
                    </Td>

                    <Td alignright="true" minwidth={50}>
                      <ButtonTable
                        type={"primary"}
                        title={"Imprimir"}
                        icon={"fas fa-file-pdf"}
                        visible={true}
                        event={() => {
                          gerarPDF(item);
                        }}
                      />
                      {/* <ButtonTable
                        disabled={item.datahora_envio}
                        type={"warning"}
                        title={"Alterar"}
                        icon={"fas fa-pencil-alt"}
                        visible={this.state.linhaSelecionada === item.id}
                        event={() => {
                          this.props.setModoTela("cadastro", {
                            ...item,
                          });
                          this.props.initForm({
                            ...item,
                          });
                        }}
                      />

                      <ButtonTable
                        disabled={item.datahora_envio}
                        type={"danger"}
                        title={"Remover"}
                        icon={"fas fa-trash-alt"}
                        visible={this.state.linhaSelecionada === item.id}
                        event={() => {
                          this.props.setModoTela("exclusao", {
                            ...item,
                          });
                          this.props.initForm({
                            ...item,
                          });
                        }}
                      /> */}
                    </Td>
                  </Tr>
                ))}
            </TBody>
          </Table>
        </ContentCardBody>
      </ContentCard>
    );
  }
}

const mapStateToProps = (state) => ({
  modoTela: state.memorando.modoTela,
  lista: state.memorando.lista,
  listaTipoMemorando: state.memorando.listaTipoMemorando,
  filtro: state.memorando.filtro,
  usuario: state.auth.usuario,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
      salvar,
      excluir,
      setFiltro,
      getLista,
      getListaTipoMemorando,
      getListaLocal,
      getListaUsuario,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Memorando);
