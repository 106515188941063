import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import DateFormat from "../common/dateFormat/DateFormat";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputDateTime from "../common/form/labelAndInputDateTime";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import LabelAndTextarea from "../common/form/labelAndTextarea";
import Select from "../common/form/select";
import { gerarPDF } from "../utils/gerarPDF";
import { setModoTela, initForm, salvar, getLista } from "./memorandoActions";

class MemorandoForm extends Component {
  state = {};

  componentWillMount() {}

  componentDidUpdate(prevProps) {
    try {
      if (this.props.memorandoIncluido.id) {
        const memorando = this.props.lista.find(
          (item) => item.id === this.props.memorandoIncluido.id
        );
        gerarPDF(memorando);
        this.props.setModoTela("lista");
      }
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    let readOnly =
      this.props.excluir ||
      this.props.confirmacao_recebimento ||
      this.props.confirmacao_envio
        ? "readOnly"
        : "";
    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            <Row>
              <Field
                name="assunto"
                component={LabelAndInput}
                label="Assunto *"
                placeholder="Informe o assunto"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="comentario"
                component={LabelAndTextarea}
                label="Comentário *"
                placeholder="Informe o comentário"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="anotacao"
                component={LabelAndTextarea}
                label="Anotações"
                placeholder="Informe as anotações"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="id_tipo_memorando"
                component={Select}
                options={this.props.listaTipoMemorando.map((item) => ({
                  id: item.id,
                  valor: item.tipo,
                }))}
                label="Tipo de Memorando *"
                placeholder="Selecione o tipo de memorando"
                cols="12 12 12 12"
                readOnly={readOnly}
              />

              <Field
                name="id_local_origem"
                component={Select}
                options={this.props.listaLocal.map((item) => {
                  return { id: item.id, valor: item.descricao };
                })}
                label="Local de Origem *"
                placeholder="Selecione o local de origem"
                cols="12 12 12 12"
                readOnly={readOnly}
              />
              <Field
                style={{ marginRight: 10 }}
                component={LabelAndCheckbox}
                cols="12 12 12 12"
                name="destinatario_externo"
                label="Destinatário Externo"
                readOnly={readOnly}
              />
              {this.props.formularioValues.destinatario_externo ? (
                <>
                  <Field
                    name="nome_destinatario_externo"
                    component={LabelAndInput}
                    label="Nome do Destinatário Externo *"
                    placeholder="Informe o nome do destinatário externo"
                    cols="12 12 12 12"
                    readOnly={readOnly}
                  />
                  <Field
                    name="email_destinatario_externo"
                    component={LabelAndInput}
                    label="E-mail do Destinatário Externo *"
                    placeholder="Informe o e-mail do destinatário externo"
                    cols="12 12 12 12"
                    readOnly={readOnly}
                  />
                </>
              ) : (
                <Field
                  name="id_usuario_destino"
                  component={Select}
                  options={this.props.listaUsuario
                    .filter((usuario) => {
                      if (
                        this.props.excluir ||
                        this.props.confirmacao_recebimento ||
                        this.props.confirmacao_envio
                      )
                        return true;

                      return usuario.id !== this.props.usuario.id;
                    })
                    .map((item) => ({
                      id: item.id,
                      valor: item.nome,
                    }))}
                  label="Usuário de Destino *"
                  placeholder="Selecione o usuário de destino"
                  cols="12 12 12 12"
                  readOnly={readOnly}
                />
              )}
              <Field
                name="id_local_destino"
                component={Select}
                options={this.props.listaLocal.map((item) => {
                  return { id: item.id, valor: item.descricao };
                })}
                label="Local de Destino *"
                placeholder="Selecione o local de destino"
                cols="12 12 12 12"
                readOnly={readOnly}
              />
              {/* {this.props.registro.id && this.props.confirmacao_envio && (
                <Field
                  name="datahora_envio"
                  component={LabelAndInputDateTime}
                  label="Data/Hora de Envio *"
                  placeholder="Informe a data/hora de envio"
                  cols="12 12 12 12"
                  readOnly={readOnly}
                />
              )}

              {this.props.registro.id && this.props.confirmacao_recebimento && (
                <Field
                  name="datahora_recebimento"
                  component={LabelAndInputDateTime}
                  label="Data/Hora de Recebimento *"
                  placeholder="Informe a data/hora de recebimento"
                  cols="12 12 12 12"
                  readOnly={readOnly}
                />
              )} */}
            </Row>
          </ContentCardBody>
          <ContentCardFooter>
            <Row alignCenter>
              {this.props.excluir || this.props.confirmacao_recebimento ? (
                // ||this.props.confirmacao_envio
                <>
                  {this.props.excluir && (
                    <Grid cols="6 6 4 3">
                      <Button text="Excluir" submit type={"danger"} />
                    </Grid>
                  )}
                  {this.props.confirmacao_recebimento && (
                    <Grid cols="6 6 4 3">
                      <Button
                        text="Confirmar Recebimento"
                        // submit
                        event={() => {
                          this.props.salvar({
                            ...this.props.formularioValues,
                            datahora_recebimento:
                              DateFormat.formatarDataHoraTelaParaSql(
                                this.props.formularioValues.datahora_recebimento
                              ),
                            datahora_envio:
                              DateFormat.formatarDataHoraTelaParaSql(
                                this.props.formularioValues.datahora_envio
                              ),
                          });
                        }}
                        type={"info"}
                      />
                    </Grid>
                  )}
                  {/* {this.props.confirmacao_envio && (
                    <Grid cols="6 6 4 3">
                      <Button text="Enviar" submit type={"info"} />
                    </Grid>
                  )} */}
                  <Grid cols="6 6 4 3">
                    <Button
                      text="Voltar"
                      type={"warning"}
                      icon={"fa fa-chevron-left"}
                      event={() => this.props.setModoTela("lista")}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  {/* <Grid cols="12 12 4 3">
                    <Button
                      text="Salvar"
                      submit
                      type={"success"}
                      icon={"fa fa-check"}
                    />
                  </Grid> */}
                  <Grid cols="12 12 4 3">
                    <Button
                      text="Enviar"
                      type={"info"}
                      icon={"fa fa-check"}
                      event={() => {
                        this.props.salvar({
                          ...this.props.formularioValues,
                          datahora_envio: DateFormat.getDataHoraAtual(),
                        });
                      }}
                    />
                  </Grid>

                  <Grid cols="12 12 4 3">
                    <Button
                      text="Voltar"
                      type={"warning"}
                      icon={"fa fa-chevron-left"}
                      event={() => this.props.setModoTela("lista")}
                    />
                  </Grid>
                </>
              )}
            </Row>
          </ContentCardFooter>
        </Form>
      </ContentCard>
    );
  }
}

MemorandoForm = reduxForm({ form: "memorandoForm", destroyOnUnmount: false })(
  MemorandoForm
);
const mapStateToProps = (state) => ({
  formularioValues: getFormValues("memorandoForm")(state),
  usuario: state.auth.usuario,
  registro: state.memorando.registro,
  lista: state.memorando.lista,
  listaTipoMemorando: state.memorando.listaTipoMemorando,
  listaLocal: state.memorando.listaLocal,
  listaUsuario: state.memorando.listaUsuario,
  memorandoIncluido: state.memorando.memorandoIncluido,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
      salvar,
      getLista,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MemorandoForm);
