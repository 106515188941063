import React from "react";
import Grid from "../layout/grid";

export default (props) => {
  return (
    <Grid cols={props.cols}>
      <div className="form-group checkbox">
        <label htmlFor={props.name} style={{ fontWeight: "bold" }}>
          {props.onChange && props.readOnly ? (
            <input
              {...props.input}
              style={{ ...props.style, marginRight: 4 }}
              placeholder={props.placeholder}
              disabled
              type="checkbox"
              onChange={props.onChange}
              checked={props.value}
            />
          ) : props.onChange && !props.readOnly ? (
            <input
              {...props.input}
              style={{ ...props.style, marginRight: 4 }}
              placeholder={props.placeholder}
              type="checkbox"
              onChange={props.onChange}
              checked={props.value}
            />
          ) : !props.onChange && props.readOnly ? (
            <input
              {...props.input}
              style={{ ...props.style, marginRight: 4 }}
              placeholder={props.placeholder}
              type="checkbox"
              checked={props.input.value === true}
              disabled
            />
          ) : (
            <input
              {...props.input}
              style={{ ...props.style, marginRight: 4 }}
              placeholder={props.placeholder}
              type="checkbox"
              checked={props.input.value === true}
            />
          )}
          {props.label}
        </label>
      </div>
    </Grid>
  );
};
