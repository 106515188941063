import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import DateFormat from "../common/dateFormat/DateFormat";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import LabelAndTextarea from "../common/form/labelAndTextarea";
import Select from "../common/form/select";
import LabelAndInput from "../common/form/labelAndInput";
import {
  setModoTela,
  initFormCarregado,
  initForm,
  salvar,
} from "./solicitacaoHoraExtraActions";

import { setError } from "../common/toast/toast";

class SolicitacaoHoraExtraForm extends Component {
  state = {
    minutos: calculaTempo(),
    usuarios: this.props.listaUsuario.filter((item) => {
      if (item.id === this.props.usuario.id) return item;
      //remove da lista os supervisores e administradores, para impedir que esses perfis solicitem hora extra
      if (
        [
          "SUPERVISOR INTERMEDIÁRIO",
          "SUPERVISOR FINAL",
          "ADMINISTRADOR",
        ].includes(item.nome_perfil_usuario)
      )
        return;
      //cria um array apenas com o id dos setores
      const idSetores = item.setores.map((item) => item.id_setor);

      //inicializa um indicador para saber se o item foi validado
      let itemOk = false;

      //retorna OK para todos os usuários que tiverem o mesmo setor do usuario logado
      for (let i = 0; i < this.props.usuario.setores.length; i++) {
        if (idSetores.includes(this.props.usuario.setores[i].id_setor)) {
          itemOk = true;
          break;
        }
      }

      //se o usuário logado for administrador ignora os setores e lista todos os que não forem "SUPERVISOR INTERMEDIÁRIO", "SUPERVISOR FINAL", "ADMINISTRADOR",
      return (
        itemOk || this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR"
      );
    }),
  };
  componentWillMount() {
    this.props.initForm({
      ...this.props.formularioValues,
      id_solicitante:
        this.props.formularioValues.id_solicitante || this.props.usuario.id,
      id_usuario:
        this.props.formularioValues.id_usuario || this.props.usuario.id,
    });
  }
  render() {
    let controleExibicao = !!(
      (this.props.formularioValues.id_usuario !== this.props.usuario.id &&
        this.props.formularioValues.id_solicitante !== this.props.usuario.id) ||
      (this.props.formularioValues.aprovada_intermediario &&
        this.props.formularioValues.id_supervisor_intermediario) ||
      this.props.formularioValues.id_motivo_recusa ||
      this.props.excluir ||
      !this.state.usuarios.length
    );

    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            {this.props.usuario.nome_perfil_usuario !== "PADRÃO" && (
              <Row>
                <Field
                  name="id_usuario"
                  label="Colaborador *"
                  component={Select}
                  options={this.state.usuarios.map((item) => ({
                    id: item.id,
                    valor: item.nome,
                  }))}
                  placeholder="Selecione o colaborador"
                  cols="12 12 6 6"
                  readOnly={controleExibicao}
                />
              </Row>
            )}
            <Row>
              <Grid cols="12 12 6 6" style={{ marginBottom: 10 }}>
                <label htmlFor="data_hora_extra">Data</label>
                <input
                  name="data_hora_extra"
                  className="form-control"
                  type="date"
                  onChange={(e) => {
                    this.props.initForm({
                      ...this.props.formularioValues,
                      data_hora_extra: e.currentTarget.value,
                    });
                  }}
                  min={new Date().toISOString().substring(0, 10)}
                  value={this.props.formularioValues.data_hora_extra}
                  readOnly={controleExibicao}
                />
              </Grid>
            </Row>
            <Row>
              <Field
                name="quantidade_minutos"
                label="Tempo Total *"
                component={Select}
                options={this.state.minutos}
                cols="12 12 6 6"
                readOnly={controleExibicao}
              />
            </Row>
            <Row>
              <Field
                name="observacao"
                component={LabelAndTextarea}
                label="Observação *"
                placeholder="Informe a observação"
                cols="12 12 12 12"
                readOnly={controleExibicao}
              />

              <Field
                name="id_motivo_solicitacao"
                component={Select}
                options={this.props.listaMotivoSolicitacao.map((item) => ({
                  id: item.id,
                  valor: item.descricao,
                }))}
                label="Motivo da Solicitação *"
                placeholder="Selecione o motivo de solicitação"
                cols="12 12 12 12"
                readOnly={controleExibicao}
              />
              {!["PADRÃO", "SOLICITANTE GRUPO"].includes(
                this.props.usuario.nome_perfil_usuario
              ) &&
                this.props.modoTela === "reprovar" && (
                  <Field
                    name="id_motivo_recusa"
                    component={Select}
                    options={this.props.listaMotivoRecusa.map((item) => ({
                      id: item.id,
                      valor: item.descricao,
                    }))}
                    label="Motivo da Recusa *"
                    placeholder="Selecione o motivo da recusa"
                    cols="12 12 12 12"
                  />
                )}
            </Row>
            {this.props.formularioValues.nome_supervisor_intermediario &&
              this.props.usuario.nome_perfil_usuario === "SUPERVISOR FINAL" && (
                <Row>
                  <Field
                    name="nome_supervisor_intermediario"
                    label="Supervisor Intermediário que Aprovou *"
                    component={LabelAndInput}
                    cols="12 12 6 6"
                    readOnly={controleExibicao}
                  />
                </Row>
              )}
          </ContentCardBody>
          <ContentCardFooter>
            <Row alignCenter>
              {this.props.excluir ? (
                <Grid cols="6 6 4 3">
                  <Button text="Excluir" submit type={"danger"} />
                </Grid>
              ) : (
                <Grid cols="12 12 4 3">
                  <Button
                    text={
                      this.props.modoTela === "aprovar"
                        ? "Aprovar"
                        : this.props.modoTela === "reprovar"
                        ? "Reprovar"
                        : "Salvar"
                    }
                    submit
                    disabled={
                      (this.props.modoTela === "reprovar" &&
                        !this.props.formularioValues.id_motivo_recusa) ||
                      !this.state.usuarios.length
                    }
                    on
                    type={
                      this.props.modoTela === "reprovar" ? "danger" : "success"
                    }
                  />
                </Grid>
              )}

              <Grid cols="12 12 4 3">
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => this.props.setModoTela("lista")}
                />
              </Grid>
            </Row>
          </ContentCardFooter>
        </Form>
      </ContentCard>
    );
  }
}

SolicitacaoHoraExtraForm = reduxForm({
  form: "solicitacaoHoraExtraForm",
  destroyOnUnmount: false,
})(SolicitacaoHoraExtraForm);
const mapStateToProps = (state) => ({
  formularioValues: getFormValues("solicitacaoHoraExtraForm")(state),
  usuario: state.auth.usuario,
  modoTela: state.solicitacaoHoraExtra.modoTela,
  listaMotivoSolicitacao: state.motivoSolicitacaoHoraExtra.lista,
  listaMotivoRecusa: state.motivoRecusaSolicitacaoHoraExtra.lista,
  listaUsuario: state.usuario.lista,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
      initFormCarregado,
      salvar,
      setError,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitacaoHoraExtraForm);

function calculaTempo() {
  const lista = [];
  for (let i = 1; i <= 12; i++) {
    const minutosAtuais = i * 30; //multiplos de 30 min
    const meiaHoraEmMS = minutosAtuais * 60 * 1000;
    const horas = new Date(meiaHoraEmMS).getUTCHours();
    const minutos = new Date(meiaHoraEmMS).getUTCMinutes();

    const tempo =
      (String(horas).length < 2 ? "0" + horas : horas) +
      ":" +
      (String(minutos).length < 2 ? "0" + minutos : minutos);
    lista[i] = { id: minutosAtuais, valor: tempo };
  }
  return lista;
}
