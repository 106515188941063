import "../common/template/dependencies";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import App from "./app";
import Auth from "../auth/auth";
import imagemLogo from "../assets/images/logo.png";
import { validateToken } from "../auth/authActions";

class AuthOrApp extends Component {
  state = {
    carregando: true,
  };

  componentWillMount() {
    if (this.props.auth.usuario) {
      this.props.validateToken(this.props.auth.usuario.token);
    }
    setTimeout(() => {
      this.setState({ ...this.state, carregando: false });
    }, 2000);
  }

  render() {
    const { usuario, validToken } = this.props.auth;
    if (this.state.carregando) {
      return (
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.7,
          }}
        >
          <img
            src={imagemLogo}
            style={{ height: 70, width: 300 }}
            alt="Letsara Memorando e Hora Extra"
          />
        </div>
      );
    } else if (usuario && validToken) {
      axios.defaults.headers.common["authorization"] = usuario.token;
      return <App />;
    }
    // else if (!usuario && !validToken) {
    //   return <Auth />;
    // }
    else {
      return <Auth />;
    }
  }
}
const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ validateToken }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp);
