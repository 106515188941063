import React from "react";

export default (props) => (
  <td
    {...props}
    align={props.alignright ? "right" : props.alignCenter ? "center" : "left"}
    onClick={props.onClick}
    title={
      props.children
        ? props.children.type === "img"
          ? props.children.props.alt
          : typeof props.children === "string"
          ? props.children
          : ""
        : ""
    }
    style={{
      paddingLeft: 2,
      paddingTop: props.paddingTop || 2,
      paddingRight: 10,
      paddingBottom: props.paddingBottom || 2,
      verticalAlign: "middle",
      minWidth: props.minwidth || null,
      color: props.color || "#000",
      backgroundColor: props.backgroundColor,
      maxWidth: props.nowrap ? 0 : null,
      overflow: props.nowrap ? "hidden" : null,
      textOverflow: props.nowrap ? "ellipsis" : null,
      whiteSpace: props.nowrap ? "nowrap" : null,
      fontSize: props.small ? 11 : 12,
      border: props.border,
      borderLeft: props.borderLeft,
      borderRight: props.borderRight,
      borderTop: props.borderTop,
      borderBottom: props.borderBottom,
    }}
  >
    {props.children}
  </td>
);
