import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./usuarioForm";

import {
  setModoTela,
  initForm,
  salvar,
  excluir,
  getLista,
  getListaPerfil,
} from "./usuarioActions";
import { getLista as getSetores } from "../setor/setorActions";

class Usuario extends Component {
  state = {
    linhaSelecionada: null,
    pesquisar: "",
  };

  componentWillMount() {
    this.props.getLista();
    this.props.getSetores();
    this.props.getListaPerfil();

    if (this.props.usuario.primeiroAcesso) {
      this.props.initForm({
        ...this.props.usuario,
        senha: null,
      });

      setTimeout(() => {
        this.props.setModoTela("alteracao", {
          ...this.props.usuario,
          senha: null,
        });
      }, 2000);
    }
  }

  render() {
    return (
      <div>
        <Content>
          {this.props.modoTela === "alteracao" ? (
            <Form onSubmit={this.props.salvar} />
          ) : null}
          {this.props.modoTela === "cadastro" ? (
            <Form onSubmit={this.props.salvar} />
          ) : null}
          {this.props.modoTela === "exclusao" ? (
            <Form excluir onSubmit={this.props.excluir} />
          ) : null}
          {this.props.modoTela === "lista" ? this.lista() : null}
        </Content>
      </div>
    );
  }

  lista() {
    return (
      <ContentCard style={{ paddingLeft: 10 }}>
        <ContentCardHeader>
          <Row>
            <Grid cols="6 6 4 2">
              <Button
                text="Adicionar"
                disabled={
                  !["ADMINISTRADOR", "RH"].includes(
                    this.props.usuario.nome_perfil_usuario
                  )
                }
                type={"success"}
                icon={"fa fa-plus"}
                event={() => {
                  this.props.setModoTela("cadastro", {});
                  this.props.initForm({});
                }}
              />
            </Grid>
            <Grid cols="6 6 4 2">
              <Button
                text="Exportar Usuários"
                disabled={
                  !["ADMINISTRADOR", "RH"].includes(
                    this.props.usuario.nome_perfil_usuario
                  )
                }
                type={"primary"}
                icon={"fa fa-plus"}
                event={() => {
                  this.exportarXLSX(this.getListaFiltrada());
                }}
              />
            </Grid>
          </Row>
        </ContentCardHeader>
        <ContentCardBody>
          <input
            className="form-control"
            placeholder="Pesquisar"
            type="text"
            value={this.state.pesquisar}
            onChange={(e) => {
              this.setState({
                ...this.state,
                pesquisar: e.target.value.toUpperCase(),
              });
            }}
            style={{ marginBottom: "20px" }}
          />
          <Table responsive>
            <THead>
              <Tr>
                <Th>Imagem de perfil</Th>
                <Th>Matrícula</Th>
                <Th>Nome de Usuário</Th>
                <Th>Nome Completo</Th>
                <Th>E-mail</Th>
                <Th>Nível de Acesso</Th>
                <Th>Setor</Th>
                <Th>Cargo</Th>
                <Th></Th>
              </Tr>
            </THead>
            <TBody>
              {this.getListaFiltrada().map((item) => (
                <Tr
                  key={item.id}
                  className={`${
                    this.state.linhaSelecionada === item.id ? "hover" : ""
                  }`}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      linhaSelecionada: item.id,
                    })
                  }
                  onMouseEnter={() =>
                    this.setState({
                      ...this.state,
                      linhaSelecionada: item.id,
                    })
                  }
                >
                  <Td>
                    {item.imagem_perfil ? (
                      <img
                        src={item.imagem_perfil}
                        style={{
                          height: 100,
                          maxWidth: 100,
                          objectFit: "contain",
                        }}
                        alt="Imagem de perfil"
                      />
                    ) : (
                      <i
                        className="fas fa-user"
                        style={{
                          height: 100,
                          padding: 30,
                          fontSize: 50,
                          color: "#777",
                        }}
                      ></i>
                    )}
                  </Td>
                  <Td nowrap="true" minwidth={100}>
                    {item.matricula}
                  </Td>
                  <Td nowrap="true" minwidth={100}>
                    {item.nome_de_usuario}
                  </Td>
                  <Td nowrap="true" minwidth={100}>
                    {item.nome}
                  </Td>
                  <Td nowrap="true" minwidth={100}>
                    {item.email}
                  </Td>
                  <Td>{item.nome_perfil_usuario}</Td>
                  <Td>
                    {item.setores.map((setor) => (
                      <p key={setor.id} style={{ padding: 0, margin: 0 }}>
                        {setor.nome}
                      </p>
                    ))}
                  </Td>

                  <Td>{item.cargo}</Td>
                  <Td alignright="true" minwidth={100}>
                    <ButtonTable
                      type={"warning"}
                      title={"Alterar"}
                      icon={"fas fa-pencil-alt"}
                      visible={this.state.linhaSelecionada === item.id}
                      event={() => {
                        this.props.setModoTela("cadastro", {
                          ...item,
                          senha: null,
                        });
                        this.props.initForm({
                          ...item,
                          senha: null,
                        });
                      }}
                    />

                    <ButtonTable
                      type={"danger"}
                      title={"Remover"}
                      icon={"fas fa-trash-alt"}
                      disabled={
                        this.props.usuario.nome_perfil_usuario !==
                        "ADMINISTRADOR"
                      }
                      visible={this.state.linhaSelecionada === item.id}
                      event={() => {
                        this.props.setModoTela("exclusao", {
                          ...item,
                          senha: null,
                        });
                        this.props.initForm({
                          ...item,
                          senha: null,
                        });
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </ContentCardBody>
      </ContentCard>
    );
  }
  getListaFiltrada() {
    return this.props.lista
      .filter((item) => {
        return (
          ["ADMINISTRADOR", "RH"].includes(
            this.props.usuario.nome_perfil_usuario
          ) || item.id === this.props.usuario.id
        );
      })
      .filter((item) => {
        if (
          this.state.pesquisar &&
          !`${item.matricula} ${item.nome} ${item.nome_de_usuario} ${
            item.email
          } ${item.nome_perfil_usuario} ${item.setores.map(
            (item) => item.nome
          )} ${item.cargo}`
            .toUpperCase()
            .includes(this.state.pesquisar.toUpperCase())
        ) {
          return false;
        }
        return true;
      });
  }
  exportarXLSX(lista) {
    const linhas = lista.map((item) => {
      const usuario = [
        item.nome,
        item.email,
        item.nome_de_usuario,
        item.matricula,
        item.cpf,
        item.nome_perfil_usuario,
        item.setores.map((item) => item.nome).toString(),
        item.cargo,
      ];
      return usuario;
    });
    const cabecalhos = [
      "NOME",
      "E-MAIL",
      "NOME DE USUÁRIO",
      "MATRÍCULA",
      "CPF",
      "NÍVEL DE ACESSO",
      "SETORES",
      "CARGO",
    ];

    const data = [cabecalhos, ...linhas];
    const xlsx = require("node-xlsx");
    const buffer = xlsx.build([{ name: "usuários", data: data }]);
    const linkSource =
      "data:application/octet-stream;base64," +
      encodeURI(Buffer.from(buffer).toString("base64"));
    const downloadLink = document.createElement("a");
    const fileName = `Usuários.xlsx`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
  modoTela: state.usuario.modoTela,
  lista: state.usuario.lista,
  listaPerfil: state.usuario.listaPerfil,
  setores: state.setor.lista,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
      salvar,
      excluir,
      getLista,
      getSetores,
      getListaPerfil,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Usuario);
