/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateFormat from "../common/dateFormat/DateFormat";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./solicitacaoHoraExtraForm";

import {
  setModoTela,
  initForm,
  initFormCarregado,
  salvar,
  excluir,
  getLista,
  getHorarioAtual,
} from "./solicitacaoHoraExtraActions";

import { getLista as getListaHorarios } from "../horarioSolicitacaoHoraExtra/horarioSolicitacaoHoraExtraActions";
import { getLista as getListaMotivoSolicitacao } from "../motivoSolicitacaoHoraExtra/motivoSolicitacaoHoraExtraActions";
import { getLista as getListaMotivoRecusa } from "../motivoRecusaSolicitacaoHoraExtra/motivoRecusaSolicitacaoHoraExtraActions";
import { getLista as getListaUsuario } from "../usuario/usuarioActions";
class SolicitacaoHoraExtra extends Component {
  state = {
    linhaSelecionada: null,
    pesquisar: "",
  };

  componentWillMount() {
    this.props.getHorarioAtual();
    this.props.getLista();
    this.props.getListaHorarios();
    this.props.getListaMotivoSolicitacao();
    this.props.getListaMotivoRecusa();
    this.props.getListaUsuario();
    setInterval(() => {
      this.props.getHorarioAtual();
      this.props.getLista();
      this.props.getListaHorarios();
      this.props.getListaMotivoSolicitacao();
      this.props.getListaMotivoRecusa();
      this.props.getListaUsuario();
    }, 60000);
  }

  render() {
    return (
      <ContentCard style={{ paddingLeft: 10 }}>
        <ContentCardHeader>
          <Row>
            <Grid cols="6 6 4 2">
              <Button
                text="Exportar Relatório"
                disabled={
                  !["ADMINISTRADOR", "RH"].includes(
                    this.props.usuario.nome_perfil_usuario
                  )
                }
                type={"primary"}
                icon={"fa fa-plus"}
                event={() => {
                  this.exportarXLSX(this.getListaFiltrada());
                }}
              />
            </Grid>
          </Row>
        </ContentCardHeader>
        <ContentCardBody>
          <Row>
            <input
              className="form-control"
              placeholder="Pesquisar por solicitante, colaborador ou supervisor"
              type="text"
              value={this.state.pesquisar}
              onChange={(e) => {
                this.setState({ ...this.state, pesquisar: e.target.value });
              }}
              style={{ marginInline: "5px", marginBottom: "20px" }}
            />
          </Row>
          <Table responsive>
            <THead>
              <Tr>
                <Th>Solicitante</Th>
                <Th>Colaborador</Th>
                <Th>Aprovador 1º Nível</Th>
                {/* <Th>Supervisor Intermediário</Th> */}
                <Th alignCenter>Aprovação 1º Nível</Th>
                {/* <Th alignCenter>Aprovação Intermediária</Th> */}
                <Th>Aprovador 2º Nível</Th>
                {/* <Th>Supervisor Final</Th> */}
                <Th alignCenter>Aprovação 2º Nível</Th>
                {/* <Th alignCenter>Aprovação Final</Th> */}
                <Th alignCenter>Data</Th>
                <Th alignCenter>Duração</Th>
                <Th>Motivo da Solicitação</Th>
                <Th>Motivo da Recusa</Th>
              </Tr>
            </THead>
            <TBody>
              {this.getListaFiltrada().map((item) => (
                <Tr
                  key={item.id}
                  className={`${
                    this.state.linhaSelecionada === item.id ? "hover" : ""
                  }`}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      linhaSelecionada: item.id,
                    })
                  }
                  onMouseEnter={() =>
                    this.setState({
                      ...this.state,
                      linhaSelecionada: item.id,
                    })
                  }
                >
                  <Td nowrap minwidth={100}>
                    {item.nome_solicitante}
                  </Td>
                  <Td nowrap minwidth={100}>
                    {item.nome_usuario}
                  </Td>
                  <Td nowrap minwidth={100}>
                    {item.nome_supervisor_intermediario || (
                      <div style={{ textAlign: "center" }}>-</div>
                    )}
                  </Td>
                  <Td nowrap alignCenter minwidth={80}>
                    {!item.id_motivo_recusa && !item.aprovada_intermediario ? (
                      <span style={{ backgroundColor: "#fa2", padding: 10 }}>
                        Pendente
                      </span>
                    ) : item.aprovada_intermediario ? (
                      <div style={{ padding: 10 }}>Aprovada</div>
                    ) : (
                      <div style={{ padding: 10 }}> Reprovada</div>
                    )}
                  </Td>
                  <Td nowrap minwidth={100}>
                    {item.nome_supervisor_final || (
                      <div style={{ textAlign: "center" }}>-</div>
                    )}
                  </Td>
                  <Td nowrap alignCenter minwidth={80}>
                    {!item.id_supervisor_final && !item.id_motivo_recusa ? (
                      <span style={{ backgroundColor: "#fa2", padding: 10 }}>
                        Pendente
                      </span>
                    ) : item.id_motivo_recusa && !item.id_supervisor_final ? (
                      "-"
                    ) : item.aprovada_final ? (
                      <div style={{ padding: 10 }}>Aprovada</div>
                    ) : (
                      <div style={{ padding: 10 }}> Reprovada</div>
                    )}
                  </Td>
                  <Td nowrap alignCenter minwidth={100}>
                    {new Date(item.data_hora_extra).toLocaleDateString(
                      "pt-BR",
                      { dateStyle: "short" }
                    )}
                  </Td>
                  <Td nowrap alignCenter minwidth={100}>
                    {item.quantidade_minutos + " min"}
                  </Td>
                  <Td nowrap minwidth={100}>
                    {item.descricao_motivo_solicitacao}
                  </Td>
                  <Td nowrap minwidth={100}>
                    {item.descricao_motivo_recusa || (
                      <div style={{ textAlign: "center" }}>-</div>
                    )}
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </ContentCardBody>
      </ContentCard>
    );
  }

  getListaFiltrada() {
    return this.props.lista
      .filter((item) => {
        return this.state.pesquisar
          ? (item.nome_solicitante &&
              item.nome_solicitante
                .toUpperCase()
                .includes(this.state.pesquisar.toUpperCase())) ||
              (item.nome_usuario &&
                item.nome_usuario
                  .toUpperCase()
                  .includes(this.state.pesquisar.toUpperCase())) ||
              (item.nome_supervisor &&
                item.nome_supervisor
                  .toUpperCase()
                  .includes(this.state.pesquisar.toUpperCase()))
          : true;
      })
      .filter((item) => {
        let itemOk = false;
        for (let i = 0; i < this.props.usuario.setores.length; i++) {
          if (item.setores.includes(this.props.usuario.setores[i].id_setor)) {
            itemOk = true;
            break;
          }
        }
        if (this.props.usuario.nome_perfil_usuario === "ADMINISTRADOR")
          return true;
        if (
          this.props.usuario.nome_perfil_usuario === "SUPERVISOR FINAL" &&
          this.props.usuario.id !== item.id_solicitante &&
          ((item.id_motivo_recusa && !item.id_supervisor_final) ||
            !item.aprovada_intermediario)
        ) {
          return false;
        }
        return (
          itemOk &&
          (item.id_usuario === this.props.usuario.id ||
            item.id_solicitante === this.props.usuario.id ||
            !["PADRÃO", "SOLICITANTE GRUPO"].includes(
              this.props.usuario.nome_perfil_usuario
            ))
        );
      });
  }

  exportarXLSX(lista) {
    const linhas = lista.map((item) => {
      const solicitacao = [
        new Date(item.data_hora_extra).toLocaleDateString("pt-BR", {
          dateStyle: "short",
        }),
        item.nome_solicitante,
        item.nome_usuario,
        item.nome_supervisor_intermediario || "-",
        !item.id_motivo_recusa && !item.aprovada_intermediario
          ? "Pendente"
          : item.aprovada_intermediario
          ? "Aprovada"
          : "Reprovada",
        item.nome_supervisor_final || "-",
        !item.id_supervisor_final && !item.id_motivo_recusa
          ? "Pendente"
          : item.id_motivo_recusa && !item.id_supervisor_final
          ? "-"
          : item.aprovada_final
          ? "Aprovada"
          : "Reprovada",
        item.quantidade_minutos + " min",
        item.descricao_motivo_solicitacao,
        item.observacao,
        item.descricao_motivo_recusa || "-",
      ];
      return solicitacao;
    });
    const cabecalhos = [
      "DATA SOLICITADA",
      "SOLICITANTE",
      "COLABORADOR",
      "APROVADOR 1",
      "APROVAÇÃO 1º NÍVEL",
      "APROVADOR 2",
      "APROVAÇÃO 2º NÍVEL",
      "DURAÇÃO",
      "MOTIVO DA SOLICITAÇÃO",
      "OBSERVAÇÃO",
      "MOTIVO DA RECUSA",
    ];

    const data = [cabecalhos, ...linhas];
    const xlsx = require("node-xlsx");
    const buffer = xlsx.build([
      { name: "relatório por solicitação", data: data },
    ]);
    const linkSource =
      "data:application/octet-stream;base64," +
      encodeURI(Buffer.from(buffer).toString("base64"));
    const downloadLink = document.createElement("a");
    const fileName = `Relatório por Solicitação.xlsx`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}

const mapStateToProps = (state) => ({
  modoTela: state.solicitacaoHoraExtra.modoTela,
  lista: state.solicitacaoHoraExtra.lista,
  horarioAtual: state.solicitacaoHoraExtra.horarioAtual,
  listaHorarios: state.horarioSolicitacaoHoraExtra.lista,
  filtro: state.solicitacaoHoraExtra.filtro,
  usuario: state.auth.usuario,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      initForm,
      initFormCarregado,
      salvar,
      excluir,
      getLista,
      getHorarioAtual,
      getListaHorarios,
      getListaMotivoSolicitacao,
      getListaMotivoRecusa,
      getListaUsuario,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitacaoHoraExtra);
