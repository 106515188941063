const userKey = "_servicos_user";
const INITIAL_STATE = {
  usuario: JSON.parse(localStorage.getItem(userKey)),
  usuarioLogado: null,
  validToken: false,
  message: {
    open: false,
    text: "",
    time: 5000,
    type: "success",
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOKEN_VALIDATED":
      if (action.payload.valid) {
        localStorage.setItem(userKey, JSON.stringify(action.payload.usuario));
        return { ...state, validToken: true, usuario: action.payload.usuario };
      } else {
        localStorage.removeItem(userKey);
        return { ...state, validToken: false, usuario: null };
      }

    case "USER_FETCHED":
      if (!action.payload.id) return state;
      localStorage.setItem(userKey, JSON.stringify(action.payload));
      return {
        ...state,
        usuario: action.payload,
        validToken: true,
      };

    case "USER_MESSEGE":
      return { ...state, message: action.payload };

    default:
      return state;
  }
};
