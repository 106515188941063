import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";

export function setModoTela(modo, registro = {}) {
  return {
    type: "USUARIO_MODO_TELA",
    payload: {
      modo: modo,
      registro: registro,
    },
  };
}

export function initForm(registro = {}) {
  return [initialize("usuarioForm", registro)];
}

export function getLista() {
  return (dispatch, getState) => {
    // getState().solicitacaoHoraExtra.relatorio = {
    //   nome: "",
    //   pendentes: "",
    //   aprovadas: "",
    //   reprovadas: "",
    //   horas_minutos: "",
    // };
    axios
      .get(`${consts.API_URL}/usuario`)
      .then((resp) => {
        dispatch({
          type: "USUARIO_LISTADO",
          payload: resp,
        });
      })
      .catch((e) => {
        // console.log(e);
        setErroAPI(e);
      });
  };
}

export function getListaPerfil(filtro = { nome: null, id: null }) {
  let filtroConsulta = filtro.nome || filtro.id ? "?" : "";
  if (filtroConsulta) {
    filtroConsulta += filtro.nome
      ? `nome=${filtro.nome}`
      : filtro.id
      ? `id=${filtro.id}`
      : "";
  }
  const request = axios.get(
    `${consts.API_URL}/usuario/perfil${filtroConsulta}`
  );

  return {
    type: "PERFIL_SELECT_LISTADO",
    payload: request,
  };
}

export function salvar(registro) {
  return (dispatch, getState) => {
    const registroFormulario = JSON.parse(JSON.stringify(registro));
    const idSetores = getState()
      .setor.lista.filter((setor) => registro[setor.nome.toLowerCase()])
      .map((setor) => setor.id);

    getState().setor.lista.map(
      (setor) => delete registro[setor.nome.toLowerCase()]
    );
    registro.setores = idSetores;

    if (!registro.id) {
      axios
        .post(`${consts.API_URL}/usuario`, registro)
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch(getLista());
          dispatch(setModoTela("lista"));
        })
        .catch((e) => {
          setErroAPI(e);
          dispatch(initForm(registroFormulario));
        });
    } else {
      axios
        .put(`${consts.API_URL}/usuario`, registro)
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch([{ type: "USER_FETCHED", payload: resp.data }]);
          dispatch(getLista());
          dispatch(setModoTela("lista"));
        })
        .catch((e) => {
          setErroAPI(e);
          dispatch(initForm(registroFormulario));
        });
    }
  };
}

export function excluir(registro) {
  return (dispatch) => {
    axios
      .delete(`${consts.API_URL}/usuario?id=${registro.id}`)
      .then((resp) => {
        setSuccess("Operação Realizada com sucesso.");
        dispatch(getLista());
        dispatch(setModoTela("lista"));
      })
      .catch((e) => {
        setErroAPI(e);
      });
  };
}
