import React from "react";

export default (props) => (
  <div
    className="row"
    style={{
      ...props.style,
      justifyContent: props.alignCenter
        ? "center"
        : props.alignright
        ? "right"
        : "left",
      alignItems: props.alignitems,
      paddingInline: 5,
    }}
  >
    {props.children}
  </div>
);
