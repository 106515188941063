import React from "react";

export default (props) => (
  <div
    id={props.id}
    className={`${props.responsive ? "table-responsive" : ""} ${
      props.border ? "table-bordered" : ""
    }`}
    style={{
      display: "flex",
      overflowX: "auto",
      maxHeight: "65vh	",
      overflowY: "scroll",
      ...props.style,
    }}
  >
    <table
      className={`table table-hover ${
        props.tableBordered ? "table-bordered" : ""
      }`}
    >
      {props.children}
    </table>
  </div>
);
