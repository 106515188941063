import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../common/template/header";
import SideBar from "../common/template/sideBar";
import Messages from "../common/msg/messages";
import imagemLogo from "../assets/images/logo.png";

import Routes from "./routes";

class App extends Component {
  state = {
    carregando: true,
  };

  constructor(props) {
    super(props);

    setTimeout(() => {
      this.setState({ ...this.state, carregando: false });
    }, 2000);
  }

  componentWillMount() {}

  render() {
    return (
      <HashRouter>
        {this.state.carregando ? (
          // <div
          //   className="modal fade show"
          //   id="modal-overlay"
          //   // style={{ display: "block", top: "40%" }}
          //   style={{
          //     display: "flex",
          //     width: "100vw",
          //     justifyContent: "start",
          //     alignItems: "center",
          //   }}
          //   aria-modal="true"
          //   role="dialog"
          // >
          // <div className="modal-dialog">
          //   <div className="overlay">
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              opacity: 0.7,
            }}
          >
            <img
              src={imagemLogo}
              style={{ height: 70, width: 300 }}
              alt="Letsara Memorando e Hora Extra"
            />
          </div>
        ) : (
          //     </div>
          //   </div>
          // </div>
          <div className="wrapper">
            <Header />
            <SideBar />
            <Routes usuario={this.props.usuario} />
            <Messages />
          </div>
        )}
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => ({ usuario: state.auth.usuario });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(App);
